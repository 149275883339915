import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PlatformConfig} from '../../../app-loader/platform-config';
import {ConfigLoaderService} from '../../../app-loader/config-loader.service';

@Component({
  selector: 'app-customer-dialog',
  templateUrl: './customer-dialog.component.html',
  styleUrls: ['./customer-dialog.component.css']
})
export class CustomerDialogComponent implements OnInit {

  customer: {
    phone: '',
    firstname: '',
    lastname: ''
  };
  form: FormGroup;

  platformConfig: PlatformConfig;

  constructor(public dialogRef: MatDialogRef<CustomerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              public configLoaderService: ConfigLoaderService) {
    this.platformConfig = this.configLoaderService.getPlatformConfig();
    this.customer = data;
  }

  ngOnInit() {

    this.form = this.fb.group ( {
      firstname: [null ,
        Validators.compose ( [Validators.required]),
        Validators.composeAsync([])
      ],
      lastname: [null ,
        Validators.compose ( [Validators.required]),
        Validators.composeAsync([])
      ],
    });

    this.form.patchValue(this.customer);

  }

  onSubmit() {
    this.dialogRef.close(this.form.value);
  }

  onClose() {
    this.dialogRef.close(null);
  }

}
