import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild,
  OnChanges, SimpleChanges} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {FormControl} from '@angular/forms';


import {RequestParameters, FieldRequestParameters} from '../../models/request-parameters/parameter.model';

import {UserCollectAgency} from '../../models/users-collect/user-collect-agency.model';
import { UserCollect } from '../../models/users-collect/user-collect.model';
import { UsersCollectService } from '../../models/users-collect/users-collect.service';
import {  UserCollectProduct } from '../../models/users-collect/user-collector-product.model';

@Component({
  selector: 'app-collect-products-autocomplete',
  templateUrl: './collect-products-autocomplete.component.html',
  styleUrls: ['./collect-products-autocomplete.component.scss']
})
export class CollectProductsAutocompleteComponent implements OnInit {
  
  @Input('collectProductFormControl') collectProductFormControl: FormControl;
  @Input('userCollect') userCollect: UserCollect;
  @Input('placeholder') placeholder: string;
  @Output() onCollectProductSelected: EventEmitter<UserCollectProduct> =
   new EventEmitter<UserCollectProduct>();

  collectProducts: UserCollectProduct[] = [];
  productLoading: boolean = false;
  productParameters: RequestParameters = new RequestParameters();
  @ViewChild('autoCollectProducts') autoCollectProducts: ElementRef;
  constructor(private usersCollectService: UsersCollectService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges ): void {
    if (changes.userCollect.currentValue) {
      this.updateProduct();
    }
  }

  onSelectProduct() {
    this.onCollectProductSelected.emit(this.collectProductFormControl.value);
  }


  displayProduct ( product: UserCollectProduct ) {
    if (product !== null) {
      return product.name;
    }
  }

  filterProducts (filterInput: string = '') {
    if (this.productParameters.searchString !== filterInput) {
      this.productParameters.searchString = filterInput;
      this.updateProduct();
    }
  }

  updateProduct() {

      if (this.userCollect) {
        
        this.productLoading = true;
        const userCollectField = new FieldRequestParameters('userCollect',
        this.userCollect.reference);
  
        this.productParameters.fields = [];
        this.productParameters.fields.push(userCollectField);
      

        this.usersCollectService.getUserCollectProducts(this.userCollect, 
          this.productParameters)
          .subscribe(
            (response: any) => {
              this.productLoading = false;
              this.collectProducts = response.result;
            }
          );

      }  
  }

}
