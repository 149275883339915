<div class="pin-change-container">
  
  <p [appAppColor]="{default : ['color']}" 
   class="connection-header" style="text-align: center !important;">
   TRANSFERT PLANIFIE
  </p>
  <br>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div *ngIf="action !== 'add'" style="width: 100%;">
      <mat-form-field style="width: 100%" >
        <input matInput placeholder="Référence"
               [formControl]="form.controls['reference']" readonly>
      </mat-form-field>
    </div>
    <div style="width: 100%;">
      <!-- <mat-form-field class="tel-input-form" style="width: 100%" >
        <input matInput type="text"
               placeholder="Votre téléphone"
               [formControl]="form.controls['accountNumber']"
               ng2TelInput
               (countryChange)="onCustomerCountryChange($event)"
               (ng2TelOutput)="getCustomerNumber($event)"
               [ng2TelInputOptions]="utils.selectorOptions"
               (hasError)="customerPhoneHasError($event)"/>
        <mat-error *ngIf="form.controls['accountNumber'].hasError('required') && form.controls['accountNumber'].touched">
          Ce champ est requis
        </mat-error>
        <mat-error *ngIf="form.controls['accountNumber'].hasError('badPhone') && form.controls['accountNumber'].touched">
          Numéro de téléphone invalide
        </mat-error>
      </mat-form-field> -->

      <mat-form-field class="tel-input-form" style="width: 100%" >
        <input matInput type="text"
               placeholder="Téléphone du destinataire"
               (blur)="onReceiverPhoneBlur()"
               [formControl]="form.controls['accountNumber']"
               ng2TelInput
               (countryChange)="onRecieverCountryChange($event)"
               (ng2TelOutput)="getReceiverNumber($event)"
               [ng2TelInputOptions]="utils.selectorOptions"
               (hasError)="receiverPhoneHasError($event)" />
        <mat-error *ngIf="form.controls['accountNumber'].hasError('required') && form.controls['accountNumber'].touched">
          Ce champ est requis
        </mat-error>
        <mat-error *ngIf="form.controls['accountNumber'].hasError('badPhone') && form.controls['accountNumber'].touched">
          Numéro de téléphone invalide
        </mat-error>
        <mat-error *ngIf="form.controls['accountNumber'].hasError('nonExistentUser') && form.controls['accountNumber'].touched">
          Ce numéro n'est pas un client
        </mat-error>
        <mat-progress-spinner mode="indeterminate" color="primary" class="loading-circle" [diameter]="25" [strokeWidth]="2" *ngIf="loadingReceiver">
        </mat-progress-spinner>
      </mat-form-field>
    </div>
    <div style="width: 100%;">
      <mat-form-field style="width: 100%" >
        <input matInput
               [formControl]="form.controls['amount']" 
               appRegexpInputCheck [inputRegexp]="'[0-9]'" 
               placeholder="Montant" type="number"
               min="0"
               class="example-left-align" required>
        <span matSuffix>.00</span>
        <mat-error *ngIf="form.controls['amount'].hasError('required') && form.controls['amount'].touched">
          Ce champ est requis
        </mat-error>
        <mat-error *ngIf="form.controls['amount'].hasError('invalidAmount') && form.controls['amount'].touched">
          {{ "Montant invalide, le montant minimal pour éffectuer cette opération est de "  }} {{minimumOperationAmount}}
        </mat-error>
      </mat-form-field>
    </div>
    <div style="width: 100%;">
        <mat-form-field style="width: 100%">
          <input type="text" matInput [formControl]="form.controls['title']" placeholder="Titre" class="example-left-align" required>
          <mat-error *ngIf="form.controls['title'].hasError('required') && form.controls['title'].touched">
            Ce champ est requis
          </mat-error>
        </mat-form-field>
    </div>
    <div style="width: 100%;">
      <mat-form-field style="width: 100%">
        <textarea type="text" matInput [formControl]="form.controls['description']" placeholder="Description" class="example-left-align" required></textarea>
        <mat-error *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched">
          Ce champ est requis
        </mat-error>
      </mat-form-field>
  </div>
    <div style="width: 100%; display: flex;justify-content: space-between;">
      <mat-form-field style="width: 45%;">
        <input type="number" [formControl]="form.controls['frequency']" matInput placeholder="Fréquence" >
        <mat-error *ngIf="form.controls['frequency'].hasError('required') &&
        form.controls['frequency'].touched">
         Ce champ est requis
       </mat-error>
      </mat-form-field>

      <mat-form-field style="width: 45%; margin-left: 15px;">
        <mat-select placeholder="Période" [formControl]="form.controls['period']" >
          <mat-option *ngFor="let period of  periods" [value]="period.value">
            {{ period.title }}
          </mat-option>
        </mat-select>
       
       
      </mat-form-field>
    </div>
    
    <div style="width: 100%;">
      <mat-form-field style="width: 100%;">
        <input [formControl]="form.controls['nextExecutionDate']"
               matInput [matDatepicker]="FirstDatepicker" 
               placeholder="Date Début"
               [min]="today" (focus)="FirstDatepicker.open()">
        <mat-datepicker-toggle matSuffix [for]="FirstDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #FirstDatepicker [disabled]="form.disabled"></mat-datepicker>
        <mat-error *ngIf="form.controls['nextExecutionDate'].hasError('required')
         && form.controls['nextExecutionDate'].touched">
          Ce champ est requis
        </mat-error>
      </mat-form-field>
    </div>

    <mat-card-actions>
      <button mat-raised-button color="accent" type="reset" (click)="dialogRef.close(null)" [disabled]="loading">
        <mat-icon>clear</mat-icon> <span class="button-hide-text">Annuler</span>
      </button>
      <button (click)="onSubmit()" mat-raised-button  [disabled]="(action != 'delete' && !form.valid) || loading"
      [appAppColor]="{default : ['background-color']}" type="submit"
      class="">
        <mat-icon>done</mat-icon> <span class="button-hide-text">{{getSubmitButton()}}</span>
        <mat-progress-spinner mode="indeterminate" class="primary"
         [diameter]="25" [strokeWidth]="2" *ngIf="loading" >
        </mat-progress-spinner>
      </button>
    </mat-card-actions>

    <!-- <button style="width: 100%;
      font-size: 1em;
      color: #fff;
      line-height: 40px;
      margin-top: 10px;" (click)="onSubmit()" mat-raised-button
              mat-ripple matRippleColor="rgba(255,255,255,.5)"
              matRippleCentered="true" [appAppColor]="{default : ['background-color']}" [disabled]="!form.valid || loading">
        <mat-progress-spinner mode="indeterminate" class="primary"
         [diameter]="25" [strokeWidth]="2" *ngIf="loading" >
        </mat-progress-spinner>
        Valider
    </button> -->
</form>  
</div>