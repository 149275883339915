import { AddUserComponent } from './add-user/add-user.component';
import { MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';


export interface Element {
  userFirstName: string;
  userLastName: string;
  userPhoneNumber: number;
}

const data: Element[] = [
  { userFirstName: 'Joe', userLastName: 'Doe', userPhoneNumber: 12 },
  { userFirstName: 'Joe', userLastName: 'Doe', userPhoneNumber: 12 },
  { userFirstName: 'Joe', userLastName: 'Doe', userPhoneNumber: 12 },
  { userFirstName: 'Joe', userLastName: 'Doe', userPhoneNumber: 12 },
  { userFirstName: 'Joe', userLastName: 'Doe', userPhoneNumber: 12 },
  { userFirstName: 'Joe', userLastName: 'Doe', userPhoneNumber: 12 },
  { userFirstName: 'Joe', userLastName: 'Doe', userPhoneNumber: 12 },
  { userFirstName: 'Joe', userLastName: 'Doe', userPhoneNumber: 12 },
  { userFirstName: 'Joe', userLastName: 'Doe', userPhoneNumber: 12 },
  { userFirstName: 'Joe', userLastName: 'Doe', userPhoneNumber: 12 },
  { userFirstName: 'Joe', userLastName: 'Doe', userPhoneNumber: 12 },
  { userFirstName: 'Joe', userLastName: 'Doe', userPhoneNumber: 12 },
  { userFirstName: 'Joe', userLastName: 'Doe', userPhoneNumber: 12 },
];
/**
 * Data source to provide what data should be rendered in the table. The observable provided
 * in connect should emit exactly the data that should be rendered by the table. If the data is
 * altered, the observable should emit that new set of data on the stream. In our case here,
 * we return a stream that contains only one set of data that doesn't change.
 */
export class ExampleDataSource extends DataSource<any> {
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Element[]> {
    return Observable.of(data);
  }

  disconnect() { }
}

@Component({
  selector: 'app-my-users',
  templateUrl: './my-users.component.html',
  styleUrls: ['./my-users.component.css']
})

export class MyUsersComponent implements OnInit {

  displayedColumns = ['userLastName', 'userFirstName', 'userPhoneNumber'];
  dataSource = new ExampleDataSource();

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: '250px',
      data: { name: 'walai', animal: 'talai' }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

}


