import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {DataSource} from '@angular/cdk/collections';
import {Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import {UserOperationsService} from '../../user-operations.service';
import {RequestParameters} from '../../../shared/models/request-parameters/parameter.model';

/**
 * Data source to provide what data should be rendered in the table. The observable provided
 * in connect should emit exactly the data that should be rendered by the table. If the data is
 * altered, the observable should emit that new set of data on the stream. In our case here,
 * we return a stream that contains only one set of data that doesn't change.
 */
export class PastOperationsDataSource extends DataSource<any> {

  constructor(private subject: BehaviorSubject<any[]>) {
    super ();
  }

  connect (): Observable<any[]> {
    return this.subject.asObservable();
  }

  disconnect (): void {
  }
}

@Component({
  selector: 'app-past-operations',
  templateUrl: './past-operations.component.html',
  styleUrls: ['./past-operations.component.css']
})

export class PastOperationsComponent implements OnInit {

  displayedColumns = ['operationDate', 'operationType', 'amount', 'description'];

  dataSource: PastOperationsDataSource | null;
  dataSubject = new BehaviorSubject<any[]>([]);

  parameters = new RequestParameters();

  constructor(private userOperationsService: UserOperationsService,
              private router: Router) {
    this.userOperationsService.getOperations(this.parameters).subscribe(
      (response: any) => {
        this.parameters = response.parameters;
        this.dataSubject.next(response.result);
      }
    );
  }

  ngOnInit() {
    this.dataSource = new PastOperationsDataSource(this.dataSubject);
  }

  onSelectRow(row) {
    this.userOperationsService.operationSelected = row;
    this.router.navigate(['/user', 'operations']);
  }

}


