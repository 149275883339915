import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appRegexpInputCheck]'
})
export class RegexpInputCheckDirective {

  _inputRegexp: RegExp;

  constructor() { }

  @Input() set inputRegexp(input: RegExp) {
    this._inputRegexp = new RegExp(input);
  }

  @HostListener('keypress',  ['$event']) onKeyPress(event: KeyboardEvent) {
    const inputChar = event.key;
    if (event.keyCode !== 8 && !this._inputRegexp.test(inputChar)) {
      event.preventDefault();
    }
  }
}
