import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { CustomerDialogComponent } from '../../shared/components/customer-dialog/customer-dialog.component';
import { OperationResultDialogComponent } from '../../shared/components/operation-result-dialog/operation-result-dialog.component';
import { UserOperationsService } from '../user-operations.service';
import { DisconnectOperationsService } from '../../disconnect-operations/disconnect-operations.service';

import { ProfileService } from '../../shared/profile/profile.service';
import { UserSubscription } from '../../shared/user/model/user-subscription.model';
import { GlobalVariable } from '../../global';
import { OperationAmountValidators } from '../../shared/validators/operation-amount.validators';
import { Utils } from '../../shared/utils/Utilis';
import {ConfirmOperationComponent} from '../operations/confirm-operation/confirm-operation.component';
import {CustomerDetailsDialogComponent} from '../../shared/components/customer-details-dialog/customer-details-dialog.component';
import { ConfirmTransfertDialogComponent } from '../../shared/components/confirm-transfert-dialog/confirm-transfert-dialog.component';

import { TransfertRecapDialogComponent } from '../../shared/components/transfert-recap-dialog/transfert-recap-dialog.component';
export interface RecapData{
  amount:number; operationCost:number;
}
@Component({
  selector: 'app-transfert',
  templateUrl: './transfert.component.html',
  styleUrls: ['./transfert.component.css']
})

export class TransfertComponent implements OnInit {

  loadingCalculateReceivingAmount: boolean = false;
  customer: UserSubscription = null;
  operationCost: any = {
    cost: 0,
    targetMonetaryArea: {
      currencyShortName: ''
    }
  };
  fraisRetrait: any = {
    cost: 0,
    targetMonetaryArea: {
      currencyShortName: ''
    }
  };
  public reasons = [
    {
      name: 'professionnel',
      description: 'D\'ordre professionel'
    },
    {
      name: 'familial',
      description: 'D\'ordre familial'
    },
    {
      name: 'maladie',
      description: 'A cause d\'une maladie'
    },
    {
      name: 'mariage',
      description: 'Pour un mariage'
    },
    {
      name: 'commercial',
      description: 'D\'odre commercial'
    },
    {
      name: 'autres',
      description: 'Autres raison'
    }
  ];
  form: FormGroup;
  loading: boolean = false;
  loadingReceiver: boolean = false;
  dialogRef: MatDialogRef<OperationResultDialogComponent> | null;
  userConfirmDialogRef: MatDialogRef<OperationResultDialogComponent> | null;
  config = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '440px',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      message: ''
    }
  };

  customerDialogRef: MatDialogRef<CustomerDialogComponent> | null;
  customerDialogConfig = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '50%',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      phone: '',
      firstname: '',
      lastname: '',
    }
  };

  customerDetailsDialogRef: MatDialogRef<CustomerDetailsDialogComponent> | null;
  customerDetailsDialogConfig:MatDialogConfig = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '50%',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      phone: '',
      firstname: '',
      lastname: '',
    }
  };

  minimumOperationAmount = GlobalVariable.MINIMUM_TRANSFER_OPERATION_AMOUNT;
  recieverTelCountry: any;
  constructor(private fb: FormBuilder,
    private http: HttpClient,
    public dialog: MatDialog,
    private userOperationsService: UserOperationsService,
    private disconnectOperationService: DisconnectOperationsService,
    public utils: Utils,
    private profileService: ProfileService,
    ) {
  }

  ngOnInit() {

    this.form = this.fb.group({
      amount: [null,
        Validators.compose([Validators.required, OperationAmountValidators.amountCheck(this.minimumOperationAmount)]),
        Validators.composeAsync([])
      ],
      reason: [null,
        Validators.compose([]),
        Validators.composeAsync([])
      ],
      description: [null,
        Validators.compose([]),
        Validators.composeAsync([])
      ],
      receiver: this.fb.group({
        phone: [null,
          Validators.compose([Validators.required]),
          Validators.composeAsync([])
        ],
        firstname: [null,
          Validators.compose([Validators.required]),
          Validators.composeAsync([])
        ],
        lastname: [null,
          Validators.compose([Validators.required]),
          Validators.composeAsync([])
        ]
      })
    });

    this.customer = this.profileService.getProfileData();

    this.profileService.profile.subscribe(
      (response) => {
        this.customer = response;
      }
    );

  }

  onReceiverPhoneBlur() {
    if ((<FormGroup>this.form.controls['receiver']).controls['phone'].valid) {
      this.loadingReceiver = true;
      this.form.disable();
      const customerPhone = (<FormGroup>this.form.controls['receiver']).controls['phone'].value;
      this.userOperationsService.checkCustomer(customerPhone).subscribe(
        (response) => {
          (<FormGroup>this.form.controls['receiver']).controls['firstname'].setValidators([]);
          (<FormGroup>this.form.controls['receiver']).controls['lastname'].setValidators([]);
          this.loadingReceiver = false;
          this.form.enable();
          this.customerDetailsDialogConfig.data.phone = this.form.value.receiver.phone;
          this.customerDetailsDialogRef = this.dialog.open(CustomerDetailsDialogComponent, this.customerDetailsDialogConfig);
        },
        (error) => {
          if (error.status === 404) {
            this.customerDialogConfig.data = (<FormGroup>this.form.controls['receiver']).value;
            this.customerDialogRef = this.dialog.open(CustomerDialogComponent, this.customerDialogConfig);
            this.customerDialogRef.afterClosed().subscribe((result) => {
              (<FormGroup>this.form.controls['receiver']).patchValue(result);
              this.loadingReceiver = false;
              this.form.enable();
            });
            this.loadingReceiver = false;
            this.form.enable();
          }
        }
      );
    }
  }

  getReceiverNumber(e) {
    (<FormGroup>this.form.controls['receiver']).controls['phone'].setValue(e);
  }
  receiverPhoneHasError(e) {
    if (!e) {
      if (this.recieverTelCountry) {
        if (this.recieverTelCountry.dialCode !== '237') {
          (<FormGroup>this.form.controls['receiver']).controls['phone'].setErrors({ 'badPhone': true });
        } else {
          const phone = (<FormGroup>this.form.controls['receiver']).controls['phone'].value;
          if (phone !== null && phone !== undefined) {
            if (phone.indexOf('+') === -1) {
              (<FormGroup>this.form.controls['receiver']).controls['phone'].setValue('+' + this.recieverTelCountry.dialCode + phone);
            }
          }
        }
      } else {
        (<FormGroup>this.form.controls['receiver']).controls['phone'].setErrors({ 'badPhone': true });
      }
    }
  }
  onRecieverCountryChange(e) {
    this.recieverTelCountry = e;
  }
  onSubmit() {
    let formValue = this.form.value;
    this.loading = true;
    this.form.disable();
    const dialogRef = this.dialog.open(ConfirmTransfertDialogComponent,{width:'300px'})
    dialogRef.afterClosed().subscribe( res => {
        if(res === "OUI"){
          this.onCalculateOperationCost('DEBIT').then((res:any) => {
          formValue.amount=parseInt(res.cost)+parseInt(formValue.amount);
          const dialogRefRecap = this.dialog.open(TransfertRecapDialogComponent,{width: '500px', data: {
            amount: formValue.amount,
            operationCost: this.operationCost,
            receiver: formValue.receiver
          }})
          dialogRefRecap.afterClosed().subscribe(resp => {
            if(resp === 'SUBMIT'){

              this.userOperationsService.transfert(formValue).subscribe(
                (response: any) => {
                  this.config.data.message = 'Opération effectuée, le code de confirmation est : ' + response.confirmationCode;
                  this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
                  this.dialogRef.afterClosed().subscribe((result) => {
                    this.form.reset();
                    this.operationCost.cost = 0;
                    this.operationCost.targetMonetaryArea.currencyShortName = '';
                    this.loading = false;
                    this.form.enable();
                  });
                },
                (error) => {
                  let message = null;
                  const errorMessage = error.error.message;
                  if (errorMessage === 'INVALID PIN') {
                    message = 'Pin invalide';
                  } else if (errorMessage === 'INSUFFICIENT BALANCE') {
                    message = 'Solde insuffisant pour éffectuer cette opération';
                  } else if (errorMessage === 'SAME PHONE NUMBER') {
                    message = 'Le transfert vers votre propre numéro n\'est pas possible';
                  } else if (errorMessage === 'Country is not supported') {
                    message = 'Le pays du destinataire n\'est pas pris en charge.';
                  } else if (errorMessage === 'RESSOURCE_NOT_FOUND') {
                    message = 'L\'expéditeur du tranfert n\'a pas été trouvé';
                  } else if (errorMessage === 'ACCOUNT BLOCKED') {
                    message = 'Votre compte a été bloqué';
                  } else {
                    message = errorMessage;
                  }
                  this.config.data.message = message;
                  this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
                  this.dialogRef.afterClosed().subscribe((result) => {
                    this.loading = false;
                    this.form.enable();
                    this.form.reset();
                    this.operationCost = {
                      cost: 0,
                      targetMonetaryArea: {
                        currencyShortName: ''
                      }
                    };
                  });
                }
              );
            }else{
              this.loading = false;
                    this.form.enable();
                    this.form.reset();
                    this.operationCost = {
                      cost: 0,
                      targetMonetaryArea: {
                        currencyShortName: ''
                      }
                    };
            }
          })

            
          }); 
        
         }else if(res === 'NON'){
          const dialogRefRecap = this.dialog.open(TransfertRecapDialogComponent,{width: '500px', data: {
            amount: formValue.amount,
            operationCost: this.operationCost,
            receiver: formValue.receiver
          }})
          dialogRefRecap.afterClosed().subscribe(res => {
            if(res === 'SUBMIT'){
              this.userOperationsService.transfert(formValue).subscribe(
                (response: any) => {
                  this.config.data.message = 'Opération effectuée, le code de confirmation est : ' + response.confirmationCode;
                  this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
                  this.dialogRef.afterClosed().subscribe((result) => {
                    this.form.reset();
                    this.operationCost.cost = 0;
                    this.operationCost.targetMonetaryArea.currencyShortName = '';
                    this.loading = false;
                    this.form.enable();
                  });
                },
                (error) => {
                  let message = null;
                  const errorMessage = error.error.message;
                  if (errorMessage === 'INVALID PIN') {
                    message = 'Pin invalide';
                  } else if (errorMessage === 'INSUFFICIENT BALANCE') {
                    message = 'Solde insuffisant pour éffectuer cette opération';
                  } else if (errorMessage === 'SAME PHONE NUMBER') {
                    message = 'Le transfert vers votre propre numéro n\'est pas possible';
                  } else if (errorMessage === 'Country is not supported') {
                    message = 'Le pays du destinataire n\'est pas pris en charge.';
                  } else if (errorMessage === 'RESSOURCE_NOT_FOUND') {
                    message = 'L\'expéditeur du tranfert n\'a pas été trouvé';
                  } else if (errorMessage === 'ACCOUNT BLOCKED') {
                    message = 'Votre compte a été bloqué';
                  } else {
                    message = errorMessage;
                  }
                  this.config.data.message = message;
                  this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
                  this.dialogRef.afterClosed().subscribe((result) => {
                    this.loading = false;
                    this.form.enable();
                    this.form.reset();
                    this.operationCost = {
                      cost: 0,
                      targetMonetaryArea: {
                        currencyShortName: ''
                      }
                    };
                  });
                }
              );
            }else {
              this.loading = false;
                    this.form.enable();
                    this.form.reset();
                    this.operationCost = {
                      cost: 0,
                      targetMonetaryArea: {
                        currencyShortName: ''
                      }
                    };
            }
            
          })
          
         }
          
        
    })
    
  }

  onCalculateOperationCost(opType) {
    this.loadingCalculateReceivingAmount = true;
    const transfert = this.form.value;

    const body = {
      amount: transfert.amount,
      operationType: opType,
      sourceAccountType: 'CLASSIC',
      targetAccountType: 'CLASSIC',
      userCollect: null,
      sourceCountry: this.GetCountryCodeFromPhone(this.customer.phone),
      targetCountry: this.GetCountryCodeFromPhone(transfert.receiver.phone)
    };
    let promise = new Promise((resolve,rejects) =>{
      this.disconnectOperationService.calculateOperationCost(body).subscribe(
        (response: any) => {
          // console.log(response);
          if(opType==='DEBIT')
            this.fraisRetrait = response;
          else
            this.operationCost = response;
        //  opType=='TRANSFERT'?this.operationCost = response: this.fraisRetrait = response;
          this.loadingCalculateReceivingAmount = false;
          resolve(this.fraisRetrait);
        },
        (error) => {
          // console.log(error);
          this.loadingCalculateReceivingAmount = false;
          this.config.data.message = 'Erreur lors du calcul des frais.';
          if (error.error.children.targetCountry) {
            this.config.data.message = 'Le pays du destinataire n\'est pas pris en charge.';
          }
          this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
          this.dialogRef.afterClosed().subscribe((result) => {
            this.loading = false;
            this.form.enable();
          });
          rejects();
        }
      );
    })
    return promise;
  }

  private GetCountryCodeFromPhone(phone) {

    const phoneUtil = PhoneNumberUtil.getInstance();
    const countryPhoneCode = phoneUtil.parse(phone).getCountryCode();
    return phoneUtil.getRegionCodeForCountryCode(countryPhoneCode);
  }

  onReasonSelected(e) {
    this.form.controls['reason'].setValue(e);
  }
}
