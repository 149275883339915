export class PrintUtils {

  public static printBlock(blockID) {
    const innerContents = document.getElementById(blockID).innerHTML;

    const iframe = document.createElement('iframe');
    iframe.setAttribute('id', 'print-frame');
    iframe.style.visibility  = 'hidden';
    iframe.style.position  = 'absolute';
    iframe.style.top  = '0px';
    iframe.style.width  = '0px';
    iframe.style.height  = '0px';

    iframe.setAttribute('srcdoc', `<html>
        <head>
          <link rel="stylesheet" type="text/css" href="/assets/styles/css/print-style.css" />
        </head>
        <body onload="window.print();">`
      + innerContents +
      `<script type="text/javascript">
        </script>
        </body>
      </html>`);

    document.getElementById(blockID).parentElement.appendChild(iframe);
  }

}
