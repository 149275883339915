import { Component, OnInit } from '@angular/core';
import { NgxCarousel } from 'ngx-carousel';
import { PlatformConfig} from '../../app-loader/platform-config';
import { ConfigLoaderService } from '../../app-loader/config-loader.service';

@Component({
  selector: 'app-slide-content',
  templateUrl: './slide-content.component.html',
  styleUrls: ['./slide-content.component.css']
})
export class SlideContentComponent implements OnInit {

  public carouselOne: NgxCarousel;

  slides: string;

  platformConfig: PlatformConfig;

  constructor(public configLoaderService: ConfigLoaderService) {
    this.platformConfig = this.configLoaderService.getPlatformConfig();
    this.slides = this.platformConfig.platform.clientHomeSlider;
  }

  ngOnInit() {

    this.carouselOne = {
      grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
      slide: 1,
      speed: 500,
      interval: 10000,
      point: {
        visible: true,
        pointStyles: `
          .ngxcarouselPoint {
            list-style-type: none;
            text-align: center;
            padding: 12px;
            margin: 0;
            white-space: nowrap;
            overflow: auto;
            position: absolute;
            width: 100%;
            bottom: 20px;
            left: 0;
            box-sizing: border-box;
          }
          .ngxcarouselPoint li {
            display: inline-block;
            border-radius: 999px;
            background: rgba(255, 255, 255, 0.55);
            padding: 5px;
            margin: 0 3px;
            transition: .4s ease all;
            cursor: pointer;
          }
          .ngxcarouselPoint li.active {
              background: #fff;
              width: 10px;
          }
        `
      },
      load: 2,
      touch: true,
      loop: true,
      custom: 'banner'
    };
  }

  public myfunc(event: Event) {
    // carouselLoad will trigger this funnction when your load value reaches
    // it is helps to load the data by parts to increase the performance of the app
    // must use feature to all carousel
  }

}
