import {APP_INITIALIZER, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import {ConfigLoaderService} from './config-loader.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ConfigLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: configLoaderServiceFactory,
      deps: [
        ConfigLoaderService,
      ],
      multi: true
    },
  ]
})
export class AppLoaderModule {

}

export function configLoaderServiceFactory(configLoaderService: ConfigLoaderService) {
  return () => configLoaderService.loadPlatformConfig();
}
