<div class="footer">
  <div class="footer-content">
    <div class="footer-content-item">
      <ul>
        <li>Email : {{ platformConfig.platform.contactEmail }}</li>
      </ul>
    </div>
    <div class="footer-content-item">
      <ul>
        <li>Tél : {{ platformConfig.platform.contactPhone }}</li>
      </ul>
    </div>
    <div class="footer-content-item">
      <ul>
        <li>Adresse : {{ platformConfig.platform.contactAddress }}</li>
      </ul>
    </div>
  </div>
  <div class="copy-content">
    <h3>Copyright &copy; BESTCASH Technologies </h3>
    <img src="../../assets/images/uba-logo.png" alt="" style="height: 50px; width: 50px; object-fit: contain;">
  </div>
</div>
