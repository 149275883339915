import {Component, OnInit, ChangeDetectorRef, HostListener, 
 AfterViewInit } from '@angular/core';
// import { ISlimScrollOptions } from 'ng2-slimscroll';
import { SidebarService } from './sidebar/sidebar.service';
import {ActivatedRoute, Data, NavigationStart, Router} from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';

import {UserSubscription} from '../shared/user/model/user-subscription.model';
import {ProfileService} from '../shared/profile/profile.service';
import { MatPaginatorIntl, MatDialog} from '@angular/material';
import {GlobalVariable} from '../global';
import {AuthService} from '../shared/auth/auth.service';
import {UserService} from '../shared/user/user.service';
import { ConfigLoaderService } from '../app-loader/config-loader.service';
import { PlatformConfig } from '../app-loader/platform-config';
import {Observable} from 'rxjs/Observable';
import { TermsComponent } from '../shared/components/terms/terms.component';
import { PinChangeComponent } from '../shared/components/pin-change/pin-change.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, AfterViewInit {

  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  // opts: ISlimScrollOptions;

  sidebarOpened: boolean =  true;

  profile: UserSubscription = new UserSubscription();

  TEST_MODE = GlobalVariable.TEST_MODE;
  lastAction = Date.now();
  setIntervalId: any;
  logoutTimeout: number = 15; // in minutes
  platformConfig: PlatformConfig;
  subscription: any;
  constructor(private sidebarService: SidebarService,
              private route: ActivatedRoute,
              private profileService: ProfileService,
              private matPaginatorIntl: MatPaginatorIntl,
              private changeDetectorRef: ChangeDetectorRef,
              private media: MediaMatcher,
              private userService: UserService,
              private dialog: MatDialog,
              private configLoaderService: ConfigLoaderService,
              private router: Router) {

    this.platformConfig = this.configLoaderService.getPlatformConfig();
    this.mobileQuery = media.matchMedia('(max-width: 960px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    this.route.data.subscribe(
      (data: Data) => {
        this.profile = data['profile'];
      }
    );

    this.profileService.profile.subscribe(
      (response) => {
        this.profile = response;
      }
    );

    if ( this.mobileQuery.matches ) {
      this.sidebarOpened = false;
    }

    this.router.events
      .filter( event => event instanceof NavigationStart )
      .subscribe(
        (event: NavigationStart) => {
          if (this.mobileQuery.matches) {
            this.sidebarOpened = false;
          }
        }
      );
    if (this.platformConfig.platform.clientConnectionTimeout !== null &&
      this.platformConfig.platform.clientConnectionTimeout !== undefined) {
      this.logoutTimeout = this.platformConfig.platform.clientConnectionTimeout;
    }
    // Time out
    this.initTimer(this.logoutTimeout);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if ( this.mobileQuery.matches ) {
      this.sidebarOpened = false;
    } else {
      this.sidebarOpened = true;
    }
  }

  ngOnInit() {
    this.matPaginatorIntl.itemsPerPageLabel = 'Nombres d\'éléments par page';
    this.sidebarService.sidebarStatus.subscribe(
      () => {
        this.sidebarOpened = !this.sidebarOpened;
      }
    );
    
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.profile.lastChangePassword) {
        this.showPinChangeDialog();
        this.showTermDialog();
      }
    }, 2000);
  }
  @HostListener('mouseover', ['$event']) onDocummentClick(e: KeyboardEvent) {
    e.preventDefault();
    this.clearTimer();
    this.initTimer(this.logoutTimeout);
  }
  clearTimer () {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  initTimer (endTime: number) {
    const interval = 1000;
    const duration = endTime * 60;
    this.subscription = Observable.timer(0, interval)
      .take(duration)
      .subscribe(value => {},
        err => { console.log(err); },
        () => {
          this.userService.currentUser.next(null);
          this.router.navigate(['/user-connection']);
        });
  }

  showTermDialog() {
    const dialogRef = this.dialog.open(TermsComponent, {
      width: '750px',
      height: '80%',
      disableClose: true,
      data: { for: "first-connexion" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //  this.form.enable();
      //  this.form.reset();
    });
  }

  showPinChangeDialog() {
    const dialogRef = this.dialog.open( PinChangeComponent, {
      width: '450px',
      height: '80%',
      disableClose: true,
      data: { for: "first-connexion" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //  this.form.enable();
      //  this.form.reset();
    });
  }
}
