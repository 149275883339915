<div class="app-report-content">
  <div class="report-container">
    <mat-toolbar class="page-title">
      <span [appAppColor]="{default : ['color']}"><mat-icon class="page-title-icon" role="img" aria-hidden="true">description</mat-icon> Relevé</span>
    </mat-toolbar>
    <div class="report-search-content">
      <form class="report-search-form">
        <div class="report-item-content">
          <mat-form-field>
            <mat-select placeholder="Période" (valueChange)="onSelectPeriod($event)" >
              <mat-option *ngFor="let period of periods" [value]="period.value">
                {{ period.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="report-item-content">
          <mat-form-field>
            <input [formControl]="form.controls['date']['controls']['value']"
                   matInput [matDatepicker]="FirstDatepicker" placeholder="Date Début"
                   [max]="maxDate" [min] = "minDate" (focus)="FirstDatepicker.open()">
            <mat-datepicker-toggle matSuffix [for]="FirstDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #FirstDatepicker [disabled]="form.disabled"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="report-item-content">
          <mat-form-field>
            <input [formControl]="form.controls['date']['controls']['value2']"
                   matInput [matDatepicker]="LastDatepicker" placeholder="Date Fin"
                   [min] = "minDate" [max] ="maxDate" (focus)="LastDatepicker.open()">
            <mat-datepicker-toggle matSuffix [for]="LastDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #LastDatepicker [disabled]="form.disabled"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="report-item-content">
          <button mat-raised-button mat-ripple
                  matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true"
                  [appAppColor]="{default : ['background-color']}"  (click)="onSubmit()" [disabled]="!form.valid" >
            <mat-progress-spinner mode="indeterminate" [diameter]="25" [strokeWidth]="2"
                                  *ngIf="loading" >
            </mat-progress-spinner>
            Valider<mat-icon>search</mat-icon>
          </button>
        </div>
      </form>
    </div>
    <app-report-dialog *ngIf="reportsLoaded"
                       [dates]="reportsData.dates"
                       [operations]="reportsData.operations"
                       [parameters]="reportsData.parameters" >
    </app-report-dialog>
  </div>
</div>

