export const GlobalVariable = Object.freeze({
  // local
  // BASE_API_URL: 'http://bestcash.it4u.lan/app_test.php/customer',
  // BASE_MEDIA_URL: 'http://bestcash.it4u.lan/',
  // PUBLIC_API_URL: 'http://bestcash.it4u.lan/app_test.php/public',
  // prod
  // BASE_API_URL: 'https://svc.bestcash.me/customer',
  // BASE_MEDIA_URL: 'https://svc.bestcash.me/',
  // BASE_MEDIA_URL: 'https://svc.bestcash.me/public',
  // test
  BASE_API_URL:   'https://svc.test.bestcash.me/customer',
  BASE_MEDIA_URL: 'https://svc.test.bestcash.me/',
  PUBLIC_API_URL: 'https://svc.test.bestcash.me/public',
  MINIMUM_TRANSFER_OPERATION_AMOUNT: 10,
  MINIMUM_PAYEMENT_OPERATION_AMOUNT: 10,
  MINIMUM_COLLECT_PRODUCT_AMOUNT: 10,
  TEST_MODE: true,
  TRANSFER_REASONS: [
    {
      name: 'professionnel',
      description: 'D\'ordre professionel'
    },
    {
      name: 'familial',
      description: 'D\'ordre familial'
    },
    {
      name: 'maladie',
      description: 'A cause d\'une maladie'
    },
    {
      name: 'mariage',
      description: 'Pour un mariage'
    },
    {
      name: 'commercial',
      description: 'D\'odre commercial'
    },
    {
      name: 'autres',
      description: 'Autres raison'
    }
  ]
});
