<div class="app-contact-content">
  <div class="contact-container">
    <mat-toolbar class="page-title">
      <span [appAppColor]="{default : ['color']}"><mat-icon class="page-title-icon" role="img" aria-hidden="true">forum</mat-icon> Contactez-Nous</span>
    </mat-toolbar>
    <div class="contact-container-content">
      <div class="contact-text-part">
        <p>
          Remplissez ce champ pour nous laisser un message
        </p>
      </div>
      <form [formGroup]="form" (submit)="onContactSubmit()">
        <div class="contact-input-container">
          <div class="contact-input-item">
            <mat-form-field class="example-full-width">
              <textarea [formControl]="form.controls['message']" matInput #transfertDescription maxlength="1000" placeholder="Message"></textarea>
              <mat-hint align="end">{{transfertDescription.value.length}} / 1000</mat-hint>
              <mat-error *ngIf="form.controls['message'].hasError('required') && form.controls['message'].touched">
                Ce champ est requis
              </mat-error>
            </mat-form-field>
          </div>
          <div class="contact-input-item">
            <button [disabled]="!form.valid || loading" type="submit" mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true" [appAppColor]="{default : ['background-color']}">
              Envoyer
              <mat-spinner *ngIf="loading" class="contact-spinner" [strokeWidth]="2" [diameter]="25"></mat-spinner>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
