import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/map';

import { AboutService } from './about.service';
import { RequestParameters } from '../shared/models/request-parameters/parameter.model';
import { GlobalVariable } from '../global';
import { ConfigLoaderService } from '../app-loader/config-loader.service';
import { PlatformConfig } from '../app-loader/platform-config';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  countryCtrl: FormControl;
  parameters = new RequestParameters();
  countries: any = [];
  dtSource: any = [];
  constCountries: any;
  cities: any = [];
  agencies: any = [];
  loadingAgencies: boolean = false;
  loadingCities: boolean = false;
  citySelected: string = '';
  noCityExist: boolean = true;
  cityActive: any = null;
  TEST_MODE = GlobalVariable.TEST_MODE;
  sourceCountryCode: string;
  targetCountryCode: string;
  platformConfig: PlatformConfig;

  appColorAgency = { active: ['border-color'] };

  constructor(private aboutService: AboutService, public configLoaderService: ConfigLoaderService) {

    this.platformConfig = this.configLoaderService.getPlatformConfig();
    this.aboutService.getCountries().subscribe(
      (response: any) => {
        this.countries = response.result;
        this.countryCtrl.setValue(this.countries[0].name);

        /** Set default Country as the one in the platformConfig **/
        const currentCountry = this.countries.filter(
          (country) => {
            return country.countryCode.toLowerCase() === this.platformConfig.clientCountryCode.toLowerCase();
          }
        )[0];
        if (currentCountry !== null && currentCountry !== undefined) {
          this.countryCtrl.setValue(currentCountry.name);
          this.getCities({ option: { value: currentCountry.name } });
        } else {
          this.getCities();
        }

        this.constCountries = response.result;
        this.aboutService.countriesSubject.next(this.countries);
      }, (error) => {
        // console.log(error);
      }
    );
    this.countryCtrl = new FormControl();

  }

  ngOnInit() {
  }

  onCountryEnter() {
    if (this.countryCtrl.value) {
      this.countries = this.countries.filter(
        country =>
          country.name.toLowerCase().indexOf(this.countryCtrl.value.toLowerCase()) === 0
      );
    } else {
      this.countries = this.constCountries;
    }
  }
  getCountryOperationCost(sourceCountry: any, targetCountry: any){
    this.aboutService.getCountryTarif(sourceCountry.value, targetCountry.value).subscribe( (res: any) => {
      this.dtSource = res;
    });
  }
  onSourceCountryChange(countrycode: string){
    this.dtSource = [];
   this.sourceCountryCode = countrycode;
   if(this.targetCountryCode != null && this.targetCountryCode !== undefined){
     this.getCountryOperationCost(countrycode, this.targetCountryCode);
   }
  }
  onTargetCountryChange(targetCountry: string){
    this.targetCountryCode = targetCountry;
    this.dtSource = [];
    if (this.sourceCountryCode != null && this.sourceCountryCode !== undefined) {
      this.getCountryOperationCost(this.sourceCountryCode, targetCountry);
    }
  }
  getCities(e = null) {
    this.loadingCities = true;
    this.noCityExist = true;
    let countrySelected = null;
    if (e) {
      countrySelected = this.countries.filter((country) => country.name === e.option.value);
      countrySelected = countrySelected[0];
    } else {
      countrySelected = this.countries[0];
    }
    this.aboutService.getCities(countrySelected.reference).subscribe(
      (response: any) => {
        this.cities = response.result;
        this.loadingCities = false;
        if (this.cities.length) {
          this.getAgencies();
        } else {
          this.agencies = [];
          this.citySelected = null;
          this.noCityExist = false;
        }
      }, (error) => {
        // console.log(error);
      }
    );
  }

  getAgencies(city = null) {
    let cityChoose = null;
    if (city) {
      cityChoose = city;
    } else {
      cityChoose = this.cities[0];
      this.cityActive = this.cities[0].name;

    }
    this.loadingAgencies = true;
    this.citySelected = cityChoose.name;
    this.aboutService.getAgencies(cityChoose.reference).subscribe(
      (response: any) => {
        this.agencies = response.result;
        this.loadingAgencies = false;
      }, (error) => {
        // console.log(error);
      }
    );
  }

}
