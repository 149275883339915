import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
export interface RecapData{
  amount:number; operationCost:any; receiver:any;
}
@Component({
  selector: 'app-transfert-recap-dialog',
  templateUrl: './transfert-recap-dialog.component.html',
  styleUrls: ['./transfert-recap-dialog.component.css']
})
export class TransfertRecapDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TransfertRecapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RecapData,) { }

  ngOnInit() {
  }
  onOuiClick(){
    this.dialogRef.close('SUBMIT');
  }
  onNonClick(){
    this.dialogRef.close('CANCEL');
  }
}
