<div class="report-results-content">
  <div class="report-title">
    <ul>
      <li>Relevé des opérations</li>
      <li>Du {{ dates.value | date:'yyyy-MM-dd':'':'fr' }} au {{ dates.value2 | date:'yyyy-MM-dd':'':'fr' }}</li>
    </ul>
  </div>
  <div class="solde-content" *ngIf="operations.length !== 0">
    <ul>
      <li [appAppColor]="{default : ['color']}">Solde Initial :</li>
      <li>  {{((operations[0].lastBalance | floorAmount) | currency: 'XOF':'') +" "+operations[0].prefixed}}</li>
    </ul>
    <ul>
      <li [appAppColor]="{default : ['color']}">Solde Final :</li>
      <!-- <li>{{ operations[operations.length-1].prefixedNewBalance }}</li> -->
      <li>  {{((operations[operations.length-1].newBalance | floorAmount) | currency: 'XOF':'') +" "+operations[operations.length-1].prefixed}}</li>
    </ul>
  </div>
  <table>
    <thead [appAppColor]="{default : ['background-color']}">
    <tr>
      <th>Date</th>
      <th>Code</th>
      <th>Description</th>
      <th>Montant</th>
      <th>Coût operation</th>
      <th>Solde</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="operations.length === 0">
      <th colspan="5" style="text-align: center">Aucune opération dans cette période</th>
    </tr>
    <tr *ngFor="let operation of operations">
      <th data-label="Date" class="align-left">{{ operation.operationDate | date:'yyyy-MM-dd HH:mm':'':'fr' }}</th>
      <th data-label="Code" class="align-left">{{ operation.operationCode }}</th>
      <th data-label="Description" class="align-left">{{operation.description}}</th>
      <th data-label="Montant">
        <app-operation-amount-movement-indicator [operation]="operation"></app-operation-amount-movement-indicator>
        <li>  {{((operation.amount | floorAmount) | currency: 'XOF':'') +" "+operation.prefixed}}</li>
      </th>
      <th data-label="Coût operation">{{((operation.operationCost | floorAmount) | currency: 'XOF':'') +" "+operation.prefixed}}</th>
      <th data-label="Solde"> <li>  {{((operation.newBalance | floorAmount) | currency: 'XOF':'') +" "+operation.prefixed}}</li></th>
    </tr>
    </tbody>
  </table>
</div>
<div class="report-footer" *ngIf="operations.length !== 0">
  <div class="print-button">
    <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
            matRippleCentered="true" [appAppColor]="{default : ['background-color']}" (click)="onPrint()">
      <mat-icon>print</mat-icon> Imprimer
    </button>
  </div>
</div>

<div id="print-div" style="width: 793px; height: auto; margin: 0 auto; padding: 20px; box-sizing: border-box; position: relative; display: block; overflow: hidden; display: none;" class="print-content" *ngIf="operations.length !== 0">

  <div class="print-header-content" style="width: 100%; padding: 0; margin: 0px 0 20px 0; float: left; box-sizing: border-box; display: block;">
    <div class="print-logo" style="width: 200px; float: left; margin: 0; padding: 0;">
      <img [src]="platformConfig.platform.logo" alt="logo" style="width: 120px;">
    </div>
    <div class="print-header-text" style="width: calc(100% - 200px); float: left; margin: 0; padding: 0;" [innerHtml]="platformConfig.platform.receiptHeader">
    </div>
  </div>

  <div class="print-title" style="width: 100%; margin: 0 0 15px 0; padding: 0; text-align: center; float: left;">
    <ul>
      <li style="list-style: none; display: block; text-decoration: underline;">Relevé des opérations <span>Du {{ dates.value | date:'dd/MM/yyyy':'':'fr' }}  Au {{ dates.value2 | date:'dd/MM/yyyy':'':'fr' }}</span></li>
    </ul>
  </div>

  <div class="solde-content" style="width: 100%; margin: 0 0 15px 0; padding: 0; float: left;">
    <ul style="float: left; margin: 0 40px 0 0; padding: 0;">
      <li style="list-style: none; color: #000000; display: inline-block;">Solde Initial :</li>
      <li style="list-style: none; color: #000000; display: inline-block;">{{((operations[0].lastBalance | floorAmount) | currency: 'XOF':'') +" "+operations[0].prefixed}}</li>
    </ul>
    <ul style="float: left; margin: 0; padding: 0;">
      <li style="list-style: none; color: #000000; display: inline-block;">Solde Final :</li>
      <li style="list-style: none; color: #000000; display: inline-block;">{{((operations[operations.length-1].newBalance | floorAmount) | currency: 'XOF':'') +" "+operations[operations.length-1].prefixed}}</li>
    </ul>
  </div>

  <table style="margin-bottom: 30px; border-collapse: collapse; width: 100%; font-size: 12px; font-weight: normal;">
    <thead>
    <tr>
      <th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; text-align: center;
      -webkit-print-color-adjust: exact; -moz-print-color-adjust: exact; width: 70px;
      -o-print-color-adjust: exact; -ms-print-color-adjust: exact;
      print-color-adjust: exact; padding: 8px 10px; text-align: left;">Date</th>
      <th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; text-align: center;
      -webkit-print-color-adjust: exact; -moz-print-color-adjust: exact; width: 70px;
      -o-print-color-adjust: exact; -ms-print-color-adjust: exact;
      print-color-adjust: exact; padding: 8px 10px; text-align: left;">Code</th>
      <th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; text-align: center;
      -webkit-print-color-adjust: exact; -moz-print-color-adjust: exact; width: auto;
      -o-print-color-adjust: exact; -ms-print-color-adjust: exact;
      print-color-adjust: exact; padding: 8px 10px; text-align: left;">Description</th>
      <th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; text-align: center;
      -webkit-print-color-adjust: exact; -moz-print-color-adjust: exact; width: auto;
      -o-print-color-adjust: exact; -ms-print-color-adjust: exact;
      print-color-adjust: exact; padding: 8px 10px; text-align: right;">Montant</th>
      <th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; text-align: center;
      -webkit-print-color-adjust: exact; -moz-print-color-adjust: exact; width: auto;
      -o-print-color-adjust: exact; -ms-print-color-adjust: exact;
      print-color-adjust: exact; padding: 8px 10px; text-align: right;">Coût</th>
      <th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; text-align: center;
      -webkit-print-color-adjust: exact; -moz-print-color-adjust: exact; width: auto;
      -o-print-color-adjust: exact; -ms-print-color-adjust: exact;
      print-color-adjust: exact; padding: 8px 10px; text-align: right;">Solde </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let operation of operations">
      <th style="border: solid 1px #000000; padding: 5px; text-align: left; font-size: .9em;">{{ operation.operationDate | date:'yyyy-MM-dd HH:mm':'':'fr' }}</th>
      <th style="border: solid 1px #000000; padding: 5px; text-align: left; font-size: .9em;">{{ operation.operationCode }}</th>
      <th style="border: solid 1px #000000; padding: 5px; text-align: left; font-size: .9em;">{{ operation.description }}</th>
      <th style="border: solid 1px #000000; padding: 5px; text-align: right; font-size: .9em;">
        {{((operation.amount | floorAmount) | currency: 'XOF':'') +" "+operation.prefixed}}
      </th>
      <th style="border: solid 1px #000000; padding: 8px 10px; text-align: right; font-size: .9em;">{{((operation.operationCost | floorAmount) | currency: 'XOF':'') +" "+operation.prefixed}}</th>
      <th style="border: solid 1px #000000; padding: 8px 10px; text-align: right; font-size: .9em;">{{((operation.newBalance | floorAmount) | currency: 'XOF':'') +" "+operation.prefixed}}</th>
    </tr>
    </tbody>
  </table>

  <div class="receipt-footer" style="width: 100%; box-sizing: border-box; display: block; overflow: hidden; margin: 0; padding: 0;" [innerHtml]="platformConfig.platform.receiptFooter"></div>

</div>

<style>
  .print-header-text:nth-child(n)
  {
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1.3em !important;
  }
</style>


