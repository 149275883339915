import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {throwError} from 'rxjs/internal/observable/throwError';

import {GlobalVariable} from '../global';
import {RequestParameters} from '../shared/models/request-parameters/parameter.model';
import {Transfert} from '../shared/transfert.model';
import {
  PermanentTransfert
} from './permanent-transfert/permanent-transfert.model';
import { DatePipe } from '@angular/common';

@Injectable()
export class UserOperationsService {
  operationSelected: any;
  datePipe = new DatePipe('en');
  constructor(private http: HttpClient) { }

  deletePermanentTransfert(permanentTransfert: PermanentTransfert) {

    return this.http.delete(GlobalVariable.BASE_API_URL + '/permanent-transfert/'+permanentTransfert.reference,
     {}
    ).map(
      (response: HttpResponse<any>) => {
        return response;
      }
    );
  }

  updatePermanentTransfert(permanentTransfert: PermanentTransfert) {

    const body = this.normalizePermanentTransfertOperationData(permanentTransfert);

    return this.http.put(GlobalVariable.BASE_API_URL + '/permanent-transfert/'+permanentTransfert.reference,
      body
    ).map(
      (response: HttpResponse<any>) => {
        return response;
      }
    );
  }
  pausePermanentTransfert(permanentTransfert: PermanentTransfert){
    return this.http.put(GlobalVariable.BASE_API_URL + '/permanent-transfert/pause/'+permanentTransfert.reference,{});
  }
  restartPermanentTransfert(permanentTransfert: PermanentTransfert){
    return this.http.put(GlobalVariable.BASE_API_URL + '/permanent-transfert/restart/'+permanentTransfert.reference,{});
  }
  permanentTransfert(permanentTransfert: PermanentTransfert) {

    const body = this.normalizePermanentTransfertOperationData(permanentTransfert);

    return this.http.post(GlobalVariable.BASE_API_URL + '/permanent-transferts',
      body
    ).map(
      (response: HttpResponse<any>) => {
        return response;
      }
    );
  }

  getPermenentTransfertOperations(parameters: RequestParameters = new RequestParameters()) {

    return this.http.get(GlobalVariable.BASE_API_URL + '/permanent-transferts', {
      params: parameters.getHttpParams(),
    })
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );
  }

  getOngoingOperations(parameters: RequestParameters = new RequestParameters()) {

    return this.http.get(GlobalVariable.BASE_API_URL + '/logged/operations-on-going', {
      params: parameters.getHttpParams(),
    })
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );
  }

  getOperations(parameters: RequestParameters = new RequestParameters()) {
    return this.http.get(GlobalVariable.BASE_API_URL + '/logged/operations', {
      params: parameters.getHttpParams(),
    })
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );
  }

  checkCustomer(phone: string) {

    return this.http.get(GlobalVariable.BASE_API_URL + '/logged-out/customer/' + phone + '/check', {
      }
    ).map(
      (response: HttpResponse<any>) => {
        return response;
      }
    );
  }

  transfert(transfert: Transfert) {

    return this.http.post(GlobalVariable.BASE_API_URL + '/logged/customer/operations/transfert',
      transfert
    ).map(
      (response: HttpResponse<any>) => {
        return response;
      }
    );
  }
  createDebitATM(data:any){
    return this.http.post(GlobalVariable.BASE_API_URL + '/logged/operations/debit-atm', data);
  }
  cancelDebitATM(operation:any){
    return this.http.post(GlobalVariable.BASE_API_URL + '/logged/operations-debit-atm/cancel',{operation: operation.reference});
  }
  cancelOperation(operation: any) {

    return this.http.post(GlobalVariable.BASE_API_URL + '/logged/operations/cancel', {
        operation: operation.reference
      }
    ).map(
      (response: HttpResponse<any>) => {
        return response;
      }
    );
  }

  cancelDebitOperation(operation: any) {

    return this.http.post(GlobalVariable.BASE_API_URL + '/logged/operations-debit/cancel', {
        operation: operation.reference
      }
    ).map(
      (response: HttpResponse<any>) => {
        return response;
      }
    );
  }
  confirmOperation(operation: any, confirmationCode: string) {

    return this.http.post(GlobalVariable.BASE_API_URL + '/logged/operations/confirm', {
        operation: operation.reference,
        confirmationCode: confirmationCode
      }
    ).map(
      (response: HttpResponse<any>) => {
        return response;
      }
    );
  }

  getReport(parameters: RequestParameters) {

    return this.http.get(GlobalVariable.BASE_API_URL + '/logged/report/operations', {
        params: parameters.getHttpParams(),
      }
    ).map(
      (response: any) => {
        return response;
      }
    );
  }

  private normalizePermanentTransfertOperationData(operation: PermanentTransfert): PermanentTransfert {
    const body: PermanentTransfert = Object.assign({}, operation);
    delete body.reference;
    delete body.recipient;
    if (body.nextExecutionDate !== null && body.nextExecutionDate !== undefined) {
      body.nextExecutionDate = this.datePipe.transform(body.nextExecutionDate, 'yyyy-MM-dd');
    }
    return body;
  }

}
