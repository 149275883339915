<div class="inner-disconnect-container-content">
  <div class="app-disconnect-pin-container">
    <div class="pin-operation-title">
      <mat-toolbar class="page-title">
        <span [appAppColor]="{default : ['color']}">Changer mon PIN</span>
      </mat-toolbar>
    </div>
    <form [formGroup]="form" class="disconnect-pin-form">
      <div class="pin-form-elements">
        <div class="disconnect-pin-input-item">
          <mat-form-field class="tel-input-form">
            <input matInput placeholder="Votre numéro de téléphone" value=""
                   [formControl]="form.controls['phone']"
                   ng2TelInput
                   (countryChange)="onCountryChange($event)"
                   (ng2TelOutput)="getPhone($event)"
                   [ng2TelInputOptions]="utils.selectorOptions"
                   (hasError)="phoneHasError($event)">
            <mat-error *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched">
              Ce champ est requis
            </mat-error>
            <mat-error *ngIf="form.controls['phone'].hasError('badPhone') && form.controls['phone'].touched">
              Numéro de téléphone invalide
            </mat-error>
          </mat-form-field>
        </div>
        <div class="disconnect-pin-input-item">
          <mat-form-field>
            <input type="password" matInput placeholder="Votre code pin" value=""
                   [formControl]="form.controls['oldPassword']">
            <mat-error *ngIf="form.controls['oldPassword'].hasError('required') && form.controls['oldPassword'].touched">
              Ce champ est requis
            </mat-error>
          
          </mat-form-field>
        </div>
        <div class="disconnect-pin-input-item">
          <mat-form-field>
            <input type="tel" matInput placeholder="Nouveau code pin" value=""
                   [formControl]="form.controls['plainPassword']['controls']['first']" #password style="-webkit-text-security:disc;">
            <mat-error *ngIf="form.controls['plainPassword']['controls']['first'].hasError('required') && form.controls['plainPassword']['controls']['first'].touched">
              Ce champ est requis
            </mat-error>
            <mat-error *ngIf="form.controls['plainPassword']['controls']['first'].hasError('pattern') && form.controls['plainPassword']['controls']['first'].touched">
              La valeur entré doit être numérique
            </mat-error>
          </mat-form-field>
        </div>
        <div class="disconnect-pin-input-item">
          <mat-form-field>
            <input type="password" matInput placeholder="Confirmer le nouveau code pin" value=""
                   [formControl]="form.controls['plainPassword']['controls']['second']" #confirmPassword>
            <mat-error *ngIf="form.controls['plainPassword']['controls']['second'].hasError('required') && form.controls['plainPassword']['controls']['second'].touched">
              Ce champ est requis
            </mat-error>
            <mat-error *ngIf="form.controls['plainPassword']['controls']['second'].hasError('MatchPassword') && form.controls['plainPassword']['controls']['second'].touched">
              Les mots de  passe doivent correspondre
            </mat-error>
            <mat-error *ngIf="form.controls['plainPassword']['controls']['second'].hasError('pattern') && form.controls['plainPassword']['controls']['second'].touched">
              La valeur entré doit être numérique
            </mat-error>
          </mat-form-field>
        </div>
        <mat-toolbar class="disconnect-pin-footer">
          <button (click)="onSubmit()" mat-raised-button
                  mat-ripple matRippleColor="rgba(255,255,255,.5)"
                  matRippleCentered="true" [appAppColor]="{default : ['background-color']}" [disabled]="!form.valid || loading">
            <mat-progress-spinner mode="indeterminate" class="primary" [diameter]="25" [strokeWidth]="2" *ngIf="loading" >
            </mat-progress-spinner>
            Changer
          </button>
        </mat-toolbar>
      </div>
    </form>
  </div>
</div>
