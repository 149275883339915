import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataSource} from '@angular/cdk/collections';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import 'rxjs/add/observable/of';

import { PlatformConfig} from '../../app-loader/platform-config';
import { ConfigLoaderService } from '../../app-loader/config-loader.service';
import {TARIFFS} from './tariffs';
import {AboutService} from '../about.service';
import {HttpHeaders} from '@angular/common/http';

export interface OperationCost {
  lowerAmount: string;
  topAmount: string;
  cost: string;
}

/**
 * Data source to provide what data should be rendered in the table. The observable provided
 * in connect should emit exactly the data that should be rendered by the table. If the data is
 * altered, the observable should emit that new set of data on the stream. In our case here,
 * we return a stream that contains only one set of data that doesn't change.
 */
export class OperationsCostsDataSource extends DataSource<any> {

  constructor(private operationCosts) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<OperationCost[]> {
    return Observable.of(this.operationCosts);
  }

  disconnect() {}
}

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.css']
})
export class TariffComponent implements OnInit {

  displayedColumns = ['lowerAmount', 'cost'];
  countrySourceCtrl = new FormControl();
  countryDestCtrl = new FormControl();
  showOperationCost = true;
  platformConfig: PlatformConfig;
  @Input() tariffs: any;
  @Input() dtSource: any;
  @Output() sourceCountryChange = new EventEmitter<string>();
  @Output() targetCountryChange = new EventEmitter<string>();
  // tariffs = TARIFFS.tariffs;
  dataSource: OperationsCostsDataSource;

  constructor(public configLoaderService: ConfigLoaderService, private aboutService: AboutService) {
    this.platformConfig = this.configLoaderService.getPlatformConfig();
    // this.getCountries();
  }

  ngOnInit() {

    // let currentTariff = this.tariffs.filter(
    //   (tariff) => {
    //     return tariff.countries.includes(this.platformConfig.clientCountryCode);
    //   }
    // )[0];
    // if (currentTariff === null || currentTariff === undefined) {
    //   currentTariff = this.tariffs[0];
    // }
    // this.dataSource = new OperationsCostsDataSource(currentTariff.operationCosts);
    // this.countrySourceCtrl.setValue(currentTariff.name);
  }

  onCountryChange(e) {
    this.showOperationCost = false;
    const data = TARIFFS.tariffs.filter(
      (tariff) => {
        return tariff.name === this.countrySourceCtrl.value;
      })[0];
    this.dataSource = new OperationsCostsDataSource(data.operationCosts);
    this.showOperationCost = true;
  }
  onSourceCountryChange(sourceCountry: any) {
    this.showOperationCost = false;
    this.sourceCountryChange.emit(sourceCountry);
    this.showOperationCost = true;
  }
  onTargetCountryChange(targetCountry: any) {
    this.showOperationCost = false;
    this.targetCountryChange.emit(targetCountry);
    this.showOperationCost = true;
  }
}
