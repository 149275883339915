import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { Subject } from 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';
import {RequestParameters} from '../shared/models/request-parameters/parameter.model';
import { FieldRequestParameters } from '../shared/models/request-parameters/parameter.model';
import {GlobalVariable} from '../../app/global';

@Injectable()
export class UserInscriptionService {
  private subject = new Subject<any>();
  constructor(private http: HttpClient) {
  }
  getCountries () {
    return this.http.get(GlobalVariable.BASE_API_URL + '/logged-out/countries')
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );
  }
  getCities (countryReference, parameters: RequestParameters = new RequestParameters()) {
    const fieldReqParams = new FieldRequestParameters('state.country', countryReference);
    parameters.fields = [];
    parameters.fields.push(fieldReqParams);
    return this.http.get(GlobalVariable.BASE_API_URL + '/logged-out/cities', {
      params: parameters.getHttpParams()
    })
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );
  }


}
