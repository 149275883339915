export const TARIFFS = Object.freeze({
  tariffs: [
    {
      'name': 'ESPACE UEMOA',
      'countries': ['BJ', 'BF', 'CI', 'GW', 'ML', 'NE', 'SN', 'TG'],
      'operationCosts': [
        {'lowerAmount': '0001 FCFA', 'topAmount': '5000 FCFA', 'cost': '150 FCFA'},
        {'lowerAmount': '5001 FCFA', 'topAmount': '20000 FCFA', 'cost': '450 FCFA'},
        {'lowerAmount': '20001 FCFA', 'topAmount': '50000 FCFA', 'cost': '900 FCFA'},
        {'lowerAmount': '50001 FCFA', 'topAmount': '100000 FCFA', 'cost': '1500 FCFA'},
        {'lowerAmount': '100001 FCFA', 'topAmount': '200000 FCFA', 'cost': '3000 FCFA'},
        {'lowerAmount': '200001 FCFA', 'topAmount': '300000 FCFA', 'cost': '4000 FCFA'},
        {'lowerAmount': '300001 FCFA', 'topAmount': '500000 FCFA', 'cost': '4500 FCFA'},
        {'lowerAmount': '500001 FCFA', 'topAmount': '800000 FCFA', 'cost': '5000 FCFA'},
        {'lowerAmount': '800001 FCFA', 'topAmount': '1000000 FCFA', 'cost': '8500 FCFA'},
        {'lowerAmount': '1000001 FCFA', 'topAmount': '1500000 FCFA', 'cost': '10000 FCFA'}
      ]
    },
    {
      'name': 'GUINEE',
      'countries': ['GN'],
      'operationCosts': [
        {'lowerAmount': '0001 GNF', 'topAmount': '50000 GNF', 'cost': '1500 GNF'},
        {'lowerAmount': '50001 GNF', 'topAmount': '100000 GNF', 'cost': '3500 GNF'},
        {'lowerAmount': '100001 GNF', 'topAmount': '300000 GNF', 'cost': '7000 GNF'},
        {'lowerAmount': '300001 GNF', 'topAmount': '500000 GNF', 'cost': '10000 GNF'},
        {'lowerAmount': '500001 GNF', 'topAmount': '1000000 GNF', 'cost': '17000 GNF'},
        {'lowerAmount': '1000001 GNF', 'topAmount': '2000000 GNF', 'cost': '30000 GNF'},
        {'lowerAmount': '2000001 GNF', 'topAmount': '4000000 GNF', 'cost': '40000 GNF'},
        {'lowerAmount': '4000001 GNF', 'topAmount': '6000000 GNF', 'cost': '50000 GNF'},
        {'lowerAmount': '6000001 GNF', 'topAmount': '8000000 GNF', 'cost': '60000 GNF'},
        {'lowerAmount': '8000001 GNF', 'topAmount': '10000000 GNF', 'cost': '75000 GNF'}
      ]
    }]
});
