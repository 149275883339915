<div *ngIf="!success" class="confirm-dialog">
  <form [formGroup]="form" (submit)="onSubmit()">
    <div>
      <p class="error-message">{{errorMessage}}</p>
      <mat-form-field>
        <input matInput type="text"
               placeholder="code de confirmation"
               [formControl]="form.controls['confirmationCode']" />
        <mat-error *ngIf="form.controls['confirmationCode'].hasError('required') && form.controls['confirmationCode'].touched">
          Ce champ est requis
        </mat-error>
      </mat-form-field>
    </div>
    <a mat-raised-button mat-ripple (click)="onCancel()" class="accent-color cancel-button">Annuler</a>
    <a mat-raised-button mat-ripple (click)="onSubmit()"
       [disabled]="!form.valid" [style.background-color]="platformConfig.platform.clientAppColor" class="validate-button">
      <mat-progress-spinner mode="indeterminate" class="loading-circle" [diameter]="25" [strokeWidth]="2" *ngIf="loading" >
      </mat-progress-spinner>
      Valider
    </a>
  </form>
</div>
<div  *ngIf="success" class="succes-confirm-dialog">
  <p class="succes-message">L'opération a bien été confirmée</p>
  <a mat-raised-button mat-ripple (click)="dialogRef.close(null)">OK</a>
</div>
