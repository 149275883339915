<div class="add-user-container">
  <!--<mat-toolbar class="add-user-header">
    <span>Les informations de l'identifiant</span>
  </mat-toolbar>-->
  <form action="">
    <div class="user-input-item">
      <mat-form-field>
        <input matInput placeholder="Numéro de l'identifiant">
      </mat-form-field>
    </div>
    <div class="user-input-item">
      <mat-form-field>
        <input matInput placeholder="Nom de l'identifiant">
      </mat-form-field>
    </div>
    <div class="user-input-item">
      <mat-form-field>
        <input matInput placeholder="Prénoms de l'indentifiant">
      </mat-form-field>
    </div>
    <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true" class="my-background-color">Valider</button>
  </form>
</div>
