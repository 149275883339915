import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material';
import {SidebarService} from './sidebar.service';
import {UserSubscription} from '../../shared/user/model/user-subscription.model';
import {GlobalVariable} from '../../global';
import {PlatformConfig} from '../../app-loader/platform-config';
import {ConfigLoaderService} from '../../app-loader/config-loader.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  @Input() profile: UserSubscription;

  public innerWidth: any;

  mediaBaseUrl = GlobalVariable.BASE_MEDIA_URL;

  TEST_MODE = GlobalVariable.TEST_MODE;

  platformConfig: PlatformConfig;

  appColorSideMenu = {active: ['border-color', 'color']};

  constructor(private sidebarService: SidebarService, public configLoaderService: ConfigLoaderService) {
    this.platformConfig = this.configLoaderService.getPlatformConfig();
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }
}
