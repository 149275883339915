import { Component, OnInit, Inject  } from '@angular/core';
import {FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import {PasswordValidator} from '../../validators/passwordValidator';
import { PinChangeService } from './pin-change.service';
import {OperationResultDialogComponent} from '../operation-result-dialog/operation-result-dialog.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';



@Component({
  selector: 'app-pin-change',
  templateUrl: './pin-change.component.html',
  styleUrls: ['./pin-change.component.css']
})
export class PinChangeComponent implements OnInit {
  form: FormGroup;
  hide = true;
  loading = false;
  errorDialogRef: MatDialogRef<OperationResultDialogComponent> | null;
  config = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '350px',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      message: '',
      correctPlatform: null
    }
  };
  numberRegEx = /\-?\d*\.?\d{1,2}/;
  constructor( 
    public dialogRef: MatDialogRef<PinChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,  
    private fb: FormBuilder, 
    public dialog: MatDialog,
    private pinChangeService: PinChangeService ) {
      this.form = this.fb.group ( {
        oldPassword: [null ,
          Validators.compose ( [Validators.required, Validators.minLength(6),Validators.maxLength(6)],),
          Validators.composeAsync([])
        ],
        plainPassword: this.fb.group({
          first: [null ,
            Validators.compose ( [Validators.required, Validators.minLength(6),Validators.maxLength(6), Validators.pattern(this.numberRegEx)]),
            Validators.composeAsync([])
          ],
          second: [null ,
            Validators.compose ( [Validators.required, Validators.pattern(this.numberRegEx)]),
            Validators.composeAsync([])
          ]
        })
      }, {
        validator: PasswordValidator.MatchPassword
      });
   }

  ngOnInit() {
    
  }


  onSubmit() {
    this.loading = true;
    this.pinChangeService.changePin(this.form.value).subscribe(
      (response: any) => {
        this.config.data.message = response.message;
        this.errorDialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
        this.errorDialogRef.afterClosed().subscribe((result) => {
          // this.form.reset();
          this.loading = false;
          this.dialogRef.close();
        });
      },
      (error) => {
        let message = error.error.message;
        let correctPlatform = null;
        if (message === 'INVALID PIN') {
          message = 'Pin invalide';
        } else if (message  === 'THIS OPERATION IS DONE ON A PLATFORM WHICH IS NOT YOUR\'S') {
          message = 'Vous tentez d\'effectuer une opération sur une plateforme qui n\'est pas la votre';
          if (error.error.platform) {
            correctPlatform = error.error.platform;
          }
        }  else if (message === 'ACCOUNT BLOCKED') {
          message = 'Votre compte a été bloqué';
        }  else if (message === 'validation failed' || message === 'Validation failed') {
          message = 'Nouveau mot de passe incorrect';
        }

        this.config.data.message = message;
        this.config.data.correctPlatform = correctPlatform;

        this.errorDialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
        this.errorDialogRef.afterClosed().subscribe((result) => {
          this.form.reset();
          this.loading = false;
        });
      }
    );
  }

}
