<div>
  <p>Detailed Infos on {{customer.phone}} </p>
  <mat-list>
    <mat-divider></mat-divider>
    <mat-list-item> <span class="title">NAME</span> :  <span class="content">{{customer.name}}</span> </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item><span class="title">ACCOUNT</span> : <span class="content"> {{customer.account}}</span> </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item> <span class="ti">COUNTRY</span> : <span class="content">{{customer.country}}</span> </mat-list-item>
    <mat-divider></mat-divider>
  </mat-list>

  <br/> <br/>

  <a mat-raised-button mat-ripple (click)="onClose()"
   color="primary" >Close</a>

</div>
