<div class="disconnect-container-content">
  <div class="app-connection-container">
    <div [ngClass]="{'app-connection-container-content': TEST_MODE, 'app-connection-container-content1': !TEST_MODE}">
      <div class="app-connection-all-content">
        <div class="connection-container-content">
          <!--<div class="connection-container-left my-background-color">

          </div>-->
          <div class="connection-container-right">
            <div class="connection-right-content">
              <mat-toolbar>
                <span [appAppColor]="{default : ['color']}" class="connection-header">Connexion</span>
              </mat-toolbar>
              <my-slide-panel [activePane]="isLeftVisible ? 'left' : 'right'">
                <div leftPane style="padding: 4%;">
                  <form (submit)="onSubmit()" >
                    <div *ngIf="!loading && formErrors.length != 0">
                      <div class="form-errors">
                        <h2 class="form-error" 
                        *ngFor="let error of formErrors" >{{ error }}</h2>
                        <p  *ngIf="correctPlatform" 
                        style="text-align: center; color: white;">
                          <span>{{'Votre platforme est : '}}</span> <span style="color: red;"><a target="_blank" href="{{'http://'+correctPlatform}}"><b>{{correctPlatform}}</b></a></span>
                        </p>
                        <p style="text-align: center; color: white;"
                         *ngIf="remainingLogins" >
                          {{"Il vous reste" }} <b>{{remainingLogins}}
                          {{ (remainingLogins > 1 ? "essais" : "essai")}}</b> {{ ("à faire")}}
                        </p>
                      </div>
                    </div>
                    <div class="connection-container-item">
                      <mat-form-field class="tel-input-form">
                        <input matInput placeholder="N° de téléphone" value=""
                              [formControl]="form.controls['login']"
                              ng2TelInput
                              (countryChange)="onCountryChange($event)"
                              (ng2TelOutput)="getPhone($event)"
                              [ng2TelInputOptions]="utils.selectorOptions"
                              (hasError)="phoneHasError($event)">
                      </mat-form-field>
                      <mat-error *ngIf="form.controls['login'].hasError('badPhone') && form.controls['login'].touched">
                        Numéro de téléphone invalide
                      </mat-error>
                      <mat-error *ngIf="form.controls['login'].hasError('required') && form.controls['login'].touched">
                        Ce champ est requis
                      </mat-error>
                    </div>
                    <div class="connection-container-item">
                      <mat-form-field>
                        <input (keydown)="blockTabAction($event)" matInput 
                        placeholder="Code PIN" [type]="hide ? 'password' : 'text'"
                              [formControl]="form.controls['password']" pattern="[0-9]*">
                        <mat-icon class="connection-icon-hide" matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                      </mat-form-field>
                      <div class="connection-container-item-bottom">
                        <mat-checkbox [formControl]="form.controls['rememberMe']" >Garder ma section active</mat-checkbox>
                      </div>
                    </div>
                    <div class="connection-container-item1">
                      <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true"
                              [appAppColor]="{default : ['background-color']}"
                              [disabled]="!form.valid"
                              (click)="onSubmit()" >
                        <mat-progress-spinner mode="indeterminate"
                         class="primary" [diameter]="25" [strokeWidth]="2" *ngIf="loading" >
                        </mat-progress-spinner>
                        Se Connecter
                      </button>
                    </div>
                  </form>
                </div>
                <div rightPane style="padding: 4%;">
                  <form [formGroup]="confirm_form" (ngSubmit)="onConfirmCodeSubmit()">
                    <div *ngIf="!loading && formErrors.length != 0">
                      <div class="form-errors">
                        <h2 class="form-error" *ngFor="let error of formErrors" >
                          {{ error }}
                        </h2> 
                      </div>
                    </div>
                    <p style="text-align: center;">
                      Entrez le code envoyé par SMS
                    </p>
                    <div fxLayout="column" fxLayoutAlign="space-around">
                      <div class="pb-1">
                        <mat-form-field style="width: 100%">
                          <input (keydown)="blockTabAction($event)" matInput placeholder="Code"
                           [formControl]="confirm_form.controls['validationCode']">
                          <mat-error *ngIf="confirm_form.controls['validationCode'].hasError('required') 
                          && confirm_form.controls['validationCode'].touched" 
                          class="mat-text-warn">Le code de confirmation est requis</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="connection-container-item1">
                        <button 
                        mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                         matRippleCentered="true"
                                [appAppColor]="{default : ['background-color']}"
                        type="submit" [disabled]="!confirm_form.valid || loading">
                          Confirmez
                          <mat-progress-spinner mode="indeterminate"
                          class="primary" [diameter]="25" [strokeWidth]="2"
                            *ngIf="loading" >
                          </mat-progress-spinner>
                        </button>
                      </div>
                      <span class="resend-code" style="text-align: center; margin-top: 15px">Renvoyer le code dans <label id="minutes">00</label>:<label id="seconds">00</label> </span>
                      <!-- <span *ngIf="resendCounter">Trop de tentative de renvoie veuillez réeassayer plus tard</span> -->
                      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <span class="resend-code" style="text-decoration: underline; cursor: pointer; margin-top: 1px;" *ngIf="mustResendCode" (click)="resendCode()"> Renvoyer le code</span>
                      </div>
                    </div>
                  </form>
                </div>
              </my-slide-panel>
             <!-- <div class="connection-container-pin">
                <h1><span>Code PIN oublié ?</span></h1>
                <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                        matRippleCentered="true" [appAppColor]="{default : ['background-color']}" (click)="openPinDialog()">
                  Récuperer mon PIN
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
