import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {PlatformConfig} from '../../../app-loader/platform-config';
import {ConfigLoaderService} from '../../../app-loader/config-loader.service';

@Component({
  selector: 'app-operation-result-dialog',
  templateUrl: './operation-result-dialog.component.html',
  styleUrls: ['./operation-result-dialog.component.css']
})
export class OperationResultDialogComponent implements OnInit {

  message: string;
  correctPlatform: string;
  platformConfig: PlatformConfig;

  constructor(public dialogRef: MatDialogRef<OperationResultDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public configLoaderService: ConfigLoaderService) {

    this.platformConfig = this.configLoaderService.getPlatformConfig();

    this.message = data.message;
    if (data.correctPlatform) {
      this.correctPlatform = data.correctPlatform;
    }

    switch (this.message) {
      case 'INSUFFICIENT BALANCE':
        this.message = 'Solde insuffisant';
        break;
      case 'RESSOURCE_NOT_FOUND':
        this.message = 'Le compte de l\'expediteur n\'existe pas';
        break;
      case 'SAME PHONE NUMBER':
        this.message = 'Impossible de faire un transfert vers le même compte';
        break;
      case 'PASSWORD CHANGED':
        this.message = 'Pin changé avec succès';
        break;
    }

  }

  ngOnInit() {
  }

}
