import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DataSource} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import {UserOperationsService} from '../../user-operations.service';
import {RequestParameters} from '../../../shared/models/request-parameters/parameter.model';
import {Subject} from 'rxjs';


/**
 * Data source to provide what data should be rendered in the table. The observable provided
 * in connect should emit exactly the data that should be rendered by the table. If the data is
 * altered, the observable should emit that new set of data on the stream. In our case here,
 * we return a stream that contains only one set of data that doesn't change.
 */
export class PastOperationsDataSource extends DataSource<any> {

  constructor(private subject: BehaviorSubject<any[]>, public paginator: MatPaginator) {
    super ();
  }

  connect (): Observable<any[]> {
    return this.subject.asObservable();
  }

  disconnect (): void {
  }
}

@Component({
  selector: 'app-my-ongoing-operations',
  templateUrl: './my-ongoing-operations.component.html',
  styleUrls: ['./my-ongoing-operations.component.css']
})
export class MyOngoingOperationsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() selectedOperation: Subject<any>;
  @Input() operationSelected: any;

  displayedColumns = ['operationDate', 'operationType', 'amount', 'description'];

  dataSource: PastOperationsDataSource | null;
  dataSubject = new BehaviorSubject<any[]>([]);

  parameters = new RequestParameters();
  activeOperation: any = {
    reference: null
  };
  constructor(private userOperationsService: UserOperationsService) {
    this.parameters.limit = 1000;
    this.updateOperations();
  }

  ngOnInit() {
    this.dataSource = new PastOperationsDataSource(this.dataSubject, this.paginator);
    this.selectedOperation.subscribe(
      (operation) => {
        if (operation === null) {
          this.updateOperations();
        }
      }
    );
  }

  onClickRow(operation) {
    this.activeOperation = operation;
    this.selectedOperation.next(operation);
  }

  updateOperations() {
    this.userOperationsService.getOngoingOperations(this.parameters).subscribe(
      (response: any) => {
        this.parameters.setData(response.parameters);
        this.dataSubject.next(response.result);
        if (this.operationSelected) {
          this.onClickRow(this.operationSelected);
        }
      }
    );
  }

}


