<div *ngIf="TEST_MODE" class="production-mode-content">
  <h1>Plateforme de test</h1>
</div>
<mat-toolbar [ngClass]="{'chronocash-header': TEST_MODE, 'chronocash-header1': !TEST_MODE}">
  <div class="header-logo">
    <button (click)="onToggleSidebar()" mat-icon-button *ngIf="authService.isAuthenticated() && isUserUrl()">
      <mat-icon role="img" aria-hidden="true">format_list_bulleted</mat-icon>
    </button>
    <div class="logo-container">
      <img [src]="platformConfig.platform.logo" alt="logo">
    </div>
  </div>

  <div class="header-menu">
    <div class="header-menu-content">

      <div class="menu-buttons">
        <mat-toolbar>
          <!-- This fills the remaining space of the current row -->
          <span class="fill-remaining-space"></span>
          <div fxLayout="row" fxShow="false" fxShow.gt-sm>
            <button class="header-link-active" mat-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                    matRippleCentered="true" routerLink="/home" routerLinkActive="active"
                    [appAppColor]="appColorMenu">
              Accueil
            </button>
            <button mat-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                    matRippleCentered="true" routerLink="/about" routerLinkActive="active"
                    [appAppColor]="appColorMenu">
              A propos
            </button>
            <button mat-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                    matRippleCentered="true" routerLink="/disconnect-operations" routerLinkActive="active"
                    *ngIf="!authService.isAuthenticated()"
                    [appAppColor]="appColorMenu">
              Opérations
            </button>
            <button mat-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                    matRippleCentered="true" routerLink="/user-connection" routerLinkActive="active"
                    *ngIf="!authService.isAuthenticated()"
                    [appAppColor]="appColorMenu">
              Connexion
            </button>
            <button mat-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                    matRippleCentered="true" routerLink="/user-inscription" routerLinkActive="active"
                    *ngIf="!authService.isAuthenticated()"
                    [appAppColor]="appColorMenu">
              Créer un compte
            </button>
            <button mat-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                    matRippleCentered="true" routerLink="/help" routerLinkActive="active"
                    [appAppColor]="appColorMenu">
              Procédures
            </button>
            <button mat-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                    matRippleCentered="true" routerLink="/legal-notice" routerLinkActive="active"
                    *ngIf="!authService.isAuthenticated()"
                    [appAppColor]="appColorMenu">
              Mention légale
            </button>
            <button mat-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                    matRippleCentered="true" routerLink="/contact" routerLinkActive="active"
                    *ngIf="!authService.isAuthenticated()"
                    [appAppColor]="appColorMenu">
              Contact
            </button>
            <button mat-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                    matRippleCentered="true" routerLink="/user" routerLinkActive="active"
                    *ngIf="authService.isAuthenticated()"
                    [appAppColor]="appColorMenu">
              Tableau de bord
            </button>
          </div>
          <button mat-icon-button mat-tooltip="Menu" class="home-menu-icon" [mat-menu-trigger-for]="menu"
                  fxHide="false" fxHide.gt-sm>
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-toolbar>
        <mat-menu x-position="before" #menu="matMenu" [overlapTrigger]="false">
          <button mat-menu-item routerLink="/home" routerLinkActive="active" [appAppColor]="appColorMenu">
            <mat-icon>home</mat-icon>Accueil
          </button>
          <button mat-menu-item routerLink="/about" routerLinkActive="active">
            <mat-icon>priority_high</mat-icon>A propos
          </button>
          <button mat-menu-item routerLink="/disconnect-operations" routerLinkActive="active" [appAppColor]="appColorMenu" *ngIf="!authService.isAuthenticated()">
            <mat-icon>view_list</mat-icon>Opérations
          </button>
          <button mat-menu-item routerLink="/user-connection" routerLinkActive="active" [appAppColor]="appColorMenu" *ngIf="!authService.isAuthenticated()">
            <mat-icon>fingerprint</mat-icon>Connexion
          </button>
          <button mat-menu-item routerLink="/user-inscription" routerLinkActive="active" [appAppColor]="appColorMenu" *ngIf="!authService.isAuthenticated()">
            <mat-icon>person_add</mat-icon>Créer un compte
          </button>
          <button mat-menu-item routerLink="/contact" routerLinkActive="active" [appAppColor]="appColorMenu" *ngIf="!authService.isAuthenticated()">
            <mat-icon>chat</mat-icon>Contact
          </button>
          <button mat-menu-item routerLink="/help">
            <mat-icon>help</mat-icon>Procédures
          </button>
          <button mat-menu-item routerLink="/user" routerLinkActive="active" [appAppColor]="appColorMenu" *ngIf="authService.isAuthenticated()">
            <mat-icon>dashboard</mat-icon>Tableau de bord
          </button>
          <!--- Device small than 960px ---->
          <div class="small-device" routerLinkActive="active" *ngIf="authService.isAuthenticated()">
            <button mat-menu-item routerLink="/user/my-account" routerLinkActive="active" [appAppColor]="appColorMenu">
              <mat-icon>account_box</mat-icon>
              <span>Mon Compte</span>
            </button>
            <button mat-menu-item (click)="onLogout()">
              <mat-icon>exit_to_app</mat-icon>
              <span>Déconnexion</span>
            </button>
          </div>
        </mat-menu>
      </div>

      <!--<div class="notification-buttons" *ngIf="authService.isAuthenticated()">
        <button [style.color]="platformConfig.platform.clientAppColor" mat-icon-button  [matMenuTriggerFor]="notifications" matTooltip="Notifications">
          <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">notifications</mat-icon>
          <span class="notification-label">5</span>
        </button>
        <mat-menu #notifications="matMenu" [overlapTrigger]="false" class="notification-container">
          <div class="notifications-content">
            <div class="notifications-title">
              <h1>Notifications</h1>
            </div>
            <div class="notifications-items-group">
              <a href="#">
                <div class="notification-item">
                  <div class="notification-item-content">
                    <div class="icon-content">
                      <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">chat</mat-icon>
                    </div>
                    <div class="text-content">
                      <p>
                        votre compte a été créditer par le numéro 00.00.00.00
                      </p>
                      <span>il y a 12 heures</span>
                    </div>
                  </div>
                </div>
              </a>
              <p class="any-notif">Vous n'avez pas de nouvelles notifications</p>
            </div>
          </div>
        </mat-menu>
      </div>-->


      <div class="header-right-buttons" *ngIf="authService.isAuthenticated()">
        <button mat-icon-button [matMenuTriggerFor]="user" matTooltip="Options">
          <mat-icon role="img" aria-hidden="true">more_vert</mat-icon>
        </button>
        <mat-menu #user="matMenu" [overlapTrigger]="false">
          <!--<button mat-menu-item routerLink="/user/dashboard">
            <mat-icon>dashboard</mat-icon>
            <span>Tableau de bord</span>
          </button>-->
          <button mat-menu-item routerLink="/user/my-account" [appAppColor]="appColorMenu">
            <mat-icon>account_box</mat-icon>
            <span>Mon Compte</span>
          </button>
          <!--<button mat-menu-item routerLink="/user/parameters">
            <mat-icon>settings</mat-icon>
            <span>Paramètres</span>
          </button>-->
          <button mat-menu-item (click)="onLogout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Déconnexion</span>
          </button>
        </mat-menu>
      </div>

    </div>
  </div>

</mat-toolbar>


