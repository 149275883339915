import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

import {SidebarService} from '../user/sidebar/sidebar.service';
import {AuthService} from '../shared/auth/auth.service';
import {GlobalVariable} from '../global';
import {ConfigLoaderService} from '../app-loader/config-loader.service';
import {PlatformConfig} from '../app-loader/platform-config';
import {AppColorDirectiveModel} from '../shared/directives/app-color/app-color.directive';
import {UserService} from '../shared/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  TEST_MODE = GlobalVariable.TEST_MODE;

  platformConfig: PlatformConfig;

  appColorMenu = {active: ['border-color', 'color']};

  constructor(private sidebarService: SidebarService,
              public authService: AuthService,
              private router: Router,
              private userService: UserService,
              public configLoaderService: ConfigLoaderService) {
    this.platformConfig = this.configLoaderService.getPlatformConfig();
  }

  ngOnInit() {
  }

  onToggleSidebar() {
    this.sidebarService.sidebarStatus.next();
  }

  onLogout() {
    // this.authService.signOutUser();
    // this.router.navigate(['/user-connection']);
    this.userService.currentUser.next(null);
    this.router.navigate(['/home']);
  }

  isUserUrl() {
    return this.router.url.includes('user/');
  }

}
