import {Component, Input, OnInit} from '@angular/core';
import {Operation} from '../../operation.model';
import {PrintUtils} from '../../../shared/utils/PrintUtils';
import {PlatformConfig} from '../../../app-loader/platform-config';
import {ConfigLoaderService} from '../../../app-loader/config-loader.service';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.css']
})
export class ReportDialogComponent implements OnInit {

  @Input() parameters;
  @Input() operations: Operation[];
  @Input() dates;

  platformConfig: PlatformConfig;

  constructor(public configLoaderService: ConfigLoaderService) {
    this.platformConfig = this.configLoaderService.getPlatformConfig();
  }

  ngOnInit() {
  }

  onPrint() {
    PrintUtils.printBlock('print-div');
  }

}
