import { Component, Input, OnInit } from '@angular/core';
import { Operation } from '../../../user/operation.model';
import { ProfileService } from '../../profile/profile.service';
import { UserSubscription } from '../../user/model/user-subscription.model';

@Component({
  selector: 'app-operation-amount-movement-indicator',
  templateUrl: './operation-amount-movement-indicator.component.html',
  styleUrls: ['./operation-amount-movement-indicator.component.css']
})
export class OperationAmountMovementIndicatorComponent implements OnInit {

  @Input() operation: Operation;

  profile: UserSubscription;

  constructor(private profileService: ProfileService) {

  }

  ngOnInit() {
    this.profile = this.profileService.getProfileData();
  }

  isAmountGoingIn() {

    if (this.operation.operationType === 'CREDIT') {
      if (this.operation.status === 'CANCEL_OPERATION') {
        return false;
      }
      return true;
    }

    if (this.operation.operationType === 'DEBIT') {
      if (this.operation.status === 'CANCEL_OPERATION') {
        return true;
      }
      return false;
    }

    if (this.operation.operationType === 'TRANSFERT') {

      if (this.operation.status === 'ONGOING') {
        if (this.operation.account.accountNumber === this.profile.phone) {
          return false;
        }
        if (this.operation.receiverAccount.accountNumber === this.profile.phone) {
          return true;
        }
      }

      if (this.operation.status === 'CONFIRMED') {
        if (this.operation.sendingOperation !== null && this.operation.sendingOperation !== undefined) {
          return true;
        } else {
          return false;
        }
      }

      if (this.operation.status === 'CANCELED') {
        return false;
      }

      if (this.operation.status === 'CANCEL_OPERATION') {
        return true;
      }

      // console.log('IMPOSSIBLE');

    }

  }

}
