<mat-form-field class="tel-input-form">
  <input matInput placeholder="{{ placeholder }}"
         [formControl]="collectAgencyFormControl"  autocomplete="off"
         [matAutocomplete]="autoCollectAgencies"
         (keyup)="filterAgencies(collectAgencyInput.value)"
         #collectAgencyInput>
  <mat-error
    *ngIf="collectAgencyFormControl.hasError('required') && 
    collectAgencyFormControl.touched"
    class="mat-text-warn">Ce champ est requis
  </mat-error>
  <mat-progress-spinner *ngIf="agencyLoading" mode="indeterminate"
   class="primary ng-pendig-spinner-spin" 
   [diameter]="25" [strokeWidth]="2"  ></mat-progress-spinner>
</mat-form-field>
<mat-autocomplete #autoCollectAgencies="matAutocomplete" [displayWith]="displayAgency"
 (optionSelected)="onSelectAgency()">
  <mat-option *ngFor="let agency of collectAgencies | async" [value]="agency">
    {{ agency.name }}
  </mat-option>
</mat-autocomplete>
