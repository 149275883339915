<div class="inner-disconnect-container-content">
  <div class="app-disconnect-pay-container">
    <div class="pay-container-mask">
      <div class="pay-container-mask-content">
        <p>Cette partie n'est pas encore disponible</p>
      </div>
    </div>
    <div class="payement-operation-title">
      <mat-toolbar class="page-title">
        <span>Payer</span>
      </mat-toolbar>
    </div>
    <form action="" class="disconnect-pay-form">
      <div class="payement-form-elements">
        <mat-tab-group>
          <mat-tab label="Payer Facture">
            <div class="client-payement-group">
              <div class="provider-reference">
                <mat-form-field>
                  <input matInput placeholder="Entrez la référence">
                </mat-form-field>
                <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true" class="my-background-color">valider</button>
              </div>
              <div class="provider-container">
                <div class="provider-container-item">
                  <ul>
                    <li>Fournisseur :</li>
                    <li>..............</li>
                  </ul>
                </div>
                <div class="provider-container-item">
                  <ul>
                    <li>Nom : </li>
                    <li>John</li>
                  </ul>
                </div>
                <div class="provider-container-item">
                  <ul>
                    <li>Prénoms :</li>
                    <li>Doe</li>
                  </ul>
                </div>
                <div class="provider-container-item">
                  <ul>
                    <li>Entreprise : </li>
                    <li>.....</li>
                  </ul>
                </div>
                <div class="provider-container-item">
                  <ul>
                    <li>Adresse de l'entreprise : </li>
                    <li>......</li>
                  </ul>
                </div>
                <div class="provider-container-item">
                  <ul>
                    <li>RCCM : </li>
                    <li>....</li>
                  </ul>
                </div>
              </div>
              <div class="client-container">
                <div class="client-container-item">
                  <ul>
                    <li>Client : </li>
                    <li>............</li>
                  </ul>
                </div>
                <div class="client-container-item">
                  <ul>
                    <li>Adresse Client : </li>
                    <li>............</li>
                  </ul>
                </div>
                <div class="client-container-item">
                  <ul>
                    <li>RCCM : </li>
                    <li>............</li>
                  </ul>
                </div>
              </div>
              <div class="total-price">
                <ul>
                  <li class="my-color">Total : 00.00 FCFA</li>
                  <li><button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true" class="my-background-color">Payer</button></li>
                </ul>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Payer Service">
            <div class="service-pay-container">
              <div class="service-container-choice">
                <mat-button-toggle-group #group="matButtonToggleGroup">
                  <mat-button-toggle value="left">
                    CEET
                  </mat-button-toggle>
                  <mat-button-toggle value="center">
                    TDE
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="service-pay-container-item">
                <mat-form-field>
                  <input matInput placeholder="Numéro de la facture">
                </mat-form-field>
              </div>
              <div class="service-pay-container-item">
                <mat-form-field>
                  <input matInput placeholder="Montant à payer" type="number" value="" class="example-right-align">
                  <span matPrefix>$&nbsp;</span>
                  <span matSuffix>.00</span>
                </mat-form-field>
              </div>
              <div class="service-pay-container-item1">
                <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true" class="my-background-color">Valider</button>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </form>
  </div>
</div>
