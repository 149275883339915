<mat-drawer-container class="sidenav-container" autosize>

  <mat-drawer class="sidenav-left-content" [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="sidebarOpened">

    <app-sidebar [profile]="profile">

    </app-sidebar>
  </mat-drawer>

  <div [ngClass]="{'sidenav-container-content': TEST_MODE, 
  'sidenav-container-content1': !TEST_MODE}">
    <router-outlet>

    </router-outlet>
    <!--<app-footer></app-footer>-->
  </div>

</mat-drawer-container>
