import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { OperationResultDialogComponent } from '../../shared/components/operation-result-dialog/operation-result-dialog.component';
import { OperationAmountValidators } from '../../shared/validators/operation-amount.validators';
import { PasswordValidator } from '../../shared/validators/passwordValidator';
import { ConfirmOperationComponent } from '../operations/confirm-operation/confirm-operation.component';
import { UserOperationsService } from '../user-operations.service';

@Component({
  selector: 'app-debit-atm',
  templateUrl: './debit-atm.component.html',
  styleUrls: ['./debit-atm.component.css']
})
export class DebitAtmComponent implements OnInit {
  form: FormGroup;
  minimumOperationAmount = 10;
  numberRegEx = /\-?\d*\.?\d{1,2}/;
  loading = false;
  config = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '200px !important',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      message: ''
    }
  };
  dialogRef: MatDialogRef<OperationResultDialogComponent> | null;
  constructor(public fb: FormBuilder, private dialog: MatDialog, private userOperatorService: UserOperationsService) { }

  ngOnInit() {
    this.form = this.fb.group({
      amount: [null,
        Validators.compose([Validators.required, OperationAmountValidators.amountCheck(this.minimumOperationAmount)]),
        Validators.composeAsync([])
      ],
      reason: [null,
        Validators.compose([]),
        Validators.composeAsync([])
      ],
      code: [null,
        Validators.compose([Validators.required, Validators.minLength(4), Validators.pattern(this.numberRegEx)]),
        Validators.composeAsync([])
      ],
      confirm: [null,
        Validators.compose([Validators.required, Validators.pattern(this.numberRegEx)]),
        Validators.composeAsync([])
      ]
    },{
      validator: PasswordValidator.ATMCodeMatch
    });
  }
  onSubmit(){
    this.loading = true;
    let data = this.form.value;
    delete data.confirm;
     this.userOperatorService.createDebitATM(data).subscribe(res => {
      this.config.data.message = 'Opération effectuée';
      this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
      this.dialogRef.afterClosed().subscribe((result) => {
        this.loading = false;
        this.form.reset();
      });
     }, err => {
      this.config.data.message = 'Opération échouée';
      this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
      this.dialogRef.afterClosed().subscribe((result) => {
        this.loading = false;
        this.form.reset();
      });
     })
  }
  onReasonSelected(e){
    this.form.controls['reason'].setValue(e);
  }
  
  isAmountValid(e){
    let amount = e.target.value;
    if(amount){
      amount = parseInt(amount);
      if(amount%1000!==0){
        this.form.controls['amount'].setErrors({notValid:true});
      }
    }
  }
}
