import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {throwError} from 'rxjs/internal/observable/throwError';

import {GlobalVariable} from '../../global';

@Injectable()
export class MediaService {

  constructor(private http: HttpClient) {
  }

  getMedia (reference: string) {

    return this.http.get(GlobalVariable.BASE_API_URL + '/logged/media/' + reference, {
    })
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );

  }

  postMedia (file: File) {

    const body = new FormData();
    body.append('file', file);

    return this.http.post(GlobalVariable.BASE_API_URL + '/logged/customer/medias', body)
      .map(
        (response: HttpResponse<any>) => {
          return response ;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status
          });
        }
      );
  }

}
