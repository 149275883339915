import { Component, OnInit } from '@angular/core';
import {GlobalVariable} from '../global';
import { ConfigLoaderService } from '../app-loader/config-loader.service';
import { PlatformConfig} from '../app-loader/platform-config';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  TEST_MODE = GlobalVariable.TEST_MODE;

  platformConfig: PlatformConfig;

  constructor(public configLoaderService: ConfigLoaderService) {
    this.platformConfig = this.configLoaderService.getPlatformConfig();
  }

  ngOnInit() {
    if (this.platformConfig.platform.homeHeadBackgroundPicture) {
      let homeBaner: any = document.getElementById('app-red-back');
      homeBaner.style.backgroundImage = "url("+this.platformConfig.platform.homeHeadBackgroundPicture+")";
      homeBaner.style.backgroundPosition = "center center";
      homeBaner.style.backgroundRepeat = "no-repeat";
      homeBaner.style.backgroundAttachment = "fixed";
      homeBaner.style.backgroundSize = "cover";
    }
  }

}
