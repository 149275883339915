import { Component, OnInit } from '@angular/core';
import {UserSubscription} from '../../shared/user/model/user-subscription.model';
import {ProfileService} from '../../shared/profile/profile.service';
import { UserService } from '../../shared/user/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

  profile: UserSubscription;
  showInformation: boolean = true;

  constructor(private profileService: ProfileService, 
    private userService: UserService ) {

    this.profile = profileService.getProfileData();

    // this.profileService.getProfile().subscribe(
    //   (profile: UserSubscription) => {
    //     this.profile = profile;
    //   }
    // );

    // this.profileService.profile.subscribe(
    //   (profile: UserSubscription) => {
    //     this.profile = profile;
    //   }
    // );

    this.showInformation = this.userService.showLastConnectionInfo;

  }

  ngOnInit() {
   // console.log(this.profile);
  }

  showInfo() {
    this.showInformation = false;
    this.userService.toggleShowLastConneciontInfo();
  }

}
