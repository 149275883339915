<div class="privacy-policy-container">
  <div class="home-description"
                 [innerHtml]="platformConfig.platform.privacyPolicy"></div>
  <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                        *ngIf ="data.for === 'inscription'"
                        matRippleCentered="true"
                        style="float: right; color: white;"
                        [appAppColor]="{default : ['background-color']}"
                        (click)="close()">
                    Ok
  </button>
  <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                        *ngIf ="data.for === 'first-connexion'"
                        matRippleCentered="true"
                        style="float: right; color: white;"
                        [appAppColor]="{default : ['background-color']}"
                        (click)="close()">
         J'ai lu et j'accepte la politique de confidentialité
  </button>
</div>