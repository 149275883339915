import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfigLoaderService } from '../../../app-loader/config-loader.service';
import { PlatformConfig } from '../../../app-loader/platform-config';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  platformConfig: PlatformConfig ; 

  constructor(public dialogRef: MatDialogRef<TermsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,  
    public configLoaderService: ConfigLoaderService ) {
      this.platformConfig = this.configLoaderService.getPlatformConfig();
     }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
