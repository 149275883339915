<div class="app-parameters-content">
  <div class="parameters-container">
    <mat-toolbar class="page-title">
      <span><mat-icon class="page-title-icon" role="img" aria-hidden="true">settings</mat-icon> Paramètres</span>
    </mat-toolbar>
    <div class="parameters-container-content">
      <div class="parameters-container-item">
        <div class="parameters-container-text">
          <h3 class="my-color">Notifications par sms</h3>
          <p>Recevoir les notifications pas sms pour les actions concernant votre compte</p>
        </div>
        <div class="parameters-slide">
          <mat-slide-toggle class="parameters-slider" [checked]="true">
          </mat-slide-toggle>
        </div>
      </div>
      <div class="parameters-container-item">
        <div class="parameters-container-text">
          <h3 class="my-color">Notifications par email</h3>
          <p>Recevoir les notifications par email pour les actions concernant votre compte</p>
        </div>
        <div class="parameters-slide">
          <mat-slide-toggle class="parameters-slider">
          </mat-slide-toggle>
        </div>
      </div>
      <div class="parameters-container-item">
        <div class="parameters-container-text">
          <h3 class="my-color">Langue</h3>
          <p>changer de langue selon votre préférence</p>
        </div>
        <div class="parameters-slide">
          <mat-form-field>
            <mat-select placeholder="Langue" floatPlaceholder="never" [(ngModel)]="selectedValue">
              <mat-option *ngFor="let language of languages" [value]="language.value" >
                {{ language.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!--<div class="parameters-container-item">
        <div class="parameters-container-text">
          <h3 class="my-color">A propos de Bestcash</h3>
          <p class="propos-text">
            Bestcash est une plateforme de transfert d'argent en ligne qui vous permet d'envoyer de l'argent à vos proches n'importe où et quand vous voulez. l'envoi se fait a l'echelle nationale et internationale...
          </p>
          <p class="politik-link">Pour plus d'informations veuillez lire nos <a href="#" class="my-color">Termes d'utilisations</a> et notre <a href="#" class="my-color">politique de confidentialité</a></p>
        </div>
      </div>-->
    </div>
  </div>
</div>
