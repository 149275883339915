<mat-toolbar>Récupération du code PIN</mat-toolbar>
<div *ngIf="!loading && formErrors.length != 0">
  <div class="form-errors" >
    <div class="form-error" *ngFor="let error of formErrors" >{{ error }}</div>
  </div>
</div>

<div class="pin-reset-response" *ngIf="success">
  <p>Votre code pin a été réinitialisé et envoyé par SMS</p>
  <p>vérifiez vos SMS</p>
  <button mat-raised-button
          (click)="onNoClick()" [appAppColor]="{default : ['background-color']}">
    OK
  </button>
</div>

<div class="pin-forgot-content" *ngIf="!success">
  <p>Veuillez entrer votre numéro de téléphone pour récupérer votre code PIN</p>
  <form [formGroup]="form">
      <mat-form-field class="tel-input-form">
        <input matInput placeholder="N° de téléphone" value=""
               [formControl]="form.controls['phone']"
               ng2TelInput
               (ng2TelOutput)="getPhone($event)"
               [ng2TelInputOptions]="utils.selectorOptions"
               (hasError)="phoneHasError($event)">
        <mat-error *ngIf="form.controls['phone'].hasError('badPhone') && form.controls['phone'].touched">
          Numéro de téléphone invalide
        </mat-error>
        <mat-error *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched">
          Ce champ est requis
        </mat-error>
      </mat-form-field>
      <!--<mat-form-field style="width: 100%">
        <mat-select  placeholder="Reçevoir le code pin par" [formControl]="form.controls['pinGettingMethod']">
          <mat-option *ngFor="let getMethod of pinGettingMethods" [value]="getMethod">
            {{getMethod}}
          </mat-option>
        </mat-select>
      </mat-form-field>-->
      <button class="recup-button" mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
              matRippleCentered="true" [appAppColor]="{default : ['background-color']}"
              (click)="onSubmit()">
        <mat-progress-spinner mode="indeterminate" class="primary loading-circle" [diameter]="25" [strokeWidth]="2" *ngIf="loading" >
        </mat-progress-spinner>
        Envoyer
      </button>
      <button mat-raised-button class="accent-color"
              (click)="onNoClick()">
        Annuler
      </button>
  </form>
</div>
