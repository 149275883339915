import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-business-infos',
  templateUrl: './business-infos.component.html',
  styleUrls: ['./business-infos.component.css']
})
export class BusinessInfosComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BusinessInfosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
