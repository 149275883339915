import { Component, OnInit } from '@angular/core';
import {PlatformConfig} from '../../app-loader/platform-config';
import {ConfigLoaderService} from '../../app-loader/config-loader.service';

@Component({
  selector: 'app-disconnect-pay',
  templateUrl: './disconnect-pay.component.html',
  styleUrls: ['./disconnect-pay.component.css']
})
export class DisconnectPayComponent implements OnInit {

  platformConfig: PlatformConfig;

  constructor(public configLoaderService: ConfigLoaderService) {
    this.platformConfig = this.configLoaderService.getPlatformConfig();
  }

  ngOnInit() {
  }

}
