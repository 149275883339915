<div class="slide-content">
  <ngx-carousel
    [inputs]="carouselOne"
    (carouselLoad)="myfunc($event)">
    <ngx-item *ngFor="let slide of slides" NgxCarouselItem>
      <img [src]="slide" alt="">
    </ngx-item>
    <!--<ngx-item NgxCarouselItem>
      <img src="assets/images/AFFICHE%203.jpg" alt="">
    </ngx-item>-->
    <button NgxCarouselPrev class='leftRs'>&lt;</button>
    <button NgxCarouselNext class='rightRs'>&gt;</button>
  </ngx-carousel>
</div>
