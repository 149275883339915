import { Directive, HostListener, ElementRef, OnInit } from "@angular/core";
import { CurrencyFormatPipePipe } from '../../pipes/CurrencyFormatPipe/currency-format-pipe.pipe';

@Directive({
  selector: '[appCurrencyFormatDirective]'
})
export class CurrencyFormatDirectiveDirective {

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: CurrencyFormatPipePipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.currencyPipe.transform(this.el.value);
  }

  // @HostListener("keyup", ["$event.target.value"])
  // onKeyup(value) {
  //   this.el.value = this.currencyPipe.parse(value); // opossite of transform
  // }

  

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    this.el.value = this.currencyPipe.parse(value); // opossite of transform
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    this.el.value = this.currencyPipe.transform(value);
  }

}
