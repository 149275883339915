import { Component, OnInit } from '@angular/core';
import {GlobalVariable} from '../global';
import { ConfigLoaderService } from '../app-loader/config-loader.service';
import { PlatformConfig} from '../app-loader/platform-config';

@Component({
  selector: 'app-disconnect-operations',
  templateUrl: './disconnect-operations.component.html',
  styleUrls: ['./disconnect-operations.component.css']
})
export class DisconnectOperationsComponent implements OnInit {

  TEST_MODE = GlobalVariable.TEST_MODE;
  platformConfig: PlatformConfig;

  appColorDisconnected = {active: ['background-color']};

  constructor(public configLoaderService: ConfigLoaderService) {
    this.platformConfig = this.configLoaderService.getPlatformConfig();
  }

  ngOnInit() {
  }

}
