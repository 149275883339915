import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {PlatformConfig} from '../../../app-loader/platform-config';
import {ConfigLoaderService} from '../../../app-loader/config-loader.service';

@Component({
  selector: 'app-dialog-contact-box',
  templateUrl: './dialog-contact-box.component.html',
  styleUrls: ['./dialog-contact-box.component.css']
})
export class DialogContactBoxComponent implements OnInit {

  platformConfig: PlatformConfig;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogContactBoxComponent>,
              public configLoaderService: ConfigLoaderService) {
    this.platformConfig = this.configLoaderService.getPlatformConfig();
  }


  ngOnInit() {
  }
  dismiss() {
    this.dialogRef.close();
  }
}
