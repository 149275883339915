import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';

import {GlobalVariable} from '../../global';
import {RequestParameters} from '../models/request-parameters/parameter.model';
import {UserSubscription} from '../user/model/user-subscription.model';
import {Subject} from 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';

@Injectable()
export class ProfileService {

  private profileData: UserSubscription;

  public profile: Subject<UserSubscription> = new Subject<UserSubscription>();

  showLastConnectionInfo: boolean = true;

  constructor(private http: HttpClient) {
    this.profile.subscribe(
      (user) => {
        this.profileData = Object.assign({}, user);
      }
    );
  }
  toggleShowLastConneciontInfo() {
    this.showLastConnectionInfo = false;
  }

  getProfileData() {
    return Object.assign({}, this.profileData);
  }

  getProfile() {
    return this.http.get(GlobalVariable.BASE_API_URL + '/logged/customer/profile')
      .map(
        (response: UserSubscription) => {
          this.profile.next(response);
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: error.error.message
          });
        }
      );
  }

  patchProfile(user: UserSubscription) {

    const body = this.normalizeProfileData(user);

    return this.http.patch(GlobalVariable.BASE_API_URL + '/logged/customer/profile', body)
      .map(
        (response: UserSubscription) => {
          this.profile.next(response);
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );
  }

  getCities(parameters: RequestParameters = new RequestParameters()) {
    return this.http.get(GlobalVariable.BASE_API_URL + '/logged/list-cities', {
      params: parameters.getHttpParams(),
    })
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );
  }

  private normalizeProfileData(user: UserSubscription): UserSubscription {
    const body = Object.assign({}, user);
    delete body.reference;
    delete body.country;

    if (body.picture !== null) {
      body.picture = body.picture.reference;
    }
    if (body.city !== null) {
      body.city = body.city.reference;
    }

    return body;
  }

}
