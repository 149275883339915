import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {GlobalVariable} from '../global';

@Injectable()
export class UserInscriptionValidators {

  constructor(private http: HttpClient) {}
  uniquePhoneValidator(control: FormControl): Promise<any> | Observable<any> {
    return this.http.get(GlobalVariable.BASE_API_URL + '/logged-out/unique/customer/phone/' + control.value)
      .map(
        (response: any) => {
          if (response) {
            if (response.reference) {
              if (control.parent.value.reference) {
                const elementReference = control.parent.value.reference;
                if (response.reference === elementReference) {
                  return false;
                }
              }
              return { phoneIsUnique: true };
            } else {
              return false;
            }
          } else {
            return;
          }
        }
      );
  }
  uniqueEmailValidator(control: FormControl): Promise<any> | Observable<any> {
    return this.http.get(GlobalVariable.BASE_API_URL + '/logged-out/unique/customer/email/' + control.value)
      .map(
        (response: any) => {
          if (response) {
            if (response.reference) {
              if (control.parent.value.reference) {
                const elementReference = control.parent.value.reference;
                if (response.reference === elementReference) {
                  return false;
                }
              }
              return { emailIsUnique: true };
            } else {
              return false;
            }
          } else {
            return;
          }
        }
      );
  }
}
