<mat-form-field style="width: 100%">
  <mat-select placeholder="Raison" [formControl]="operationReasonFormControl" (selectionChange)="onReasonSelection($event)" >
    <mat-option *ngFor="let reason of reasons" [value]="reason.name" [matTooltip]="reason.description">
      {{reason.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="transfert-input-item">
  <mat-form-field class="example-full-width" *ngIf="operationReasonFormControl.value === 'Autres'">
  <textarea matInput maxlength="250" (blur)="onOtherReasonBlur($event)"
            placeholder="Entrer une autre autre raison"></textarea>
  </mat-form-field>
</div>
