<div class="slim-content">
  <div class="app-dashboard-content">
    <div class="solde">
      <div class="solde-content">
        <ul>
          <li>Votre Solde</li> <!-- profile.account.prefixedBalance -->
          <li [appAppColor]="{default : ['color']}">{{ ((profile.account.balance | floorAmount) | currency: 'XOF':'') + ' ' + profile.account.monetaryArea.currencyShortName  }}</li>
        </ul>
        <div *ngIf="!profile.idNumber || !profile.cardExpiration || !profile.idType" style="background-color: red; color: white; padding-left: 1%;">
          <p><b style="font-size: 1.5em;">Compte non validé</b>, veuillez passer dans l'agence la plus proche pour faire valider votre compte.</p>
        </div>
      </div>
    </div>
    <div class="ongoing-operations">
      <div class="ongoing-block">
        <mat-toolbar class="operations-header" [appAppColor]="{default : ['color']}">Operations en cours</mat-toolbar>
        <div class="ongoing-operations-content">
          <app-ongoing-operations>
          </app-ongoing-operations>
        </div>
      </div>
    </div>
    <div class="past-operations">
      <mat-toolbar class="operations-header" [appAppColor]="{default : ['color']}">Operations Effectuees</mat-toolbar>
      <div class="past-operations-content">
        <app-past-operations>
        </app-past-operations>
      </div>
    </div>
    <!-- <div (click)="showInfo()" *ngIf="!showInformation" class="showInformation">
      <mat-icon aria-hidden="true">dashboard</mat-icon>
    </div> -->

    <div *ngIf="profile && showInformation" class="last-connexion-info">
      <mat-icon style="cursor: pointer;" (click)="showInfo()" class="close-icon" aria-hidden="true">close</mat-icon>
      <p *ngIf="profile.lastConnexion" style="text-align: center;" ><b>Dernière connexion</b></p>
      <ul *ngIf="profile.lastConnexion" class="" style="list-style-type: none;">
        <li *ngIf="profile.lastConnexion">Date et heure : {{ profile.lastConnexion | date:'dd-MM-yyyy HH:mm':'':'fr' }}</li>
        <li *ngIf="profile.lastConnexionCountry">Pays : {{profile.lastConnexionCountry}} </li>
        <li *ngIf="profile.lastConnexionIp" >Ip : {{profile.lastConnexionIp}}</li>
      </ul>
    </div>

  </div>
</div>


