<div class="disconnect-container-content">
  <div class="app-home-container">
    <div [ngClass]="{'app-home-container-content': TEST_MODE, 'app-home-container-content1': !TEST_MODE}">
      <div class="app-home-all-content">
        <div id="app-red-back" class="app-red-back" [appAppColor]="{default : ['background-color']}">
        </div>
        <div class="app-home-store">
          <div class="app-home-store-left">

            <div class="slide-container">
              <app-slide-content>

              </app-slide-content>
            </div>

            <div class="app-services-content">
              <div class="chronocash-descritpion">
                <h1 [appAppColor]="{default : ['color']}">{{ platformConfig.platform.name }}</h1>
                <div class="home-description"
                 [innerHtml]="platformConfig.platform.clientHomeDescription"></div>
              </div>
              <div class="chronocash-services">
                <h2 [appAppColor]="{default : ['color']}">Nos Services</h2>
                <div class="service-item first-item">
                  <div class="services-icon-content">
                    <img src="../../assets/images/transfer.png" alt="image-transfert">
                  </div>
                  <div class="services-text-group">
                    <ul>
                      <li>Transferer de l'argent</li>
                      <li>Transferer de l'argent au national et a l'international</li>
                    </ul>
                  </div>
                </div>
                <div class="service-item">
                  <div class="services-icon-content">
                    <img src="../../assets/images/invoice.png" alt="image-facture">
                  </div>
                  <div class="services-text-group">
                    <ul>
                      <li>Payer les factures</li>
                      <li>Payer vos factures et services dans toutes nos agences ou en ligne</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="app-home-store-right">
            <img [src]="platformConfig.platform.clientHomeMobilePicture" alt="mobile-image">
          </div>
        </div>
        <div class="download-application">
          <h3 [appAppColor]="{default : ['color']}">Télécharger notre application mobile</h3>
          <div class="download-application-content">
            <a [href]="platformConfig.platform.clientHomePlayStoreLink" target="_blank">
              <div [appAppColor]="{default : ['background-color']}" class="download-button android-button">
                <ul>
                  <li class="android">
                    <img src="assets/images/playstore.png" alt="android-image">
                  </li>
                  <li>
                    Télécharger sur<br> <span>Google Play</span>
                  </li>
                </ul>
              </div>
            </a>
            <a [href]="platformConfig.platform.clientHomeAppStoreLink" target="_blank">
              <div [appAppColor]="{default : ['background-color']}" class="download-button apple-button">
                <ul>
                  <li class="apple">
                    <img src="assets/images/apple-white.png" alt="apple-image">
                  </li>
                  <li>
                    Télécharger sur<br> <span>App Store</span>
                  </li>
                </ul>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
