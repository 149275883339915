import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';

import {RequestParameters} from '../request-parameters/parameter.model';
import {GlobalVariable} from '../../../global';
import {UserCollect} from './user-collect.model';
import {Observable} from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { UserCollectAgency } from './user-collect-agency.model';

import { UserCollectProduct } from './user-collector-product.model';
import { OperationTransfertCollect } from './collect-operation.model';

@Injectable()
export class UsersCollectService {

  private subject = new Subject<any>();
  constructor(private http: HttpClient) {
  }

  postTransfertCollect(transfert: OperationTransfertCollect) {

    const body = this.normalizeOperationDepotCollectData(transfert);

    return this.http.post(GlobalVariable.BASE_API_URL + '/logged/customer/operations/transfert-collect',
      body
    ).map(
      (response: HttpResponse<any>) => {
        return response;
      }
    );
  }

  getUsersCollector (parameters: RequestParameters = new RequestParameters()) {
    return this.http.get(GlobalVariable.BASE_API_URL + '/users', {
      params: parameters.getHttpParams(),
    })
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return Observable.throw({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );

  }

  getUserCollect (reference: string) {

    return this.http.get(GlobalVariable.BASE_API_URL + '/user-collect/' + reference, {
    }).map(
        (response: HttpResponse<any>) => {
          return response;
        }
      );

  }

  getUserCollectAgencies(userCollect: UserCollect, parameters: RequestParameters = new RequestParameters()) {
    return this.http.get(GlobalVariable.BASE_API_URL + '/collect-agencies/'+ userCollect.reference,{
      params: parameters.getHttpParams(),
    })
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return Observable.throw({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );
  }

  getUserCollectProducts( userCollect: UserCollect, parameters: RequestParameters = new RequestParameters() ) {
    return this.http.get(GlobalVariable.BASE_API_URL +
       '/collect-products/'+ userCollect.reference, {
        params: parameters.getHttpParams(),
      })
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return Observable.throw({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );

  }

  postUserCollect (UserCollect: UserCollect) {

    const body = this.normalizeUserCollectData(UserCollect);

    return this.http.post(GlobalVariable.BASE_API_URL + '/users', body)
      .map(
        (response: HttpResponse<any>) => {
          return response ;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return Observable.throw({
            code: error.status
          });
        }
      );
  }

  postUserCollectAgency (UserCollectAgency: UserCollectAgency ) {

    const body = this.normalizeUserCollectAgencyData(UserCollectAgency);

    return this.http.post(GlobalVariable.BASE_API_URL + '/agencies', body)
      .map(
        (response: HttpResponse<any>) => {
          return response ;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return Observable.throw({
            code: error.status
          });
        }
      );
  }

  postUserCollectProduct(UserCollectProduct: UserCollectProduct ) {

    const body = this.normalizeUserCollectProductData(UserCollectProduct);

    return this.http.post(GlobalVariable.BASE_API_URL + '/products', body)
      .map(
        (response: HttpResponse<any>) => {
          return response ;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return Observable.throw({
            code: error.status
          });
        }
      );
  }

  putUserCollect (UserCollect: UserCollect) {

    const body = this.normalizeUserCollectData(UserCollect);

    return this.http.put(GlobalVariable.BASE_API_URL + '/operator/clk/' + UserCollect.reference, body)
      .map(
        (response: HttpResponse<any>) => {
          return response ;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return Observable.throw({
            code: error.status
          });
        }
      );

  }

  patchUserCollect (UserCollect: UserCollect) {

    const body = this.normalizeUserCollectData(UserCollect);

    return this.http.patch(GlobalVariable.BASE_API_URL + '/operator/clk/' + UserCollect.reference, body)
      .map(
        (response: HttpResponse<any>) => {
          return response ;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return Observable.throw({
            code: error.status
          });
        }
      );

  }

  deleteUserCollect (UserCollect: UserCollect) {
    return this.http.delete(GlobalVariable.BASE_API_URL + '/operator/clk/' + UserCollect.reference)
      .map(
        (response: HttpResponse<any>) => {
          return response ;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return Observable.throw({
            code: error.status
          });
        }
      );
  }
  
  disableUserCollect(UserCollect: UserCollect, stateToApply) {
    return this.http.get(GlobalVariable.BASE_API_URL + '/operator/' + UserCollect.reference + '/' + stateToApply)
      .map(
        (response: HttpResponse<any>) => {
          return response ;
        }
      ).catch((error: HttpErrorResponse) => {
          return Observable.throw({
            code: error.status
          });
        }
      );
  }

  private normalizeUserCollectProductData(userPartner: UserCollectProduct ): UserCollectProduct {
    const body = Object.assign({}, userPartner);
    delete body.reference;
    return body;
  }

  private normalizeUserCollectAgencyData(userPartner: UserCollectAgency ): UserCollectAgency {
    const body = Object.assign({}, userPartner);
    delete body.reference;
    return body;
  }

  private normalizeUserCollectData(userPartner: UserCollect): UserCollect {

    const body = Object.assign({}, userPartner);
    delete body.reference;

    if (body.picture !== null) {
      body.picture = body.picture.reference;
    }
    // if (body.country !== null) {
    //   body.country = body.country.reference;
    // }
    // if (body.city !== null) {
    //   body.city = body.city.reference;
    // }
    // if (body.callCenter !== null) {
    //   body.callCenter = body.callCenter.reference;
    // }
    // if (body.role !== null) {
    //   body.role = body.role.reference;
    // }
    // if (body.platform !== null) {
    //   body.platform = body.platform.reference;
    // }
    return body;
  }

  emitMessage(msg: object) {
    this.subject.next(msg);
  }
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  private normalizeOperationDepotCollectData(operation: OperationTransfertCollect): OperationTransfertCollect {
    const body: OperationTransfertCollect = Object.assign({}, operation);

    if (body.collectAgency !== null) {
      body.collectAgency = body.collectAgency.reference;
    }

    if (body.productDetails !== null) {
      if (body.productDetails.length) {
        body.productDetails = body.productDetails.map(
          (prd) => {
            return {
              product: prd.product.reference,
              amount: prd.amount
            }
          }
        )
      }
    }

    return body;
  }

}
