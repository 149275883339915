import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { HttpClient } from '@angular/common/http';

import { DisconnectOperationsService } from '../disconnect-operations.service';
import { OperationResultDialogComponent } from '../../shared/components/operation-result-dialog/operation-result-dialog.component';
import { Utils } from '../../shared/utils/Utilis';

@Component({
  selector: 'app-disconnect-balance',
  templateUrl: './disconnect-balance.component.html',
  styleUrls: ['./disconnect-balance.component.css']
})
export class DisconnectBalanceComponent implements OnInit {

  form: FormGroup;
  loading: boolean = false;

  dialogRef: MatDialogRef<OperationResultDialogComponent> | null;
  config = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '350px',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      message: '',
      correctPlatform: null
    }
  };
  telCountry: any;
  numberRegEx = /\-?\d*\.?\d{1,2}/;
  constructor(private fb: FormBuilder,
    public dialog: MatDialog,
    private http: HttpClient,
    public utils: Utils,
    private disconnectOperationService: DisconnectOperationsService) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      phone: [null,
        Validators.compose([Validators.required]),
        Validators.composeAsync([])
      ],
      pin: [null,
        Validators.compose([Validators.required, Validators.pattern(this.numberRegEx)]),
        Validators.composeAsync([])
      ]
    });
  }

  phoneHasError(e) {
    if (!e) {
      if (this.telCountry) {
        if (this.telCountry.dialCode !== '237') {
          this.form.controls['phone'].setErrors({ 'badPhone': true });
        } else {
          const phone = this.form.controls['phone'].value;
          if (phone !== null && phone !== undefined) {
            if (phone.indexOf('+') === -1) {
              this.form.controls['phone'].setValue('+' + this.telCountry.dialCode + phone);
            }
          }
        }
      } else {
        this.form.controls['phone'].setErrors({ 'badPhone': true });
      }
    }
  }

  onCountryChange(e) {
    this.telCountry = e;
  }

  getPhone(e) {
    this.form.controls['phone'].setValue(e);
  }

  onSubmit() {
    this.loading = true;
    this.disconnectOperationService.getBalance(this.form.value.phone, this.form.value.pin).subscribe(
      (response: any) => {
        this.config.data.message = 'Votre solde est de : ' + response.prefixedBalance;
        this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
        this.dialogRef.afterClosed().subscribe((result) => {
          this.form.reset();
          this.form.setErrors(null);
          this.loading = false;
        });
      },
      (error) => {
        let message = error.error.message;
        let correctPlatform = null;
        if (message === 'INVALID PIN') {
          message = 'Pin invalide';
        } else if (message === 'THIS OPERATION IS DONE ON A PLATFORM WHICH IS NOT YOUR\'S') {
          message = 'Vous tentez d\'effectuer une opération sur une platforme qui n\'est pas la votre';
          if (error.error.platform) {
            correctPlatform = error.error.platform;
          }
          // console.log(correctPlatform);
        } else if (message === 'ACCOUNT BLOCKED') {
          message = 'Votre compte a été bloqué';
        }

        this.config.data.message = message;
        this.config.data.correctPlatform = correctPlatform;
        this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
        this.dialogRef.afterClosed().subscribe((result) => {
          this.form.reset();
          this.loading = false;
        });
      }
    );
  }

}
