<div class="app-invoice-content">
  <div class="invoice-container">
    <mat-toolbar class="page-title">
      <span><mat-icon class="page-title-icon" role="img" aria-hidden="true">view_quilt</mat-icon> Facture</span>
    </mat-toolbar>
    <div class="invoice-container-content">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium earum laboriosam nihil odio, quae quisquam rerum tempore! Accusantium alias asperiores deleniti, dicta hic, laboriosam provident quam reprehenderit sequi sit voluptas?
      </p>
      <form action="">
        <div class="invoice-container-item">
          <mat-form-field>
            <input matInput placeholder="Numéro" value="">
          </mat-form-field>
        </div>
        <div class="invoice-container-item">
          <mat-form-field>
            <input matInput placeholder="Montant de la facture" type="number" value="" class="example-right-align">
            <span matPrefix>$&nbsp;</span>
            <span matSuffix>.00</span>
          </mat-form-field>
        </div>
        <div class="invoice-container-item1">
          <mat-form-field class="example-full-width">
            <textarea matInput #invoiceDescription maxlength="250" placeholder="Message"></textarea>
            <mat-hint align="end">{{invoiceDescription.value.length}} / 250</mat-hint>
          </mat-form-field>
        </div>
        <div class="invoice-container-item1">
          <mat-form-field>
            <input matInput placeholder="Référence" value="" disabled="disabled">
          </mat-form-field>
        </div>
        <div class="invoice-container-item1">
          <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true" class="my-background-color">Facturer</button>
        </div>
      </form>
    </div>
  </div>
</div>
