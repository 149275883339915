import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import {GlobalVariable} from '../global';
import {ChangePassword} from '../shared/changePassword.model';
import {Transfert} from '../shared/transfert.model';
import {CustomUrlEncoder} from '../shared/custom-url-encoder';

@Injectable()
export class DisconnectOperationsService {

  constructor(private http: HttpClient) {
  }

  getBalance(phone: string, pin: string) {
    const params = new HttpParams({encoder: new CustomUrlEncoder()})
      .set('phone', phone)
      .set('pin', pin);

    return this.http.get(GlobalVariable.BASE_API_URL + '/logged-out/customer/balance', {
      params
    }).map(
      (response: HttpResponse<any>) => {
        return response;
      }
    );
  }

  changePin(changePassword: ChangePassword) {

    const body = Object.assign({}, changePassword);
    delete body.phone;

    return this.http.put(GlobalVariable.BASE_API_URL + '/logged-out/customer/' + changePassword.phone + '/change-password',
      body
    ).map(
      (response: HttpResponse<any>) => {
        return response;
      }
    );
  }

  checkCustomer(phone: string) {

    return this.http.get(GlobalVariable.BASE_API_URL + '/logged-out/customer/' + phone + '/check', {
      }
    ).map(
      (response: HttpResponse<any>) => {
        return response;
      }
    );
  }

  transfert(transfert: Transfert) {

    return this.http.post(GlobalVariable.BASE_API_URL + '/logged-out/customer/operations/transfert',
      transfert
    ).map(
      (response: HttpResponse<any>) => {
        return response;
      }
    );
  }

  calculateOperationCost(operationCostCalculate: any) {

    return this.http.post(GlobalVariable.BASE_API_URL + '/logged-out/customer/calculate-operation-cost',
      operationCostCalculate
    ).map(
      (response: HttpResponse<any>) => {
        return response;
      }
    );
  }

}
