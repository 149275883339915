import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import { PhoneNumberUtil } from 'google-libphonenumber';
import { GlobalVariable } from '../../global';

@Injectable()
export class Utils {

  private countryOptions: string;

  selectorOptions = {
    initialCountry: 'auto',
    nationalMode: true,
    utilsScript: '/assets/js/utils.js',
        preferredCountries: ["BJ"],
    excludeCountries: [
      "AF",
      "AL",
      "DZ",
      "AS",
      "AD",
      "AO",
      "AI",
      "AQ",
      "AG",
      "AR",
      "AM",
      "AW",
      "AU",
      "AT",
      "AZ",
      "BS",
      "BH",
      "BD",
      "BB",
      "BY",
      "BE",
      "BZ",
      "BM",
      "BT",
      "BO",
      "BQ",
      "BA",
      "BW",
      "BV",
      "BR",
      "IO",
      "BN",
      "BG",
      "BI",
      "CV",
      "KH",
      "CM",
      "CA",
      "KY",
      "CF",
      "TD",
      "CL",
      "CN",
      "CX",
      "CC",
      "CO",
      "KM",
      "CD",
      "CG",
      "CK",
      "CR",
      "HR",
      "CU",
      "CW",
      "CY",
      "CZ",
      "DK",
      "DJ",
      "DM",
      "DO",
      "EC",
      "EG",
      "SV",
      "GQ",
      "ER",
      "EE",
      "SZ",
      "ET",
      "FK",
      "FO",
      "FJ",
      "FI",
      "FR",
      "GF",
      "PF",
      "TF",
      "GA",
      "GM",
      "GE",
      "DE",
      "GH",
      "GI",
      "GR",
      "GL",
      "GD",
      "GP",
      "GU",
      "GT",
      "GG",
      "GN",
      "GY",
      "HT",
      "HM",
      "VA",
      "HN",
      "HK",
      "HU",
      "IS",
      "IN",
      "ID",
      "IR",
      "IQ",
      "IE",
      "IM",
      "IL",
      "IT",
      "JM",
      "JP",
      "JE",
      "JO",
      "KZ",
      "KE",
      "KI",
      "KP",
      "KR",
      "KW",
      "KG",
      "LA",
      "LV",
      "LB",
      "LS",
      "LR",
      "LY",
      "LI",
      "LT",
      "LU",
      "MO",
      "MG",
      "MW",
      "MY",
      "MV",
      "MT",
      "MH",
      "MQ",
      "MR",
      "MU",
      "YT",
      "MX",
      "FM",
      "MD",
      "MC",
      "MN",
      "ME",
      "MS",
      "MA",
      "MZ",
      "MM",
      "NA",
      "NR",
      "NP",
      "NL",
      "NC",
      "NZ",
      "NI",
      "NG",
      "NU",
      "NF",
      "MP",
      "NO",
      "OM",
      "PK",
      "PW",
      "PS",
      "PA",
      "PG",
      "PY",
      "PE",
      "PH",
      "PN",
      "PL",
      "PT",
      "PR",
      "QA",
      "MK",
      "RO",
      "RU",
      "RW",
      "RE",
      "BL",
      "SH",
      "KN",
      "LC",
      "MF",
      "PM",
      "VC",
      "WS",
      "SM",
      "ST",
      "SA",
      "RS",
      "SC",
      "SL",
      "SG",
      "SX",
      "SK",
      "SI",
      "SB",
      "SO",
      "ZA",
      "GS",
      "SS",
      "ES",
      "LK",
      "SD",
      "SR",
      "SJ",
      "SE",
      "CH",
      "SY",
      "TW",
      "TJ",
      "TZ",
      "TH",
      "TL",
      "TK",
      "TO",
      "TT",
      "TN",
      "TR",
      "TM",
      "TC",
      "TV",
      "UG",
      "UA",
      "AE",
      "GB",
      "UM",
      "US",
      "UY",
      "UZ",
      "VU",
      "VE",
      "VN",
      "VG",
      "VI",
      "WF",
      "EH",
      "YE",
      "ZM",
      "ZW",
      "XK",
     "AX"],
    geoIpLookup: function(callback) {
      if (this.countryOptions) {
        callback(this.countryOptions);
      } else {
        this.http.get( GlobalVariable.PUBLIC_API_URL+'/country/by-ip').subscribe(
          (response) => {
            callback(response.countryCode);
          }
        );
      }
    }.bind(this),
  };

  constructor(private http: HttpClient) {
  }

  public static filterObjectArray(array, value) {
    const temp = [...array];
    return temp.filter(function(d) {
      let valid = false;
      Object.keys(d).forEach(
        (key) => {
          if (d[key].toLowerCase().includes(value)) {
            valid = true;
          }
        }
      );
      return valid;
    });
  }

  public static shuffleArray(array: (string|RegExp)[]): (string|RegExp)[] {
    let m = array.length;
    // While there remain elements to shuffle
    while (m) {
      // Pick a remaining element…
      const i = Math.floor(Math.random() * m--);
      // And swap it with the current element.
      const t = array[m];
      array[m] = array[i];
      array[i] = t;
    }
    return array;
  }

  public static getSecretCodeMask() {
    const mask = Utils.shuffleArray(['\*', /[0-9]/, '\*', /[0-9]/, '\*', /[0-9]/, '\*', /[0-9]/, '\*', /[0-9]/]);
    return {mask: mask};
  }

  public static GetCountryCodeFromPhone(phone) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const countryPhoneCode  = phoneUtil.parse(phone).getCountryCode();
    return phoneUtil.getRegionCodeForCountryCode(countryPhoneCode);
  }

}
