import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import 'rxjs/Rx';
import {Observable} from 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';

import {GlobalVariable} from '../../global';
import {Credentials} from './model/credentials.model';
import {User} from '../user/model/user.model';
import {AuthToken} from './model/auth-token.model';
import {UserService} from '../user/user.service';
import {UserSubscription} from '../user/model/user-subscription.model';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class AuthService {

  private loginUrl: string = GlobalVariable.BASE_API_URL + '/auth-tokens';
  private subscriptionUrl: string = GlobalVariable.BASE_API_URL + '/logged-out/inscription';
  public logoutUser: Subject<Boolean> = new Subject<Boolean>();

  constructor(private http: HttpClient,
              private userService: UserService) {

    const userDataStored = window.localStorage.getItem('currentUser');

    if (userDataStored === null) {
      this.userService.currentUser.next(null);
    } else {
      this.userService.currentUser.next(JSON.parse(userDataStored));
    }

    this.userService.currentUser.subscribe(
      (user) => {
        if (user === null) {
          this.signOutUser();
          document.location.reload();
        }
      }
    );
  }

  confirmAuthCode(body) {
    const body_copy = Object.assign({}, body);
    delete body_copy.login;
    return this.http.post(GlobalVariable.BASE_API_URL + '/auth-token/validate' , body_copy)
    .map(
      (authToken: AuthToken) => {
        const user = new User(body.login, null, authToken);
        window.localStorage.clear();
        window.localStorage.setItem('currentUser', JSON.stringify(user));
        this.userService.currentUser.next(user);
        return user;
      }
    )
    .catch(
      (error: HttpErrorResponse) => {
        return throwError({
          code: error.status,
          message: error.error.message
        });
      }
    );
  }
  resendCode(token){
    return this.http.get(GlobalVariable.BASE_API_URL+ '/auth-token/resend?token='+encodeURIComponent(token));
  }
  signinUser(credentials: Credentials) {
    const body = Object.assign({}, credentials);
    delete body.rememberMe;
    console.log("REQUEST SEND");
    
    return this.http.post(this.loginUrl, body)
      .map(
        (authToken: AuthToken) => {
          if (authToken.valid) {
            const user = new User(credentials.login, null, authToken);
            window.localStorage.setItem('currentUser', JSON.stringify(user));
            this.userService.currentUser.next(user);
            return user;
          } else {
            return authToken;
          }
        }
      )
      .catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            message: error.error.message,
            remainingLogins: error.error.remainingLogins ? error.error.remainingLogins : null
          });
        }
      );
  }

  simpleAuthSigningUser(credentials: Credentials) {

    const body = Object.assign({}, credentials);
    delete body.rememberMe;

    return this.http.post(this.loginUrl, body)
      .map(
        (authToken: AuthToken) => {
          const user = new User(credentials.login, null, authToken);
          window.localStorage.setItem('currentUser', JSON.stringify(user));
          this.userService.currentUser.next(user);
          return user;
        }
      )
      .catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            message: error.error.message,
            remainingLogins: error.error.remainingLogins ? error.error.remainingLogins : null
          });
        }
      );
  }

  doubleAuthSigningUser(credentials: Credentials) {

    const body = Object.assign({}, credentials);
    delete body.rememberMe;

    return this.http.post(this.loginUrl, body)
      .map(
        (authToken: AuthToken) => {
          // const user = new User(credentials.login, null, authToken);
          // window.localStorage.setItem('currentUser', JSON.stringify(user));
          // this.userService.currentUser.next(user);
          return authToken;
        }
      )
      .catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            message: error.error.message,
            remainingLogins: error.error.remainingLogins ? error.error.remainingLogins : null
          });

        }
      );
  }

  resetPin(phone: string) {

    return this.http.get(GlobalVariable.BASE_API_URL + '/logged-out/customer/' + phone + '/reset-pin')
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
      .catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error)
          });
        }
      );
  }

  signupUser(userSubscription: UserSubscription) {

    return this.http.post(this.subscriptionUrl, userSubscription)
      .map(
        (user: UserSubscription) => {
          return user;
        }
      )
      .catch(
        (error: HttpErrorResponse) => {
          console.log(error);
          return throwError({
            code: error.status,
            content: error.error
          });
        }
      );
  }

  signOutUser() {
    window.localStorage.removeItem('currentUser');
    // this.userService.currentUser.next(null);
  }

  isAuthenticated(): boolean {
    return this.userService.userExists();
  }

}
