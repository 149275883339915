<div class="app-operations-content">
  <div class="operations-container">
    <mat-toolbar class="page-title">
      <span [appAppColor]="{default : ['color']}">
        <!-- <mat-icon class="page-title-icon" role="img" 
        aria-hidden="true">history</mat-icon> -->
         Transfert planifié</span>
    </mat-toolbar>
    <div class="actions-buttons">
      <button mat-raised-button mat-ripple style="color: white;"
                  matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true"
                  [appAppColor]="{default : ['background-color']}" 
                   (click)="onAddPermanentTransert()" >
            <mat-progress-spinner mode="indeterminate" [diameter]="25" [strokeWidth]="2"
                                  *ngIf="loading" >
            </mat-progress-spinner>
            Nouveau
      </button>
    </div>
    <div class="my-ongoing-operations-table-container">

      <mat-table #table [dataSource]="dataSource" 
      class="my-ongoing-operations-table-container-content">
    
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
    
        <ng-container matColumnDef="reference">
          <mat-header-cell *matHeaderCellDef class="table-title"> Titre </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.title}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="recipientAccount">
          <mat-header-cell *matHeaderCellDef class="table-title">
            Compte reçeveur</mat-header-cell>
          <mat-cell  *matCellDef="let element">
            {{ element.recipient.accountNumber }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="startOperationDate">
          <mat-header-cell *matHeaderCellDef class="table-title">
            Date de début</mat-header-cell>
          <mat-cell  *matCellDef="let element">
            {{ element.nextExecutionDate | date:'yyyy-MM-dd':'':'fr' }}
          </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef class="table-title"> Montant </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{((element.amount | floorAmount) | currency: 'XOF')}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="frequency">
          <mat-header-cell *matHeaderCellDef class="table-title"> Fréquence </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.frequency}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="iteration">
          <mat-header-cell *matHeaderCellDef class="table-title"> Nombre exécution </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.iteration}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="table-title"> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.status == 'ONGOING'" style="cursor: pointer;" (click)="onPausePermanentTransfert(element)">pause</mat-icon>
            <mat-icon *ngIf="element.status == 'PAUSE'" style="cursor: pointer;" (click)="onRestartPermanentTransfert(element)">refresh</mat-icon>
            <mat-icon style="cursor: pointer;" color="primary" (click)="onEditPermanentTransert(element)" class="sidebar-icon" role="img" aria-hidden="true">edit</mat-icon>
            <mat-icon style="cursor: pointer;" color="danger" (click)="onDeletePermanentTransert(element)" class="sidebar-icon" role="img" aria-hidden="true">delete</mat-icon>
            <span *ngIf="element.status == 'PAUSE'" style="color:#fff;background-color:red;padding:4px;border-radius:5px">En pause</span>
          </mat-cell>
        </ng-container>
    
        <!-- operationDescription Column -->
        <!-- <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef class="table-title"> Description </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Description:</span>
            {{element.description}}
          </mat-cell>
        </ng-container> -->
    
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row  *matRowDef="let row; columns: displayedColumns;"></mat-row>
    
      </mat-table>
    </div>    
  </div>
</div>    

