import {AfterViewChecked, Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {PlatformConfig} from '../../../app-loader/platform-config';
import {ConfigLoaderService} from '../../../app-loader/config-loader.service';

@Directive({
  selector: '[appAppColor]'
})
export class AppColorDirective implements OnInit, AfterViewChecked {

  @Input() appAppColor: AppColorDirectiveModel;

  private platformConfig: PlatformConfig;

  constructor(private renderer: Renderer2, private el: ElementRef, private configLoader: ConfigLoaderService) {
    this.platformConfig = configLoader.getPlatformConfig();
  }

  ngOnInit(): void {
    if (this.appAppColor !== null && this.appAppColor !== undefined) {
      if (this.appAppColor.default !== null && this.appAppColor.default !== undefined) {
        this.appAppColor.default.forEach(
          (style) => {
            this.renderer.setStyle(this.el.nativeElement, style , this.platformConfig.platform.clientAppColor );
          }
        );
      }
    }
  }

  ngAfterViewChecked(): void {
    if (this.appAppColor !== null && this.appAppColor !== undefined) {
      if (this.appAppColor.active !== null && this.appAppColor.active !== undefined) {
        this.appAppColor.active.forEach(
          (style) => {
            if ((<DOMTokenList>this.el.nativeElement.classList).contains('active')) {
              this.renderer.setStyle(this.el.nativeElement, style, this.platformConfig.platform.clientAppColor);
            } else {
              this.renderer.removeStyle(this.el.nativeElement, style);
            }
          }
        );
      }
    }
  }
}

export class AppColorDirectiveModel {
  default: string[] =  [];
  active: string[] = [];
}
