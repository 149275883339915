
import {AuthToken} from '../../auth/model/auth-token.model';

export class User {

  public username: string;
  public password: string;
  public authToken: AuthToken;

  constructor(username: string = null, password: string = null, authToken: AuthToken = null) {
    this.username = username;
    this.password = password;
    this.authToken = authToken;
  }

}
