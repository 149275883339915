
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

import {ProfileService} from './profile.service';
import {UserSubscription} from '../user/model/user-subscription.model';

@Injectable()
export class ProfileResolver implements Resolve<UserSubscription> {

  constructor(private profileService: ProfileService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<UserSubscription> | Promise<UserSubscription> | UserSubscription {

    return this.profileService.getProfile();

  }

}
