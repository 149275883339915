import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import { Subject } from 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';

import {RequestParameters} from '../shared/models/request-parameters/parameter.model';
import { FieldRequestParameters } from '../shared/models/request-parameters/parameter.model';
import {GlobalVariable} from '../global';

@Injectable()
export class AboutService {

  public countriesSubject = new Subject<any>();

  constructor(private http: HttpClient) {
  }

  getCountries () {
    return this.http.get(GlobalVariable.BASE_API_URL + '/logged-out/countries?limit=1000')
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );
  }
  getCountryTarif(source: string, target: string) {
    return this.http.get(GlobalVariable.BASE_API_URL + '/logged-out/country/operation-costs?sourceCountry=' +source+ '&targetCountry=' +target+ '&operationType=TRANSFERT')
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );
  }

  getCities (countryReference, parameters: RequestParameters = new RequestParameters()) {
    const fieldReqParams = new FieldRequestParameters('state.country', countryReference);
    parameters.fields = [];
    parameters.fields.push(fieldReqParams);
    return this.http.get(GlobalVariable.BASE_API_URL + '/logged-out/cities', {
      params: parameters.getHttpParams()
    })
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );
  }

  getAgencies(cityReference, parameters: RequestParameters = new RequestParameters()) {
    const fieldReqParams = new FieldRequestParameters('city', cityReference);
    parameters.fields = [];
    parameters.fields.push(fieldReqParams);
    return this.http.get(GlobalVariable.BASE_API_URL + '/logged-out/agencies', {
      params: parameters.getHttpParams()
    })
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );
  }

}
