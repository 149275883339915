<div [ngClass]="{'sidebar-container': TEST_MODE, 'sidebar-container1': !TEST_MODE}">
  <div class="left-sidenav">
    <div [appAppColor]="{default : ['background-color']}" class="user-image">
      <div class="user-image-content">
        <img mat-card-image src="assets/images/user.png" alt="Photo of user" *ngIf="profile.picture === null">
        <img mat-card-image src="{{ mediaBaseUrl + profile.picture.path }}" alt="Photo of user" *ngIf="profile.picture !== null">
      </div>
      <h3>{{ profile.lastname }} {{ profile.firstname }}</h3>
    </div>
    <ul>
      <li routerLinkActive="active" [appAppColor]="appColorSideMenu">
        <a mat-button routerLink="/user/dashboard">
          <mat-icon class="sidebar-icon" role="img" aria-hidden="true">dashboard</mat-icon> <span>Tableau de bord</span>
        </a>
      </li>
      <li routerLinkActive="active" [appAppColor]="appColorSideMenu">
        <a mat-button routerLink="/user/my-account">
          <mat-icon class="sidebar-icon" role="img" aria-hidden="true">account_circle</mat-icon> <span>Mon compte</span>
        </a>
      </li>
      <li routerLinkActive="active" [appAppColor]="appColorSideMenu">
        <a mat-button routerLink="/user/operations">
          <mat-icon class="sidebar-icon" role="img" aria-hidden="true">history</mat-icon> <span>Mes opérations en cours</span>
        </a>
      </li>
      <li routerLinkActive="active" [appAppColor]="appColorSideMenu">
        <a mat-button routerLink="/user/transfert">
          <mat-icon class="sidebar-icon" role="img" aria-hidden="true">compare_arrows</mat-icon> <span>Transfert</span>
        </a>
      </li>
      <li routerLinkActive="active" [appAppColor]="appColorSideMenu">
        <a mat-button routerLink="/user/transfert-collect">
          <mat-icon class="sidebar-icon" role="img" 
          aria-hidden="true">compare_arrows</mat-icon> <span>BMo PAY</span>
        </a>
      </li>
      <li routerLinkActive="active" [appAppColor]="appColorSideMenu">
        <a mat-button routerLink="/user/permanent-transfert">
          <mat-icon class="sidebar-icon" role="img" 
          aria-hidden="true">compare_arrows</mat-icon> 
          <span>Transfert planifié</span>
        </a>
      </li>
      <li routerLinkActive="active" [appAppColor]="appColorSideMenu">
        <a mat-button routerLink="/user/debit-atm">
          <mat-icon class="sidebar-icon" role="img" 
          aria-hidden="true">compare_arrows</mat-icon> 
          <span>Débit ATM</span>
        </a>
      </li>
      <li routerLinkActive="active" [appAppColor]="appColorSideMenu">
        <a mat-button routerLink="/user/to-pay">
          <mat-icon class="sidebar-icon" role="img" aria-hidden="true">payment</mat-icon> <span>Paiement</span>
        </a>
      </li>
      <li routerLinkActive="active" [appAppColor]="appColorSideMenu" *ngIf="false">
        <a mat-button routerLink="/user/invoice">
          <mat-icon class="sidebar-icon" role="img" aria-hidden="true">view_quilt</mat-icon> <span>Facture</span>
        </a>
      </li>
      <li routerLinkActive="active" [appAppColor]="appColorSideMenu">
        <a mat-button routerLink="/user/report">
          <mat-icon class="sidebar-icon" role="img" aria-hidden="true">description</mat-icon> <span>Relevé</span>
        </a>
      </li>
      <li routerLinkActive="active" [appAppColor]="appColorSideMenu">
        <a mat-button routerLink="/user/user-contact">
          <mat-icon class="sidebar-icon" role="img" aria-hidden="true">forum</mat-icon> <span>Contactez-nous</span>
        </a>
      </li>
      <!--<li routerLinkActive="active">
        <a mat-button routerLink="/user/parameters">
          <mat-icon class="sidebar-icon" role="img" aria-hidden="true">settings</mat-icon> <span>Paramètres</span>
        </a>
      </li>-->
    </ul>
  </div>
</div>
