import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs/Observable';


export class CustomValidators {

  public static linkedEntity(control: FormControl): Promise<any> | Observable<any> | { invalidLinkedEntity: boolean } | any {
    const value = control.value;
    if (control.value === '') {
      return false;
    }
    if (value !== null) {
      if (value.reference === undefined) {
        return { invalidLinkedEntity: true };
      }
    }
  }

}
