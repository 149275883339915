<div class="pin-change-container">
  
    <p [appAppColor]="{default : ['color']}" 
     class="connection-header" style="text-align: center !important;">CHANGEMENT DE PIN</p>
     <br>
  <p style="text-align: center;" >Vous devez changer de PIN pour continuer</p>
  <form [formGroup]="form" >
      <div style="width: 100%;">
        <mat-form-field style="width: 100%;">
          <input type="password" matInput placeholder="Votre code pin" value=""
                 [formControl]="form.controls['oldPassword']">
          <mat-error *ngIf="form.controls['oldPassword'].hasError('required') && form.controls['oldPassword'].touched">
            Ce champ est requis
          </mat-error>
          <mat-error *ngIf="(form.controls['oldPassword'].hasError('maxLength') || form.controls['oldPassword'].hasError('minlength')) && form.controls['oldPassword'].touched">
           Le PIN actuel doit être de 6 caractères.
         </mat-error>
         <!-- <mat-error *ngIf="form.controls['pin'].hasError('pattern') && form.controls['pin'].touched">
          La valeur entré doit être numérique
        </mat-error> -->
        </mat-form-field>
      </div>
      <div style="width: 100%;">
        <mat-form-field style="width: 100%;">
          <input type="tel" matInput placeholder="Nouveau code pin" value=""
                 [formControl]="form.controls['plainPassword']['controls']['first']" #password style="-webkit-text-security:disc;">
          <mat-error *ngIf="form.controls['plainPassword']['controls']['first'].hasError('required') && form.controls['plainPassword']['controls']['first'].touched">
            Ce champ est requis
          </mat-error>
          <mat-error *ngIf="(form.controls['plainPassword']['controls']['first'].hasError('minlength')
           || form.controls['oldPassword'].hasError('maxLength')) && form.controls['plainPassword']['controls']['first'].touched">
            Le nouveau PIN doit être de 6 caractères.
          </mat-error>
          <mat-error *ngIf="form.controls['plainPassword']['controls']['first'].hasError('pattern') || form.controls['plainPassword']['controls']['first'].touched">
              La valeur entré doit être numérique
          </mat-error>
        </mat-form-field>
      </div>
      <div style="width: 100%;">
        <mat-form-field style="width: 100%;">
          <input type="tel" matInput placeholder="Confirmer le nouveau code pin" value=""
                 [formControl]="form.controls['plainPassword']['controls']['second']" #confirmPassword style="-webkit-text-security:disc;">
          <mat-error *ngIf="form.controls['plainPassword']['controls']['second'].hasError('required') && form.controls['plainPassword']['controls']['second'].touched">
            Ce champ est requis
          </mat-error>
          <mat-error *ngIf="form.controls['plainPassword']['controls']['second'].hasError('MatchPassword') && form.controls['plainPassword']['controls']['second'].touched">
            Les mots de  passe doivent correspondre
          </mat-error>
          <mat-error *ngIf="form.controls['plainPassword']['controls']['second'].hasError('pattern') || form.controls['plainPassword']['controls']['second'].touched">
            La valeur entré doit être numérique
        </mat-error>
        </mat-form-field>
      </div>
        <button style="width: 100%;
        font-size: 1em;
        color: #fff;
        line-height: 40px;
        margin-top: 10px;" (click)="onSubmit()" mat-raised-button
                mat-ripple matRippleColor="rgba(255,255,255,.5)"
                matRippleCentered="true" [appAppColor]="{default : ['background-color']}" [disabled]="!form.valid || loading">
          <mat-progress-spinner mode="indeterminate" class="primary"
           [diameter]="25" [strokeWidth]="2" *ngIf="loading" >
          </mat-progress-spinner>
          Valider
        </button>
        <mat-dialog-actions align="end" *ngIf="data.for == 'change-password'">
          <button mat-button mat-dialog-close>Fermer</button>
        </mat-dialog-actions>
  </form>  
</div>