import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import { ContactService } from './contact.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { DialogContactBoxComponent } from '../shared/components/dialog-contact-box/dialog-contact-box.component';
import {Utils} from '../shared/utils/Utilis';
import {GlobalVariable} from '../global';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  public form: FormGroup;
  loading = false;
  private loggoutContactUrl = '/logged-out/contact-clk';
  TEST_MODE = GlobalVariable.TEST_MODE;
  telCountry: any;
  constructor(private fb: FormBuilder,
              private http: HttpClient,
              public dialog: MatDialog,
              public utils: Utils,
              private contactService: ContactService) {
  }

  ngOnInit() {
    this.form = this.fb.group ( {
      firstname: [null , Validators.compose ( [ Validators.required ] )] ,
      lastname: [null , Validators.compose ( [ Validators.required ] )],
      phone: [null , Validators.compose ( [Validators.required] )],
      email: [null , Validators.compose ( [ Validators.email, Validators.required] )],
      message: [null , Validators.compose ( [ Validators.required ] )]
    });
  }
  phoneHasError(e) {
    if (!e) {
      if (this.telCountry) {
        if (this.telCountry.dialCode !== '237') {
          this.form.controls['phone'].setErrors({'badPhone': true});
        } else {
          const phone = this.form.controls['phone'].value;
          if (phone !== null && phone !== undefined) {
            if (phone.indexOf('+') === -1) {
              this.form.controls['phone'].setValue('+' + this.telCountry.dialCode + phone);
            }
          }
        }
      } else {
        this.form.controls['phone'].setErrors({'badPhone': true});
      }
    }
  }

  onCountryChange(e) {
    this.telCountry = e;
  }
  getPhone(e) {
    this.form.controls['phone'].setValue(e);
  }
  onContactSubmit() {
   this.form.disable();
   this.loading = true;
   this.contactService.postContact(this.form.value, this.loggoutContactUrl).subscribe(
     (response) => {
       const dialogRef = this.dialog.open(DialogContactBoxComponent, {
         width: '250px',
         disableClose: false,
         data: { message: 'Votre message a été envoyé'}
       });
       dialogRef.afterClosed().subscribe(result => {
          this.form.enable();
          this.form.reset();
       });
        this.loading = false;
     },
      (error) => {
        this.loading = false;
        this.form.enable();
      }
    );
  }
}
