import { CustomerDetailsDialogService } from './../customer-details-dialog/customer-details-dialog.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-collect-customer-details-dialog',
  templateUrl: './collect-customer-details-dialog.component.html',
  styleUrls: ['./collect-customer-details-dialog.component.css']
})
export class CollectCustomerDetailsDialogComponent implements OnInit {

  customer: {
    country: ''
    phone: '',
    name: '',
    account: ''
  };

  constructor(public dialogRef: MatDialogRef<CollectCustomerDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private customerDetailsService: CustomerDetailsDialogService) {
      this.customer = data;
  }

  ngOnInit() {
    this.loadCollectCustomerInfos(this.data.phone)
  }

  loadCollectCustomerInfos(reference: String) {
    this.customerDetailsService.getCollectCustomerInfos(reference)
    .subscribe(
      userInfo => {
        this.customer.name = userInfo.name
        this.customer.account = userInfo.account.reference
        this.customer.country = userInfo.country.name
        this.customer.phone = userInfo.username
      },
      error => {throw error}
    )
  }

  onClose() {
    this.dialogRef.close(null);
  }

}
