import { Component, OnInit } from '@angular/core';
import { ConfigLoaderService } from '../app-loader/config-loader.service';
import { PlatformConfig} from '../app-loader/platform-config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  platformConfig: PlatformConfig;

  constructor( public configLoaderService: ConfigLoaderService) {
    this.platformConfig = this.configLoaderService.getPlatformConfig();
  }

  ngOnInit() {
  }

}
