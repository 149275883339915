<div>
    <p>Detailed Infos on {{customer.phone}} </p>
    <mat-list>
      <mat-divider></mat-divider>
      <mat-list-item> <span class="title">FIRSTNAME</span> :  <span class="content">{{customer.lastname.toUpperCase()}}</span> </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item><span class="title">LASTNAME</span> : <span class="content"> {{customer.firstname}}</span> </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item> <span class="ti">COUNTRY</span> : <span class="content">{{customer.country}}</span> </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>

    <br/> <br/>

    <a mat-raised-button mat-ripple (click)="onClose()"
     color="primary" >Close</a>

</div>
