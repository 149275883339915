import {Injectable, Injector} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

import {PlatformConfig} from './platform-config';
import {GlobalVariable} from '../global';

@Injectable()
export class ConfigLoaderService {

  private platformConfig: PlatformConfig;

  constructor(private injector: Injector) {
  }

  get http() {
    return this.injector.get(HttpClient);
  }

  loadPlatformConfig() {
    const platformConfigAge = new Date().getTime() - (+window.localStorage.getItem('platformConfigDate') );
    // Old platform config time stemp check platformConfigAge > (24 * 60 * 60 * 1000)
    if ( platformConfigAge ) {
      return new Promise((resolve, reject) => {
        this.refreshPlatformConfig().subscribe(
          (platformConfig: PlatformConfig) => {
            resolve();
          }, (error: HttpErrorResponse) => {
            alert('SERVER CONNEXION ERROR');
          }
        );
      });
    } else {
      return new Promise((resolve, reject) => {
        this.platformConfig = JSON.parse(window.localStorage.getItem('platformConfig'));
        resolve();
      });
    }
  }

  getPlatformConfig(): PlatformConfig {
    return this.platformConfig;
  }

  refreshPlatformConfig() {
    return this.http.get(GlobalVariable.PUBLIC_API_URL + '/platform-config', {
    })
      .map(
        (response: PlatformConfig) => {
          this.platformConfig = response;
          window.localStorage.setItem('platformConfig', JSON.stringify(response));
          window.localStorage.setItem('platformConfigDate', '' + new Date().getTime());
          return response;
        }
      );
  }

}
