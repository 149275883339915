<div class="disconnect-container-content">
  <div class="app-inscription-container">
    <div [ngClass]="{'app-inscription-container-content': TEST_MODE, 'app-inscription-container-content1': !TEST_MODE}">
      <div class="app-inscription-all-content">
        <div class="inscription-container-content">
          <div class="inscription-container-right">
            <div class="inscription-right-content">
              <mat-toolbar>
                <span [appAppColor]="{default : ['color']}" class="inscription-header">Créer un compte</span>
              </mat-toolbar>
              <form [formGroup]="form">
                <div *ngIf="!loading && formErrors.length != 0">
                  <div class="form-errors">
                    <h2 class="form-error" *ngFor="let error of formErrors" >{{ error }}</h2>
                  </div>
                </div>
                <div class="inscription-container-item">
                  <mat-form-field class="tel-input-form">
                    <input matInput placeholder="Numéro de téléphone"
                           [formControl]="form.controls['phone']"
                           ng2TelInput
                           (countryChange)="onCountryChange($event)"
                           (ng2TelOutput)="getPhone($event)"
                           [ng2TelInputOptions]="utils.selectorOptions"
                           (countryChange)="onCountryChange($event)"
                           (hasError)="phoneHasError($event)">
                    <mat-error *ngIf="form.controls['phone'].hasError('badPhone') && form.controls['phone'].touched">
                      Numéro de téléphone invalide
                    </mat-error>
                    <mat-error *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched">
                      Ce champ est requis
                    </mat-error>
                    <mat-error *ngIf="form.controls['phone'].hasError('phoneIsUnique') && form.controls['phone'].touched">
                      Ce numéro est déja utilisé
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="inscription-container-item">
                  <mat-form-field>
                    <input matInput placeholder="Nom"
                           [formControl]="form.controls['lastname']">
                    <mat-error *ngIf="form.controls['lastname'].hasError('required') && form.controls['lastname'].touched">
                      Ce champ est requis
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="inscription-container-item">
                  <mat-form-field>
                    <input matInput placeholder="Prénoms"
                           [formControl]="form.controls['firstname']">
                    <mat-error *ngIf="form.controls['firstname'].hasError('required') && form.controls['firstname'].touched">
                      Ce champ est requis
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="inscription-container-item">
                  <mat-form-field>
                    <input matInput placeholder="Adresse"
                           [formControl]="form.controls['address']">
                    <mat-error *ngIf="form.controls['address'].hasError('required') && form.controls['address'].touched">
                      Ce champ est requis
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="inscription-container-item">
                  <mat-form-field>
                    <input matInput placeholder="Email"
                           (keyup)="onEmailKeyUpBlur($event)"
                           (blur)="onEmailKeyUpBlur($event)"
                           [formControl]="form.controls['email']">
                    <mat-error *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched">
                      Email incorrect
                    </mat-error>
                    <mat-error *ngIf="form.controls['email'].hasError('emailIsUnique') && form.controls['email'].touched">
                      Cet email est déja utilisé
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- <div *ngIf="!loading && formErrors.length != 0">
                  <div class="form-errors" >
                    <h2 class="form-error my-color" *ngFor="let error of formErrors" >{{ error }}</h2>
                  </div>
                </div> -->
                <div class="inscription-container-item">
                    <mat-checkbox [formControl]="form.controls['term']" >                    
                    </mat-checkbox>
                    <span>
                      J'ai lu et j'accepte 
                      <span style="cursor: pointer;" 
                      [appAppColor]="{default : ['color']}" (click)="showUseConditions()">
                        les conditions d'utilisation</span>
                        et 
                        <span style="cursor: pointer;" 
                        [appAppColor]="{default : ['color']}" (click)="showPrivacyPolicy()">
                        la politique de confidentialité</span>
                    </span>
                </div>
                <div class="inscription-container-item1">
                  <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                          matRippleCentered="true" [appAppColor]="{default : ['background-color']}"
                          type="submit"
                          (click)="onSubmit()" [disabled]="loading || !form.valid || !form.value.term">
                    <mat-progress-spinner mode="indeterminate" class="primary" [diameter]="25" [strokeWidth]="2" *ngIf="loading" >
                    </mat-progress-spinner>
                    Créer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
