import { BrowserModule } from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FlexLayoutModule} from '@angular/flex-layout';
import { MAT_DATE_LOCALE, MatRippleModule, MatRadioModule} from '@angular/material';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCarouselModule } from 'ngx-carousel';
import { Ng2TelInputModule} from 'ng2-tel-input';
import {LayoutModule} from '@angular/cdk/layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import 'hammerjs';

import { AppComponent } from './app.component';
import { MaterialModulesModule } from './shared/material/material-modules.module';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './user/sidebar/sidebar.component';
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { MyAccountComponent } from './user/my-account/my-account.component';
import { TransfertComponent } from './user/transfert/transfert.component';
import { ToPayComponent } from './user/to-pay/to-pay.component';
import { OperationsComponent } from './user/operations/operations.component';
import { ReportComponent } from './user/report/report.component';
import { ContactComponent } from './contact/contact.component';
import { ParametersComponent } from './user/parameters/parameters.component';
import { UserConnectionComponent } from './user-connection/user-connection.component';
import { UserInscriptionComponent } from './user-inscription/user-inscription.component';
import { OngoingOperationsComponent } from './user/dashboard/ongoing-operations/ongoing-operations.component';
import { PastOperationsComponent } from './user/dashboard/past-operations/past-operations.component';
import { MyUsersComponent } from './user/my-account/my-users/my-users.component';
import { AddUserComponent } from './user/my-account/my-users/add-user/add-user.component';
import { MyOngoingOperationsComponent } from './user/operations/my-ongoing-operations/my-ongoing-operations.component';
import { InvoiceComponent } from './user/invoice/invoice.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { UserComponent } from './user/user.component';
import { DisconnectOperationsComponent } from './disconnect-operations/disconnect-operations.component';
import { DisconnectTransferComponent } from './disconnect-operations/disconnect-transfer/disconnect-transfer.component';
import { DisconnectChangePinComponent } from './disconnect-operations/disconnect-change-pin/disconnect-change-pin.component';
import { DisconnectPayComponent } from './disconnect-operations/disconnect-pay/disconnect-pay.component';
import { DisconnectBalanceComponent } from './disconnect-operations/disconnect-balance/disconnect-balance.component';
import { UserContactComponent } from './user/user-contact/user-contact.component';
import { SidebarService } from './user/sidebar/sidebar.service';
import { TariffComponent } from './about/tariff/tariff.component';
import { HelpComponent } from './help/help.component';
import { SlideContentComponent } from './home/slide-content/slide-content.component';
import { PinForgotComponent } from './user-connection/pin-forgot/pin-forgot.component';
import { DisconnectOperationsService } from './disconnect-operations/disconnect-operations.service';
import { UserOperationsService } from './user/user-operations.service';
import { OperationResultDialogComponent } from './shared/components/operation-result-dialog/operation-result-dialog.component';
import { CustomerDialogComponent } from './shared/components/customer-dialog/customer-dialog.component';
import { AuthService } from './shared/auth/auth.service';
import { UserService } from './shared/user/user.service';
import { SnackMessageComponent } from './shared/snack-messages/snack-message/snack-message.component';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { AuthInterceptorService } from './shared/auth/auth-interceptor.service';
import { SnackMessageService } from './shared/snack-messages/snack-message.service';
import { ProfileResolver } from './shared/profile/profile-resolver.service';
import { ProfileService } from './shared/profile/profile.service';
import { MediaService } from './shared/media/media.service';
import { CitiesAutocompleteComponent } from './shared/components/cities-autocomplete/cities-autocomplete.component';
import { ConfirmOperationComponent } from './user/operations/confirm-operation/confirm-operation.component';
import { ReportDialogComponent } from './user/report/report-dialog/report-dialog.component';
import { BusinessInfosComponent } from './user/my-account/business-infos/business-infos.component';
import { UserInscriptionValidators } from './user-inscription/user-inscription.validators';
import { UserInscriptionService } from './user-inscription/user-inscription.service';
import { OperationReasonComponent } from './shared/components/operation-reason/operation-reason.component';
import { AboutService } from './about/about.service';
import {
  OperationAmountMovementIndicatorComponent
} from './shared/components/operation-amount-movement-indicator/operation-amount-movement-indicator.component';
import { ContactService} from './contact/contact.service';
import { DialogContactBoxComponent } from './shared/components/dialog-contact-box/dialog-contact-box.component';
import { Utils } from './shared/utils/Utilis';
import { FooterComponent } from './footer/footer.component';
import {AppLoaderModule} from './app-loader/app-loader.module';
import {AppColorDirective} from './shared/directives/app-color/app-color.directive';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { TermsComponent } from './shared/components/terms/terms.component';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { SlidePanelComponent } from './shared/components/slide-panel/slide-panel.component';
import { PinChangeComponent } from './shared/components/pin-change/pin-change.component';
import { PinChangeService } from './shared/components/pin-change/pin-change.service';

import { CurrencyFormatPipePipe } from './shared/pipes/CurrencyFormatPipe/currency-format-pipe.pipe';
import { CurrencyFormatDirectiveDirective } from './shared/directives/CurrencyFormatDirective/currency-format-directive.directive';
import {
  RegexpInputCheckDirective
} from './shared/directives/regexp-input-check/regexp-input-check.directive';
import { TransfertCollectComponent } from './user/transfert-collect/transfert-collect.component';
import { CollectAgenciesAutocompleteComponent } from './shared/components/collect-agencies-autocomplete/collect-agencies-autocomplete.component';
import { UsersCollectService } from './shared/models/users-collect/users-collect.service';
import {
  OperationCollectProductsDialogComponent
} from './user/transfert-collect/operation-collect-products-dialog/operation-collect-products-dialog.component';
import {
  CollectProductsAutocompleteComponent
} from './shared/components/collect-products-autocomplete/collect-products-autocomplete.component';
import { PermanentTransfertComponent } from './user/permanent-transfert/permanent-transfert.component';
import { ConfirmDialog, PermanentTransfertFormDialogComponent } from './user/permanent-transfert/permanent-transfert-form-dialog/permanent-transfert-form-dialog.component';
import { CustomerDetailsDialogComponent } from './shared/components/customer-details-dialog/customer-details-dialog.component';
import { CollectCustomerDetailsDialogComponent } from './shared/components/collect-customer-details-dialog/collect-customer-details-dialog.component';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { ConfirmTransfertDialogComponent } from './shared/components/confirm-transfert-dialog/confirm-transfert-dialog.component';
import { TransfertRecapDialogComponent } from './shared/components/transfert-recap-dialog/transfert-recap-dialog.component';
import { FloorAmountPipe } from './shared/pipes/floor-amount.pipe';
import { DebitAtmComponent } from './user/debit-atm/debit-atm.component';
// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    DashboardComponent,
    MyAccountComponent,
    TransfertComponent,
    ToPayComponent,
    OperationsComponent,
    ReportComponent,
    ContactComponent,
    ParametersComponent,
    UserConnectionComponent,
    UserInscriptionComponent,
    OngoingOperationsComponent,
    PastOperationsComponent,
    MyUsersComponent,
    AddUserComponent,
    MyOngoingOperationsComponent,
    InvoiceComponent,
    HomeComponent,
    AboutComponent,
    UserComponent,
    DisconnectOperationsComponent,
    DisconnectTransferComponent,
    DisconnectChangePinComponent,
    DisconnectPayComponent,
    DisconnectBalanceComponent,
    UserContactComponent,
    TariffComponent,
    HelpComponent,
    SlideContentComponent,
    PinForgotComponent,
    OperationResultDialogComponent,
    CustomerDialogComponent,
    SnackMessageComponent,
    CitiesAutocompleteComponent,
    ConfirmOperationComponent,
    ReportDialogComponent,
    BusinessInfosComponent,
    OperationReasonComponent,
    OperationAmountMovementIndicatorComponent,
    DialogContactBoxComponent,
    FooterComponent,
    AppColorDirective,
    TermsComponent,
    PrivacyPolicyComponent,
    SlidePanelComponent,
    PinChangeComponent,
    CurrencyFormatPipePipe,
    CurrencyFormatDirectiveDirective,
    RegexpInputCheckDirective,
    TransfertCollectComponent,
    CollectAgenciesAutocompleteComponent,
    CollectProductsAutocompleteComponent,
    OperationCollectProductsDialogComponent,
    PermanentTransfertComponent,
    PermanentTransfertFormDialogComponent,
    CustomerDetailsDialogComponent,
    CollectCustomerDetailsDialogComponent,
    LegalNoticeComponent,
    ConfirmTransfertDialogComponent,
    TransfertRecapDialogComponent,
    ConfirmDialog,
    FloorAmountPipe,
    DebitAtmComponent
  ],
  entryComponents : [
    AddUserComponent,
    BusinessInfosComponent,
    PinForgotComponent,
    OperationResultDialogComponent,
    CustomerDialogComponent,
    SnackMessageComponent,
    ConfirmOperationComponent,
    ReportDialogComponent,
    OperationReasonComponent,
    DialogContactBoxComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    SlidePanelComponent,
    PinChangeComponent,
    OperationCollectProductsDialogComponent,
    PermanentTransfertFormDialogComponent,
    CustomerDetailsDialogComponent,
    CollectCustomerDetailsDialogComponent,
    ConfirmTransfertDialogComponent,
    TransfertRecapDialogComponent,
    ConfirmDialog
  ],
  imports: [
    AppLoaderModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModulesModule,
    FlexLayoutModule,
    HttpClientModule,
    MatRippleModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCarouselModule,
    Ng2TelInputModule,
    LayoutModule,
    NgxDatatableModule,

  ],
  providers: [
    SidebarService,
    DisconnectOperationsService,
    UserOperationsService,
    AuthService,
    AuthGuard,
    UserService,
    SnackMessageService,
    ProfileService,
    MediaService,
    ProfileResolver,
    UserInscriptionValidators,
    UserInscriptionService,
    AboutService,
    ContactService,
    Utils,
    PinChangeService,
    CurrencyFormatPipePipe,
    UsersCollectService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: LOCALE_ID, useValue: 'fr-FR'}
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
