<div class="app-transfert-content">
  <div class="transfert-container">
    <mat-toolbar class="page-title">
      <span [appAppColor]="{default : ['color']}">
        <mat-icon class="page-title-icon" role="img" aria-hidden="true">
          compare_arrows</mat-icon> Débit ATM</span>
    </mat-toolbar>
    <div>
      <form>

        <div>
          <div>
            <mat-form-field style="width: 50%;">
              <input  matInput placeholder="Montant" type="text" value=""
                      [formControl]="form.controls['amount']" class="example-right-align"
                      appCurrencyFormatDirective appRegexpInputCheck [inputRegexp]="'[0-9]'" (change)="isAmountValid($event)">                     
              <span matSuffix>.00</span>
              <mat-error *ngIf="form.controls['amount'].hasError('required') && form.controls['amount'].touched">
                Ce champ est requis
              </mat-error>
              <mat-error *ngIf="form.controls['amount'].touched && form.controls['amount'].hasError('notValid')">
                Le montant doit être un multiple de 1000
              </mat-error>
              <mat-error *ngIf="form.controls['amount'].hasError('invalidAmount') && form.controls['amount'].touched">
                {{ "Montant invalide, le montant minimal pour éffectuer cette opération est de "  }} {{minimumOperationAmount}}
              </mat-error>
              <mat-error *ngIf="form.controls['amount'].hasError('incorrectAmount') && form.controls['amount'].touched">
               Le montant doit être multiple de 1000
              </mat-error>
            </mat-form-field>
          </div>
          <div style="width: 100%;">
            <mat-form-field style="width: 50%;">
              <input type="tel" matInput placeholder="Code confidentiel" value=""
                     [formControl]="form.controls['code']" #password style="-webkit-text-security:disc;">
              <mat-error *ngIf="form.controls['code'].hasError('required') && form.controls['code'].touched">
                Ce champ est requis
              </mat-error>
              <mat-error *ngIf="form.controls['code'].hasError('minlength')
                && form.controls['code'].touched">
                Le code doit être de 4 caractères.
              </mat-error>
              <mat-error *ngIf="form.controls['code'].hasError('pattern') || form.controls['code'].touched">
                  La valeur entré doit être numérique
              </mat-error>
            </mat-form-field>
          </div>
          <div style="width: 100%;">
            <mat-form-field style="width: 50%;">
              <input type="tel" matInput placeholder="Confirmer le code" value=""
                     [formControl]="form.controls['confirm']" #confirmPassword style="-webkit-text-security:disc;">
              <mat-error *ngIf="form.controls['confirm'].hasError('required') && form.controls['confirm'].touched">
                Ce champ est requis
              </mat-error>
              <mat-error *ngIf="form.controls['confirm'].hasError('MatchPassword') && form.controls['confirm'].touched">
                Les codes doivent correspondre
              </mat-error>
              <mat-error *ngIf="form.controls['confirm'].hasError('pattern') || form.controls['confirm'].touched">
                La valeur entré doit être numérique
            </mat-error>
            </mat-form-field>
          </div>
          <div style="max-width: 50%;">
            <app-operation-reason 
              [operationReasonFormControl]="form.controls['reason']"
              [placeholder]="'Raison'"
              (onOperationReasonSelected)="onReasonSelected($event)"
            ></app-operation-reason>
          </div>
          <button [disabled]="!form.valid" mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
          matRippleCentered="true" [appAppColor]="{default : ['background-color']}" (click)="onSubmit()">
         <mat-progress-spinner mode="indeterminate" class="primary" [diameter]="25" [strokeWidth]="2" *ngIf="loading" >
         </mat-progress-spinner>
         Envoyer
       </button>
          <mat-toolbar class="transfert-footer">
           
          </mat-toolbar>
        </div>

       

      </form>
    </div>
  </div>
</div>

