import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { DisconnectOperationsService } from '../disconnect-operations.service';
import { OperationResultDialogComponent } from '../../shared/components/operation-result-dialog/operation-result-dialog.component';
import { CustomerDialogComponent } from '../../shared/components/customer-dialog/customer-dialog.component';
import { OperationAmountValidators } from '../../shared/validators/operation-amount.validators';
import { GlobalVariable } from '../../global';
import { Utils } from '../../shared/utils/Utilis';

@Component({
  selector: 'app-disconnect-transfer',
  templateUrl: './disconnect-transfer.component.html',
  styleUrls: ['./disconnect-transfer.component.css']
})
export class DisconnectTransferComponent implements OnInit {

  form: FormGroup;
  public reasons = GlobalVariable.TRANSFER_REASONS;
  loadingCalculateReceivingAmount: boolean = false;
  operationCost: any = {
    cost: 0,
    targetMonetaryArea: {
      currencyShortName: ''
    }
  };

  loading: boolean = false;
  loadingReceiver: boolean = false;
  dialogRef: MatDialogRef<OperationResultDialogComponent> | null;
  config = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '350px',
    height: '',
    padding: '20px',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      message: '',
      correctPlatform: null
    }
  };

  customerDialogRef: MatDialogRef<CustomerDialogComponent> | null;
  customerDialogConfig = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '350px',
    height: '',
    padding: '20px',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      phone: '',
      firstname: '',
      lastname: '',
    }
  };
  minimumOperationAmount = GlobalVariable.MINIMUM_TRANSFER_OPERATION_AMOUNT;
  senderTelCountry: any;
  recieverTelCountry: any;
  mask = {mask: [/[0-9]/, /[0-9]/,/[0-9]/,' ',/[0-9]/, /[0-9]/,/[0-9]/,' ',/[0-9]/, /[0-9]/,/[0-9]/,' ',/[0-9]/, /[0-9]/,/[0-9]/,' ',/[0-9]/, /[0-9]/,/[0-9]/,' ',/[0-9]/, /[0-9]/,/[0-9]/]};
  constructor(private fb: FormBuilder,
    public dialog: MatDialog,
    private http: HttpClient,
    public utils: Utils,
    private disconnectOperationService: DisconnectOperationsService) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      amount: [null,
        Validators.compose([Validators.required, OperationAmountValidators.amountCheck(this.minimumOperationAmount)]),
        Validators.composeAsync([])
      ],
      pin: [null,
        Validators.compose([Validators.required]),
        Validators.composeAsync([])
      ],
      reason: [null,
        Validators.compose([]),
        Validators.composeAsync([])
      ],
      description: [null,
        Validators.compose([]),
        Validators.composeAsync([])
      ],
      customer: this.fb.group({
        phone: [null,
          Validators.compose([Validators.required]),
          Validators.composeAsync([])
        ]
      }),
      receiver: this.fb.group({
        phone: [null,
          Validators.compose([Validators.required]),
          Validators.composeAsync([])
        ],
        firstname: [null,
          Validators.compose([Validators.required]),
          Validators.composeAsync([])
        ],
        lastname: [null,
          Validators.compose([Validators.required]),
          Validators.composeAsync([])
        ]
      })
    });
  }
  /*
  customerPhoneHasError(e) {
    if (!e) {
      (<FormGroup>this.form.controls['customer']).controls['phone'].setErrors({'badPhone': true});
    }
  }

  receiverPhoneHasError(e) {
    if (!e) {
      (<FormGroup>this.form.controls['receiver']).controls['phone'].setErrors({'badPhone': true});
    }
  }*/

  customerPhoneHasError(e) {
    if (!e) {
      if (this.senderTelCountry) {
        if (this.senderTelCountry.dialCode !== '237') {
          (<FormGroup>this.form.controls['customer']).controls['phone'].setErrors({ 'badPhone': true });
        } else {
          const phone = (<FormGroup>this.form.controls['customer']).controls['phone'].value;
          if (phone !== null && phone !== undefined) {
            if (phone.indexOf('+') === -1) {
              (<FormGroup>this.form.controls['customer']).controls['phone'].setValue('+' + this.senderTelCountry.dialCode + phone);
            }
          }
        }
      } else {
        (<FormGroup>this.form.controls['customer']).controls['phone'].setErrors({ 'badPhone': true });
      }
    }
  }
  receiverPhoneHasError(e) {
    if (!e) {
      if (this.recieverTelCountry) {
        if (this.recieverTelCountry.dialCode !== '237') {
          (<FormGroup>this.form.controls['receiver']).controls['phone'].setErrors({ 'badPhone': true });
        } else {
          const phone = (<FormGroup>this.form.controls['receiver']).controls['phone'].value;
          if (phone !== null && phone !== undefined) {
            if (phone.indexOf('+') === -1) {
              (<FormGroup>this.form.controls['receiver']).controls['phone'].setValue('+' + this.recieverTelCountry.dialCode + phone);
            }
          }
        }
      } else {
        (<FormGroup>this.form.controls['receiver']).controls['phone'].setErrors({ 'badPhone': true });
      }
    }
  }
  onCustomerCountryChange(e) {
    this.senderTelCountry = e;
  }
  onRecieverCountryChange(e) {
    this.recieverTelCountry = e;
  }

  onReceiverPhoneBlur() {
    if ((<FormGroup>this.form.controls['receiver']).controls['phone'].valid) {
      this.loadingReceiver = true;
      this.form.disable();
      const customerPhone = (<FormGroup>this.form.controls['receiver']).controls['phone'].value;
      this.disconnectOperationService.checkCustomer(customerPhone).subscribe(
        (response) => {
          this.loadingReceiver = false;
          (<FormGroup>this.form.controls['receiver']).controls['firstname'].setValue('  ');
          (<FormGroup>this.form.controls['receiver']).controls['lastname'].setValue('  ');
          this.form.enable();
        },
        (error) => {
          if (error.status === 404) {
            this.customerDialogConfig.data = (<FormGroup>this.form.controls['receiver']).value;
            this.customerDialogRef = this.dialog.open(CustomerDialogComponent, this.customerDialogConfig);
            this.customerDialogRef.afterClosed().subscribe((result) => {
              (<FormGroup>this.form.controls['receiver']).patchValue(result);
              this.loadingReceiver = false;
              this.form.enable();
            });
            this.loadingReceiver = false;
            this.form.enable();
          }
        }
      );
    }
  }

  getCustomerNumber(e) {
    (<FormGroup>this.form.controls['customer']).controls['phone'].setValue(e);
  }

  getReceiverNumber(e) {
    (<FormGroup>this.form.controls['receiver']).controls['phone'].setValue(e);
  }

  onSubmit() {
    this.loading = true;
    this.disconnectOperationService.transfert(this.form.value).subscribe(
      (response: any) => {
        this.config.data.message = 'Opération effectuée, le code de confirmation est : ' + response.confirmationCode;
        this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
        this.dialogRef.afterClosed().subscribe((result) => {
          this.form.reset();
          this.operationCost.cost = 0;
          this.operationCost.targetMonetaryArea.currencyShortName = '';
          this.loading = false;
        });
      },
      (error) => {
        let message = null;
        let correctPlatform = null;
        const errorMessage = error.error.message;
        if (errorMessage === 'INVALID PIN') {
          message = 'Pin invalide';
        } else if (errorMessage === 'INSUFFICIENT BALANCE') {
          message = 'Solde insuffisant pour éffectuer cette opération';
        } else if (errorMessage === 'SAME PHONE NUMBER') {
          message = 'Le transfert vers votre propre numéro n\'est pas possible';
        } else if (errorMessage === 'RESSOURCE_NOT_FOUND') {
          message = 'L\'expéditeur du tranfert n\'a pas été trouvé';
        } else if (errorMessage === 'THIS OPERATION IS DONE ON A PLATFORM WHICH IS NOT YOUR\'S') {
          message = 'Vous tentez d\'effectuer une opération sur une plateforme qui n\'est pas la votre';
          if (error.error.platform) {
            correctPlatform = error.error.platform;
          }
        } else if (errorMessage === 'ACCOUNT BLOCKED') {
          message = 'Votre compte a été bloqué';
        } else {
          message = errorMessage;
        }
        this.config.data.message = message;
        this.config.data.correctPlatform = correctPlatform;
        this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
        this.dialogRef.afterClosed().subscribe((result) => {
          this.loading = false;
          this.form.reset();
          this.operationCost = {
            cost: 0,
            targetMonetaryArea: {
              currencyShortName: ''
            }
          };
        });
      }
    );
  }

  onCalculateReceivingAmount() {

    this.loadingCalculateReceivingAmount = true;

    const transfert = this.form.value;

    const body = {
      amount: transfert.amount,
      operationType: 'TRANSFERT',
      sourceAccountType: 'CLASSIC',
      targetAccountType: 'CLASSIC',
      userCollect: null,
      sourceCountry: this.GetCountryCodeFromPhone(transfert.customer.phone),
      targetCountry: this.GetCountryCodeFromPhone(transfert.receiver.phone)
    };

    this.disconnectOperationService.calculateOperationCost(body).subscribe(
      (response: any) => {
        this.operationCost = response;
        this.loadingCalculateReceivingAmount = false;
      },
      (error) => {
        // console.log(error);
        this.loadingCalculateReceivingAmount = false;
      }
    );
  }

  private GetCountryCodeFromPhone(phone) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const countryPhoneCode = phoneUtil.parse(phone).getCountryCode();
    return phoneUtil.getRegionCodeForCountryCode(countryPhoneCode);
  }
  onReasonSelected(e) {
    this.form.controls['reason'].setValue(e);
  }
}
