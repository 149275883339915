<div class="business-infos-content">
  <h1>Informations du compte marchand</h1>
  <form action="">
    <mat-form-field>
      <input matInput type="text" placeholder="Nom de la compagnie">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="Adresse de la compagnie">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="RCCM de la compagnie">
    </mat-form-field>
    <button mat-raised-button class="my-background-color">Valider</button>
  </form>
</div>
