import {Injectable} from '@angular/core';
import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';

import {UserService} from '../user/user.service';
import {SnackMessageService} from '../snack-messages/snack-message.service';
import {SnackMessage} from '../snack-messages/snack-message.model';
import {GlobalVariable} from '../../global';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private userService: UserService,
              private snackMessageService: SnackMessageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.userService.userExists() && req.url.includes(GlobalVariable.BASE_API_URL)
      && !req.url.includes(GlobalVariable.BASE_API_URL + '/public') ) {

      const token: string = this.userService.getToken();
      const authReq = req.clone({headers: req.headers.set('X-Auth-Token', token)});

      return next.handle(authReq).do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {

          if (event.status > 200 || req.method === 'PUT') {

            const snackMessage = new SnackMessage('success', 'SUCCESS');

            switch (event.status) {
              case 200 :
                snackMessage.message = 'RESOURCE UPDATED';
                break;
              case 201 :
                snackMessage.message = 'RESOURCE CREATED';
                break;
              case 204 :
                snackMessage.message = 'RESOURCE DELETED';
                snackMessage.type = 'danger';
                break;
            }
            this.snackMessageService.newMessage.next(snackMessage);

          }

        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.userService.currentUser.next(null);
            this.snackMessageService.newMessage.next(new SnackMessage('danger', 'INVALID AUTHENTIFICATION'));
          }
        }
      });
    } else {
      return next.handle(req);
    }

  }

}
