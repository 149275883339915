import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';

import {UserOperationsService} from '../user-operations.service';
import {OperationResultDialogComponent} from '../../shared/components/operation-result-dialog/operation-result-dialog.component';
import {ConfirmOperationComponent} from './confirm-operation/confirm-operation.component';
import {UserSubscription} from '../../shared/user/model/user-subscription.model';
import {ProfileService} from '../../shared/profile/profile.service';
import {Operation} from '../operation.model';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.css']
})
export class OperationsComponent implements OnInit {

  selectedOperation: Subject<any> = new Subject<any>();
  operationSelected: Operation = null;
  loadingCancel: boolean = false;
  operationSelectedFromDashboard: any;
  profile: UserSubscription;

  dialogRef: MatDialogRef<OperationResultDialogComponent> | null;
  config = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '200px !important',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      message: ''
    }
  };

  confirmOperationDialogRef: MatDialogRef<ConfirmOperationComponent> | null;
  configConfirmOperation = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '300px',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      operation: null
    }
  };


  constructor(private userOperationsService: UserOperationsService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private profileService: ProfileService) {
  }

  ngOnInit() {
   this.operationSelectedFromDashboard = this.userOperationsService.operationSelected;
    this.profile = this.profileService.getProfileData();

    this.profileService.getProfile().subscribe(
      (profile: UserSubscription) => {
        this.profile = profile;
      }
    );

    this.profileService.profile.subscribe(
      (profile: UserSubscription) => {
        this.profile = profile;
      }
    );

    this.selectedOperation.subscribe(
      (operation) => {
        this.operationSelected = operation;
      }
    );
  }
  canCancelOperation() {
    return (this.operationSelected.account.accountNumber === this.profile.phone) &&
      (this.operationSelected.agency === null || this.operationSelected.agency === undefined);
  }

  canConfirmOperation() {
    return this.operationSelected.receiverAccount.accountNumber === this.profile.phone && this.operationSelected.receiverAccount !== null;
  }

  onCancel() {
    this.loadingCancel = true;
    this.userOperationsService.cancelOperation(this.operationSelected).subscribe(
      (response) => {
        this.selectedOperation.next(null);
        this.config.data.message = 'Opération annulée';
        this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
        this.dialogRef.afterClosed().subscribe((result) => {
          this.loadingCancel = false;
        });
      },
      (error) => {
        this.config.data.message = 'Echec de l\'annulation';
      this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
      this.dialogRef.afterClosed().subscribe((result) => {
        this.loadingCancel = false;
      });
      }
    );
  }
  onCancelDebitATM(){
    this.loadingCancel = true;
    this.userOperationsService.cancelDebitATM(this.operationSelected).subscribe(res => {
      this.selectedOperation.next(null);
      this.config.data.message = 'Opération annulée';
      this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
      this.dialogRef.afterClosed().subscribe((result) => {
        this.loadingCancel = false;
      });
    }, err => {
      this.config.data.message = 'Echec de l\'annulation';
      this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
      this.dialogRef.afterClosed().subscribe((result) => {
        this.loadingCancel = false;
      });
    })
  }
  onCancelDebit() {
    this.loadingCancel = true;
    this.userOperationsService.cancelDebitOperation(this.operationSelected).subscribe(
      (response) => {
        this.selectedOperation.next(null);
        this.config.data.message = 'Opération annulée';
        this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
        this.dialogRef.afterClosed().subscribe((result) => {
          this.loadingCancel = false;
        });
      },
      (error) => {
        this.config.data.message = 'Echec de l\'annulation';
        this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
        this.dialogRef.afterClosed().subscribe((result) => {
          this.loadingCancel = false;
        });
      }
    );
  }

  onConfirm() {
    this.configConfirmOperation.data.operation = this.operationSelected;
    this.confirmOperationDialogRef = this.dialog.open(ConfirmOperationComponent, this.configConfirmOperation);
    this.confirmOperationDialogRef.afterClosed().subscribe((result) => {
      this.selectedOperation.next(null);
      this.loadingCancel = false;
    });
  }

}
