import {Subject} from 'rxjs';
import {Router} from '@angular/router';

import { Injectable } from '@angular/core';
import {User} from './model/user.model';

@Injectable()
export class UserService {

  public currentUser: Subject<User> = new Subject<User>();
  private currentUserData: User;

  showLastConnectionInfo: boolean = true;

  constructor(private router: Router) {
    this.currentUser.subscribe(
      (user: User) => {
        this.currentUserData = user;
        if (user === null) {

        }
      }
    );
  }

  toggleShowLastConneciontInfo() {
    this.showLastConnectionInfo = false;
  }

  userExists(): boolean {
    return (this.currentUserData !== null);
  }

  getToken(): string {
    return this.currentUserData.authToken.value;
  }
  getCurrentUser() {
    return this.currentUserData;
  }

}
