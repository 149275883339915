import { Component, OnInit, ViewChild } from '@angular/core';
import { UserOperationsService } from '../user-operations.service';
import { GlobalVariable } from '../../global';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';

import {DataSource} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import {RequestParameters} from '../../shared/models/request-parameters/parameter.model';
import {Subject} from 'rxjs';

import { PermanentTransfertFormDialogComponent } from './permanent-transfert-form-dialog/permanent-transfert-form-dialog.component';

export class PermanentTransfertOperationsDataSource extends DataSource<any> {

  constructor(private subject: BehaviorSubject<any[]>, public paginator: MatPaginator) {
    super ();
  }

  connect (): Observable<any[]> {
    return this.subject.asObservable();
  }

  disconnect (): void {
  }
}

@Component({
  selector: 'app-permanent-transfert',
  templateUrl: './permanent-transfert.component.html',
  styleUrls: ['./permanent-transfert.component.css']
})
export class PermanentTransfertComponent implements OnInit {

  displayedColumns = ['reference', 'recipientAccount', 'startOperationDate', 
  'amount', 'frequency', 'iteration', 'actions'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: PermanentTransfertOperationsDataSource | null;
  dataSubject = new BehaviorSubject<any[]>([]);

  parameters = new RequestParameters();

  permanentTransfertDialogRef: MatDialogRef<PermanentTransfertFormDialogComponent> | null;
  permanentTransfertDialogConfig = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '50%',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      permanentTransfert: null,
      action: null,
    }
  };

  loading: boolean  = false;

  constructor(private userOperationsService: UserOperationsService,
    public dialog: MatDialog) {
    this.updateOperations();
  }

  ngOnInit() {
    this.dataSource = new PermanentTransfertOperationsDataSource(this.dataSubject, this.paginator);
  }

  updateOperations() {
    this.userOperationsService.getPermenentTransfertOperations(this.parameters).subscribe(
      (response: any) => {
        this.parameters.setData(response.parameters);
        this.dataSubject.next(response.result);
      }
    );
  }

  onAddPermanentTransert() {
    this.permanentTransfertDialogConfig.data.action = 'add';
    this.permanentTransfertDialogConfig.data.permanentTransfert = null;
    this.permanentTransfertDialogRef = this.dialog.open(PermanentTransfertFormDialogComponent, this.permanentTransfertDialogConfig);
    this.permanentTransfertDialogRef.afterClosed().subscribe((result) => {
        if (result !== null && result !== undefined) {
          this.updateOperations();
        }
    });
  }

  onEditPermanentTransert(permanentTransfert) {
    this.permanentTransfertDialogConfig.data.action = 'edit';
    this.permanentTransfertDialogConfig.data.permanentTransfert = permanentTransfert;
    this.permanentTransfertDialogRef = this.dialog.open(PermanentTransfertFormDialogComponent, this.permanentTransfertDialogConfig);
    this.permanentTransfertDialogRef.afterClosed().subscribe((result) => {
        if (result !== null && result !== undefined) {
          this.updateOperations();
        }
    });
  }

  onDeletePermanentTransert(permanentTransfert) {
    this.permanentTransfertDialogConfig.data.action = 'delete';
    this.permanentTransfertDialogConfig.data.permanentTransfert = permanentTransfert;
    this.permanentTransfertDialogRef = this.dialog.open(PermanentTransfertFormDialogComponent, this.permanentTransfertDialogConfig);
    this.permanentTransfertDialogRef.afterClosed().subscribe((result) => {
        if (result !== null && result !== undefined) {
          this.updateOperations();
        }
    });
  }
  onPausePermanentTransfert(data){
   this.userOperationsService.pausePermanentTransfert(data).subscribe(res => {
    this.updateOperations();
   })
    
  }
  onRestartPermanentTransfert(data){
    this.userOperationsService.restartPermanentTransfert(data).subscribe(res => {
     this.updateOperations();
    })
     
   }

}
