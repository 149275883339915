import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';

import {RequestParameters} from '../../models/request-parameters/parameter.model';
import {FormControl} from '@angular/forms';
import {City} from './city.model';
import {ProfileService} from '../../profile/profile.service';

@Component({
  selector: 'app-cities-autocomplete',
  templateUrl: './cities-autocomplete.component.html',
  styleUrls: ['./cities-autocomplete.component.scss']
})
export class CitiesAutocompleteComponent implements OnInit {

  @Input() cityFormControl: FormControl;
  @Input() placeholder: string;
  @Output() onCitySelected: EventEmitter<City> = new EventEmitter<City>();

  cities: Observable<any> = new Observable<any>();
  cityLoading: boolean = true;
  cityParameters: RequestParameters = new RequestParameters();

  constructor(private profileService: ProfileService) {
    this.updateCities();
  }

  ngOnInit() {
  }

  onSelectCity() {
    this.onCitySelected.emit(this.cityFormControl.value);
  }

  displayCities (city: City) {
    if (city !== null) {
      return city.name;
    }
  }

  filterCities (filterInput: string = '') {
    if (this.cityParameters.searchString !== filterInput) {
      this.cityParameters.searchString = filterInput;
      this.updateCities();
    }
  }

  updateCities () {
    this.cityLoading = true;
    this.cities = this.profileService.getCities(this.cityParameters)
      .map(
        (response: any) => {
          this.cityLoading = false;
          return response.result;
        }
      );
  }
}
