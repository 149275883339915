<div class="inner-disconnect-container-content">
  <div class="app-disconnect-transfer-container">
    <div class="transfert-operation-title">
      <mat-toolbar class="page-title">
        <span [appAppColor]="{default : ['color']}">Transferer</span>
      </mat-toolbar>
    </div>
    <form [formGroup]="form" class="disconnect-transfer-form" (submit)="onSubmit()">
      <div class="transfert-form-elements">
        <div class="disconnect-transfer-input-item">
          <mat-form-field class="tel-input-form">
            <input matInput type="text"
                   placeholder="Téléphone de l'expéditeur"
                   [formControl]="form.controls['customer']['controls']['phone']"
                   ng2TelInput
                   (countryChange)="onCustomerCountryChange($event)"
                   (ng2TelOutput)="getCustomerNumber($event)"
                   [ng2TelInputOptions]="utils.selectorOptions"
                   (hasError)="customerPhoneHasError($event)"/>
            <mat-error *ngIf="form.controls['customer']['controls']['phone'].hasError('required') && form.controls['customer']['controls']['phone'].touched">
              Ce champ est requis
            </mat-error>
            <mat-error *ngIf="form.controls['customer']['controls']['phone'].hasError('badPhone') && form.controls['customer']['controls']['phone'].touched">
              Numéro de téléphone invalide
            </mat-error>
          </mat-form-field>
        </div>
        <div class="disconnect-transfer-input-item">
          <mat-form-field>
            <input type="password" matInput placeholder="PIN"
                   [formControl]="form.controls['pin']"
                   maxlength="6" required >
            <mat-error *ngIf="form.controls['pin'].hasError('required') && form.controls['pin'].touched">
              Ce champ est requis
            </mat-error>
          </mat-form-field>
        </div>
        <div class="disconnect-transfer-input-item">
          <mat-form-field class="tel-input-form">
            <input matInput type="text"
                   placeholder="Téléphone du destinataire"
                   (blur)="onReceiverPhoneBlur()"
                   [formControl]="form.controls['receiver']['controls']['phone']"
                   ng2TelInput
                   (countryChange)="onRecieverCountryChange($event)"
                   (ng2TelOutput)="getReceiverNumber($event)"
                   [ng2TelInputOptions]="utils.selectorOptions"
                   (hasError)="receiverPhoneHasError($event)" />
            <mat-error *ngIf="form.controls['receiver']['controls']['phone'].hasError('required') && form.controls['receiver']['controls']['phone'].touched">
              Ce champ est requis
            </mat-error>
            <mat-error *ngIf="form.controls['receiver']['controls']['phone'].hasError('badPhone') && form.controls['receiver']['controls']['phone'].touched">
              Numéro de téléphone invalide
            </mat-error>
            <mat-progress-spinner mode="indeterminate" class="primary" [diameter]="25" [strokeWidth]="2" *ngIf="loadingReceiver">
            </mat-progress-spinner>
          </mat-form-field>
        </div>

        <div class="disconnect-transfer-input-item">
          <mat-form-field>
            <input matInput
                   [formControl]="form.controls['amount']" 
                   appCurrencyFormatDirective
                   appRegexpInputCheck [inputRegexp]="'[0-9]'" 
                   placeholder="Montant à envoyer" type="text"
                   value="" class="example-left-align" required>
            <span matSuffix>.00</span>
            <mat-error *ngIf="form.controls['amount'].hasError('required') && form.controls['amount'].touched">
              Ce champ est requis
            </mat-error>
            <mat-error *ngIf="form.controls['amount'].hasError('invalidAmount') && form.controls['amount'].touched">
              {{ "Montant invalide, le montant minimal pour éffectuer cette opération est de "  }} {{minimumOperationAmount}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="disconnect-transfer-input-item1">
          <app-operation-reason
            [operationReasonFormControl]="form.controls['reason']"
            [placeholder]="'Motif'"
            (onOperationReasonSelected)="onReasonSelected($event)"
          ></app-operation-reason>
        </div>
        <div class="disconnect-transfer-input-item1">
          <mat-form-field class="example-full-width">
            <textarea matInput maxlength="250"
                      [formControl]="form.controls['description']"
                      placeholder="Description" #transfertDescription></textarea>
            <mat-error *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched">
              Ce champ est requis
            </mat-error>
            <mat-hint align="end">{{transfertDescription.value.length}} / 250</mat-hint>
          </mat-form-field>
        </div>

        <div class="disconnect-transfer-input-item1">
          <ul>
            <li>
              <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                      matRippleCentered="true" [appAppColor]="{default : ['background-color']}"
                      [disabled]="!form.valid || loadingCalculateReceivingAmount "
                      (click)="onCalculateReceivingAmount()" type="button" >
                <mat-progress-spinner mode="indeterminate" class="primary" [diameter]="25" [strokeWidth]="2"
                                      *ngIf="loadingCalculateReceivingAmount" >
                </mat-progress-spinner>
                Coût de transfert</button>
            </li>
            <li [appAppColor]="{default : ['color']}">
              {{ operationCost.cost + '' + operationCost.targetMonetaryArea.currencyShortName }}
            </li>
          </ul>
        </div>

        <mat-toolbar class="disconnect-transfer-footer">
          <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                  matRippleCentered="true" [appAppColor]="{default : ['background-color']}" (click)="onSubmit()"
                  [disabled]="!form.valid || loading" >
            <mat-progress-spinner mode="indeterminate" class="primary" [diameter]="25" [strokeWidth]="2" *ngIf="loading" >
            </mat-progress-spinner>
            Envoyer
          </button>
        </mat-toolbar>

      </div>
    </form>
  </div>
</div>
