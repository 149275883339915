import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-to-pay',
  templateUrl: './to-pay.component.html',
  styleUrls: ['./to-pay.component.css']
})
export class ToPayComponent implements OnInit {

  refs = [
    {value: 'ref-0', viewValue: 'Ref 2001'},
    {value: 'ref-1', viewValue: 'Ref 2002'},
    {value: 'ref-2', viewValue: 'Ref 2003'},
    {value: 'ref-2', viewValue: 'Ref 2004'}
  ];

  constructor() { }

  ngOnInit() {
  }

}
