import { Component, OnInit, Inject } from '@angular/core';
import {FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {
  PermanentTransfert
} from '../permanent-transfert.model';

import { Utils } from '../../../shared/utils/Utilis';

import { PhoneNumberUtil } from 'google-libphonenumber';
import {PlatformConfig} from '../../../app-loader/platform-config';
import {ConfigLoaderService} from '../../../app-loader/config-loader.service';

import { ProfileService } from '../../../shared/profile/profile.service';
import { UserSubscription } from '../../../shared/user/model/user-subscription.model';

import { UserOperationsService } from '../../user-operations.service';

import { OperationResultDialogComponent } from '../../../shared/components/operation-result-dialog/operation-result-dialog.component';
import {Observable} from 'rxjs/Observable';
import { CustomerDetailsDialogComponent } from '../../../shared/components/customer-details-dialog/customer-details-dialog.component';

@Component({
  selector: 'app-permanent-transfert-form-dialog',
  templateUrl: './permanent-transfert-form-dialog.component.html',
  styleUrls: ['./permanent-transfert-form-dialog.component.css']
})
export class PermanentTransfertFormDialogComponent implements OnInit {
  form: FormGroup;
  public action: string = 'add';
  public permanentTransfert:PermanentTransfert;
  minimumOperationAmount = 10;
  recieverTelCountry: any;
  frequencies = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
  ];
  periods = [
    {
      title: "Jours",
      value:"DAY"
    },
    {
      title: "Semaine",
      value:"WEEK"
    },
    {
      title: "Mois",
      value:"MONTH"
    }
  ];
  profile: UserSubscription = null;
  platformConfig: PlatformConfig;
  loading: boolean  = false;
  loadingReceiver: boolean = false;
  today = new Date();
  customerDetailsDialogRef: MatDialogRef<CustomerDetailsDialogComponent> | null;
  customerDetailsDialogConfig:MatDialogConfig = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '50%',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      phone: '',
      firstname: '',
      lastname: '',
    }
  };
  operationDialogRef: MatDialogRef<OperationResultDialogComponent> | null;
  operationDialogConfig = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '440px',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      message: ''
    }
  };

  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PermanentTransfertFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,  public utils: Utils,
    public configLoaderService: ConfigLoaderService, 
    private userOperationsService: UserOperationsService,
    public dialog: MatDialog,
    private profileService: ProfileService,
     ) {
      this.profileService.getProfile().subscribe(
        (profile: UserSubscription) => {
          this.profile = profile;
        }
      );
  
      this.platformConfig = this.configLoaderService.getPlatformConfig();
      
      
      this.permanentTransfert = this.data.permanentTransfert;
      this.action = this.data.action;
   }

  ngOnInit() {
   
    this.form = this.fb.group ( {
      reference: [null ,
        Validators.compose ( []),
        Validators.composeAsync([])
      ],
      accountNumber: [null ,
        Validators.compose ( [ Validators.required ]),
        Validators.composeAsync([])
      ],
      amount: [null ,
        Validators.compose ( [ Validators.required ]),
        Validators.composeAsync([])
      ],
      frequency: [null ,
        Validators.compose ( [ Validators.required ]),
        Validators.composeAsync([])
      ],
      period: [null ,
        Validators.compose ( [ Validators.required ]),
        Validators.composeAsync([])
      ],
      nextExecutionDate: [null ,
        Validators.compose ( [ Validators.required ]),
        Validators.composeAsync([])
      ],
      title: [null ,
        Validators.compose ( [ Validators.required ]),
        Validators.composeAsync([])
      ],
      description: [null ,
        Validators.compose ( [ Validators.required ]),
        Validators.composeAsync([])
      ],
    });

    if (this.permanentTransfert !== null  && this.permanentTransfert !== undefined) {
    
      this.form.patchValue(this.permanentTransfert);
      this.form.controls['accountNumber'].setValue(this.permanentTransfert.recipient.accountNumber);
    }
    

    if (this.action === 'delete') {
      this.form.disable();
      // this.form.patchValue(this.permanentTransfert);
    }
    
    
    
    // this.profile = this.profileService.getProfileData();
    // console.log(this.profile);
    // if (this.profile  !== null && this.profile !== undefined) {
    //   this.form.controls['accountNumber'].setValue(this.profile.phone);
    // }

  }

  onReceiverPhoneBlur() {
    if ((this.form.controls['accountNumber']).valid) {
      this.loadingReceiver = true;
      this.form.disable();
      const customerPhone = (this.form.controls['accountNumber']).value;
      this.userOperationsService.checkCustomer(customerPhone).subscribe(
        (response) => {
          
          this.loadingReceiver = false;
          this.form.enable();
          this.customerDetailsDialogConfig.data.phone = this.form.value.accountNumber;
          this.customerDetailsDialogRef = this.dialog.open(CustomerDetailsDialogComponent, this.customerDetailsDialogConfig);
        },
        (error) => {
          if (error.status === 404) {
            this.form.enable();
            this.form.controls['accountNumber'].setErrors({'nonExistentUser': true});
            this.loadingReceiver = false;
            
          }
        }
      );
    }
  }

  getReceiverNumber(e) {
    this.form.controls['accountNumber'].setValue(e);
  }
  receiverPhoneHasError(e) {
    if (!e) {
      if (this.recieverTelCountry) {
        if (this.recieverTelCountry.dialCode !== '237') {
          this.form.controls['accountNumber'].setErrors({ 'badPhone': true });
        } else {
          const phone = this.form.controls['accountNumber'].value;
          if (phone !== null && phone !== undefined) {
            if (phone.indexOf('+') === -1) {
              this.form.controls['accountNumber'].setValue('+' + this.recieverTelCountry.dialCode + phone);
            }
          }
        }
      } else {
        this.form.controls['accountNumber'].setErrors({ 'badPhone': true });
      }
    }
  }
  onRecieverCountryChange(e) {
    this.recieverTelCountry = e;
  }

  getSubmitButton() {
    if (this.action === 'add' || this.action === 'edit') {
      return 'Valider';
    } else if (this.action === 'delete') {
      return 'Supprimer';
    }
  }

  onSubmit() {
    this.loading = true;
    this.form.disable();

    let requestObserver: Observable<any>;

    switch (this.action) {
      case 'add':
        requestObserver = this.userOperationsService.permanentTransfert(this.form.value);
        break;
      case 'edit':
        requestObserver = this.userOperationsService.updatePermanentTransfert(this.form.value);
        break;
      case 'delete':
        const dialog = this.dialog.open(ConfirmDialog, { width: '250px'});
        dialog.afterClosed().subscribe(rs => {
          console.log(rs);
          if(rs==="OUI"){
             this.userOperationsService.deletePermanentTransfert(this.form.value).subscribe(
              (response: any) => {
                this.operationDialogConfig.data.message = 'Opération effectuée avec succès.';
                this.operationDialogRef = this.dialog.open(OperationResultDialogComponent, this.operationDialogConfig);
                this.operationDialogRef.afterClosed().subscribe((result) => {
                  this.loading = false;
                  this.dialogRef.close(true);
                });
              },
              (error) => { 
      
                let message = null;
                const errorMessage = error.error.message;
                if (errorMessage === 'INSUFFICIENT BALANCE') {
                  message = 'Solde insuffisant pour éffectuer cette opération';
                } else if (errorMessage === 'TRANSFERRING TO YOUR OWN ACCOUNT IS NOT ALLOWED') {
                  message = 'Le transfert vers votre propre compte n\'est pas autorisé';
                }  else if (errorMessage === 'YOU CAN\'T DO THE TRANSFERT TO YOURSELF') {
                  message = 'Le transfert vers votre propre compte n\'est pas autorisé';
                } else {
                  message = errorMessage;
                }
                
                this.operationDialogConfig.data.message = message;
                this.operationDialogRef = this.dialog.open(OperationResultDialogComponent, this.operationDialogConfig);
                this.operationDialogRef.afterClosed().subscribe((result) => {
                  this.form.enable();
                  this.loading = false;
                });
      
              });;
          }else
          this.loading = false;
        })
        break;  
    }
    if(requestObserver !== null || requestObserver !== undefined)
    requestObserver.subscribe(
        (response: any) => {
          this.operationDialogConfig.data.message = 'Opération effectuée avec succès.';
          this.operationDialogRef = this.dialog.open(OperationResultDialogComponent, this.operationDialogConfig);
          this.operationDialogRef.afterClosed().subscribe((result) => {
            this.loading = false;
            this.dialogRef.close(true);
          });
        },
        (error) => { 

          let message = null;
          const errorMessage = error.error.message;
          if (errorMessage === 'INSUFFICIENT BALANCE') {
            message = 'Solde insuffisant pour éffectuer cette opération';
          } else if (errorMessage === 'TRANSFERRING TO YOUR OWN ACCOUNT IS NOT ALLOWED') {
            message = 'Le transfert vers votre propre compte n\'est pas autorisé';
          }  else if (errorMessage === 'YOU CAN\'T DO THE TRANSFERT TO YOURSELF') {
            message = 'Le transfert vers votre propre compte n\'est pas autorisé';
          } else {
            message = errorMessage;
          }
          
          this.operationDialogConfig.data.message = message;
          this.operationDialogRef = this.dialog.open(OperationResultDialogComponent, this.operationDialogConfig);
          this.operationDialogRef.afterClosed().subscribe((result) => {
            this.form.enable();
            this.loading = false;
          });

        });
  }

}
@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.html',
})
export class ConfirmDialog{
  constructor(public dialogRef: MatDialogRef<ConfirmDialog>) {}
  closeDialog() {
    this.dialogRef.close('OUI');
  }
}