import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../shared/auth/auth.service';
import {HttpClient} from '@angular/common/http';
import {Utils} from '../../shared/utils/Utilis';

@Component({
  selector: 'app-pin-forgot',
  templateUrl: './pin-forgot.component.html',
  styleUrls: ['./pin-forgot.component.css']
})
export class PinForgotComponent implements OnInit {

  form: FormGroup;

  public loading: boolean = false;
  public success: boolean = false;
  public formErrors: string[] = [];
  public pinGettingMethods: string[] = ['SMS', 'MAIL'];

  constructor(public dialogRef: MatDialogRef<PinForgotComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private authService: AuthService,
              public utils: Utils,
              private http: HttpClient) {

  }

  ngOnInit() {
    this.form = this.fb.group ( {
      phone: [null , Validators.compose ( [ Validators.required ] )] ,
      pinGettingMethod: ['SMS' , Validators.compose ( [ Validators.required ] )]
    });
  }

  phoneHasError(e) {
    if (!e) {
      this.form.controls['phone'].setErrors({'badPhone': true});
    }
  }

  getPhone(e) {
    this.form.controls['phone'].setValue(e);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    const data = this.form.value;
    this.form.disable();
    this.loading = true;
    this.authService.resetPin(data.phone).subscribe(
      (response) => {
        this.success = true;
        this.loading = false;
      },
      (error) => {
        this.formErrors.pop();
        if (error.content.message === 'RESSOURCE_NOT_FOUND') {
          this.formErrors.push('Numéro de téléphone non trouvé');
        } else {
          this.formErrors.push(error.content.message);
        }
        this.loading = false;
        this.form.enable();
      }
    );

  }

}
