<h2 mat-dialog-title>Récapitulatif</h2>
<div mat-dialog-content>
    <p>
      <b>Montant: </b> {{((data.amount | floorAmount) | currency: 'XOF':'') +" " +data.operationCost.targetMonetaryArea.currencyShortName}}  
    </p>
    <mat-divider></mat-divider>
    <p>
      <b>Coût opération:</b> {{data.operationCost.cost+" " +data.operationCost.targetMonetaryArea.currencyShortName}}
    </p>    
    <mat-divider></mat-divider>
    <p>
      <b>Numéro de téléphone:</b>{{data.receiver.phone}}
    </p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNonClick()" cdkFocusInitial>Annuler</button>
    <button mat-button (click)="onOuiClick()" cdkFocusInitial color="primary">Valider</button>
  </div>