
import {SnackMessage} from './snack-message.model';
import {Subject} from 'rxjs';

export class SnackMessageService {

  private messages: SnackMessage[] = [];
  public newMessage: Subject<SnackMessage> = new Subject<SnackMessage>();

  constructor() {
    this.newMessage.subscribe(
      (snackMessage: SnackMessage) => {
        this.messages.push(snackMessage);
      }
    );
  }

}
