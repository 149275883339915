import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild,
  OnChanges, SimpleChanges} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {FormControl} from '@angular/forms';


import {RequestParameters, FieldRequestParameters} from '../../models/request-parameters/parameter.model';

import {UserCollectAgency} from '../../models/users-collect/user-collect-agency.model';
import { UserCollect } from '../../models/users-collect/user-collect.model';
import { UsersCollectService } from '../../models/users-collect/users-collect.service';

@Component({
  selector: 'app-collect-agencies-autocomplete',
  templateUrl: './collect-agencies-autocomplete.component.html',
  styleUrls: ['./collect-agencies-autocomplete.component.scss']
})
export class CollectAgenciesAutocompleteComponent implements OnInit, OnChanges {

  @Input('collectAgencyFormControl') collectAgencyFormControl: FormControl;
  @Input('userCollect') userCollect: UserCollect;
  @Input('placeholder') placeholder: string;
  @Output() onCollectorAgencySelected: EventEmitter<UserCollectAgency> =
   new EventEmitter<UserCollectAgency>();

  collectAgencies: Observable<any> = new Observable<any>();
  agencyLoading: boolean = false;
  agencyParameters: RequestParameters = new RequestParameters();
  @ViewChild('autoCollectAgencies') autoCollectAgencies: ElementRef;
  constructor(private usersCollectService: UsersCollectService ) {
    this.updateAgency();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges ): void {
    if (changes.userCollect.currentValue) {
      this.updateAgency();
    }
  }

  onSelectAgency() {
    this.onCollectorAgencySelected.emit(this.collectAgencyFormControl.value);
  }


  displayAgency (agency: UserCollectAgency ) {
    if (agency !== null) {
      return agency.name;
    }
  }

  filterAgencies (filterInput: string = '') {
    console.log(filterInput);
    
    if (this.agencyParameters.searchString !== filterInput) {
      this.agencyParameters.searchString = filterInput;
      this.updateAgency();
    }else {
      console.log('filter input error');
      
    }
  }

  updateAgency() {
      if (this.userCollect) {
        this.agencyLoading = true;
        const userCollectField = new FieldRequestParameters('userCollect',
        this.userCollect.reference);
        this.agencyParameters.fields = [];
        this.agencyParameters.fields.push(userCollectField);
       this.collectAgencies = this.usersCollectService.getUserCollectAgencies(this.userCollect, this.agencyParameters)
          .map(
            (response: any) => {
              this.agencyLoading = false;
              return response.result;
            }
          );

      }  
  }

}
