<div class="tariff-content mat-elevation-z8">
  <form class="about-form">
    <mat-form-field class="price-full-width">
      <mat-select (selectionChange)="onSourceCountryChange($event)" placeholder="Pays source" [formControl]="countrySourceCtrl">
        <mat-option *ngFor="let tariff of tariffs" [value]="tariff.countryCode">
          {{ tariff.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="price-full-width">
      <mat-select (selectionChange)="onTargetCountryChange($event)" placeholder="Pays destination" [formControl]="countryDestCtrl">
        <mat-option *ngFor="let tariff of tariffs" [value]="tariff.countryCode">
          {{ tariff.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </form>
  <mat-table *ngIf="showOperationCost" #table [dataSource]="dtSource" class="tariff-table" style="margin-bottom: 50px">
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data</td>
    </tr>
    <ng-container matColumnDef="lowerAmount">
      <mat-header-cell [style.color]="platformConfig.platform.clientAppColor" *matHeaderCellDef class="table-title"> Paliers des montants </mat-header-cell>
      <span>Bonjour</span>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Paliers des montants :</span>
          {{element.lowerAmount + ' - ' + element.topAmount}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="cost">
      <mat-header-cell [style.color]="platformConfig.platform.clientAppColor" *matHeaderCellDef class="table-title"> Frais de transfert </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Frais de transfert :</span>
        {{element.billingType == "PALLET"? element.cost: element.cost + " %"}}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

  </mat-table>
</div>
