import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {throwError} from 'rxjs/internal/observable/throwError';
import {GlobalVariable} from '../../app/global';

@Injectable()
export class ContactService {
  constructor(private http: HttpClient) {
  }
  postContact (contactContent: any, url: any) {
    return this.http.post(GlobalVariable.BASE_API_URL + url, contactContent)
      .map(
        (response: HttpResponse<any>) => {
          return response;
        }
      ).catch(
        (error: HttpErrorResponse) => {
          return throwError({
            code: error.status,
            content: JSON.parse(error.error).message
          });
        }
      );
  }
}
