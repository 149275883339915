import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import {GlobalVariable} from '../../../global';


@Injectable()
export class PinChangeService {

  constructor(private http: HttpClient) {
  }

  changePin(body) {
    return this.http.put(GlobalVariable.BASE_API_URL + '/logged/customer/change-password',
      body
    ).map(
      (response: HttpResponse<any>) => {
        return response;
      }
    );
  }

}