<div class="operations-popup-one">
    <mat-card-content style="height: 90% !important;">
      <form [formGroup]="form" (ngSubmit)="onProductAdd()">
        <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10px" class="customer-form-content">
          <div fxFlex="20" class="user-popup-infos-left">
              <app-collect-products-autocomplete
                [collectProductFormControl]="form.controls['product']"
                [placeholder]="'Produit'"
                [userCollect]="userCollect"
                (onCollectProductSelected)="onCollectProductSelected($event)">
              </app-collect-products-autocomplete>
              <mat-error
                *ngIf="form.controls['product'].hasError('invalidLinkedEntity')
                && form.controls['product'].touched"
                class="mat-text-warn">
                {{ 'Vous devez selectionner une valeure de la liste.'}}
              </mat-error>
            <!-- <mat-form-field style="width: 100%">
              <mat-select  placeholder="Produit"
               [formControl]="form.controls['product']">
                <mat-option *ngFor="let product of products" [value]="product">
                  {{product.name}}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="form.controls['product'].hasError('required') 
                && form.controls['product'].touched"
                class="mat-text-warn">Ce champs est requis
              </mat-error>
            </mat-form-field> -->
          </div>
          <div fxFlex="20" class="user-popup-infos-left">
            <mat-form-field style="width: 100%">
              <input appCurrencyFormatDirective appRegexpInputCheck [inputRegexp]="'[0-9]'" value=""
              autocomplete="off" type="text" matInput placeholder="Montant" 
              [formControl]="form.controls['amount']">
              <span matSuffix>.00</span>
              <mat-error *ngIf="form.controls['amount'].hasError('required') 
              && form.controls['amount'].touched" class="mat-text-warn">
                Ce champs est requis</mat-error>
              <mat-error *ngIf="form.controls['amount'].hasError('invalidAmount') && form.controls['amount'].touched">
                {{ "Montant invalide, le montant minimum est " }} {{minimumOperationAmount}}
              </mat-error>

            </mat-form-field>
          </div>
          <div fxFlex="20" class="user-popup-infos-left">
            <button [disabled]="loading || !form.valid"
                   mat-raised-button type="submit" 
                   class="operations-form-submit collect-button">
             <mat-progress-spinner mode="indeterminate" class=""
                                   [diameter]="25" [strokeWidth]="2"
                                   *ngIf="loading" >
             </mat-progress-spinner>
              Valider
           </button>
          </div>
        </div>
      </form>
    <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10px" class="customer-form-content">
      <div fxFlex="100" class="user-popup-infos-left">
      <h2 *ngIf="!operationProducts.length">Pas de produits ajoutés</h2>
      
      <table *ngIf="operationProducts.length">
        <tr>
          <th>Produit</th>
          <th>Montant</th>
          <th>ACTIONS</th>
        </tr>
        <tr *ngFor="let opProduct of operationProducts">
          <td>{{opProduct.product.name}}</td>
          <td>{{opProduct.amount}}</td>
          <td>
            <button mat-icon-button
                mat-button-sm color="danger"
                class="mr-1"
                matTooltip="Supprimer"
                (click)="onDeleteOperationCollectProduct(opProduct)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>    
  </mat-card-content>
  <br>
  <br>
    <mat-card-actions>
      <button mat-raised-button color="accent" type="reset" (click)="dialogRef.close(null)" [disabled]="loading">
        <mat-icon>clear</mat-icon> <span class="button-hide-text">Annuler</span>
      </button>
      <button (click)="onSubmit()" mat-raised-button 
      [appAppColor]="{default : ['background-color']}" type="submit"
              [disabled]="!operationProducts.length" class="organization-submit">
        <mat-icon>done</mat-icon> <span class="button-hide-text">Valider</span>
      </button>
    </mat-card-actions>
</div>
