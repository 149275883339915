
import {AbstractControl} from '@angular/forms';

export class PasswordValidator {

  static MatchPassword(formControl: AbstractControl) {

    const password = formControl.get('plainPassword').get('first').value;
    const confirmPassword = formControl.get('plainPassword').get('second').value;
    if (password !== confirmPassword) {
      formControl.get('plainPassword').get('second').setErrors( {MatchPassword: true} );
    } else {
      return null;
    }

  }
  static ATMCodeMatch(formControl: AbstractControl) {

    const password = formControl.get('code').value;
    const confirmPassword = formControl.get('confirm').value;
    if (password !== confirmPassword) {
      formControl.get('confirm').setErrors( {MatchPassword: true} );
    } else {
      return null;
    }

  }

}
