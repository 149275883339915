<div class="disconnect-container-content">
  <div class="app-about-container">
    <div [ngClass]="{'app-about-container-content': TEST_MODE, 'app-about-container-content1': !TEST_MODE}">
      <div class="app-about-all-content">
        <div class="about-container-content">
          <h1 [appAppColor]="{default : ['color']}" class="about-title">qu'est ce que {{ platformConfig.platform.name }} ?</h1>

          <div class="about-photo">
            <div class="photo-item-container">
              <div class="photo-item1">
                <img [src]="platformConfig.platform.clientAboutDescriptionPicture" alt="about-description-image">
              </div>
            </div>
          </div>

          <div class="about-container-right">
            <div class="about-description" [innerHtml]="platformConfig.platform.clientAboutDescriptionText"></div>
          </div>

        </div>
        <div class="about-tariff">
          <h1 [appAppColor]="{default : ['color']}">Nos Tarifs</h1>
          <app-tariff [tariffs]="countries" [dtSource]="dtSource" (sourceCountryChange)="onSourceCountryChange($event)" (targetCountryChange)="onTargetCountryChange($event)">
          </app-tariff>
        </div>
        <div class="agencies-container">
          <h1 [appAppColor]="{default : ['color']}">Nos agences</h1>
          <div class="agencies-container-content">
            <div class="about-agencies-content">
              <div class="about-agencies-names">
                <form class="about-form">
                  <mat-form-field class="about-full-width">
                    <input (keyup)="onCountryEnter()" matInput placeholder="Veuillez choisir un pays" aria-label="State" [matAutocomplete]="auto" [formControl]="countryCtrl">
                    <mat-autocomplete (optionSelected)="getCities($event)" #auto="matAutocomplete">
                      <mat-option *ngFor="let country of countries" [value]="country.name">
                        <span>{{country.name }}</span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </form>
                <div *ngIf="cities.length" class="agency-container" slimScroll>
                  <mat-progress-spinner mode="indeterminate" class="loading-circle" [diameter]="40" [strokeWidth]="3" *ngIf="loadingCities" >
                  </mat-progress-spinner>
                  <ul *ngIf="!loadingCities ">
                    <li *ngFor="let city of cities">
                      <button [appAppColor]="appColorAgency" [ngClass]="{'active': (city.name === citySelected)}" (click)="getAgencies(city)" mat-button mat-ripple matRippleColor="rgba(255,255,255,.5)">{{city.name}}</button>
                    </li>
                  </ul>
                </div>
                <div class="mobile-countries-list" *ngIf="!loadingCities">
                  <mat-form-field>
                    <mat-select [(ngModel)]="cityActive">
                      <mat-option *ngFor="let city of cities" [value]="city.name" (click)="getAgencies(city)">
                        {{city.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="agency-container-none" *ngIf="!cities.length">
                  <div class="agency-none-message-container">
                  <h3 class="agency-none-message">Notre produit n'est pas disponible dans ce pays</h3>
                  </div>
                </div>
              </div>
              <div class="about-agencies-map">
                <mat-progress-spinner mode="indeterminate" class="loading-circle" [diameter]="40" [strokeWidth]="3" *ngIf="loadingAgencies" >
                </mat-progress-spinner>
                <div class="list-agencies-container" *ngIf="!loadingAgencies" >
                  <h2 *ngIf="citySelected">Nos Agences de <span>{{citySelected}}</span></h2>
                  <div class="list-agencies-container-content" *ngIf="agencies.length">
                    <div class="list-agency-item" *ngFor="let agency of agencies">
                      <div class="list-agency-item-content" >
                        <h3>{{agency.name}}</h3>
                        <ul>
                          <li><mat-icon>phone</mat-icon> Numéro :</li>
                          <li>{{agency.phone}}</li>
                        </ul>
                        <ul>
                          <li><mat-icon>store</mat-icon> Adresse :</li>
                          <li>{{agency.address}}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="list-agencies-container-content" *ngIf="!agencies.length && noCityExist">
                    <div class="none-message-container">
                      <h3 class="none-message">Pas d'agences disponible pour cette ville</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="partner-container">
          <h1 [appAppColor]="{default : ['color']}">Nos Partenaires</h1>
          <div class="partner-container-content">
            <div class="partner-item">
              <div class="partner-item-image">
                <img src="../../assets/images/c-lk-logo.jpg" alt="Logo">
              </div>
            </div>
            <div class="partner-item">
              <div class="partner-item-image">
                <img src="../../assets/images/btci.gif" alt="Logo-BTCI">
              </div>
            </div>
            <div class="partner-item">
              <div class="partner-item-image">
                <img src="../../assets/images/utb.jpg" alt="Logo-UTB">
              </div>
            </div>
            <div class="partner-item">
              <div class="partner-item-image">
                <img src="../../assets/images/poste-benin.jpg" alt="Logo-Poste-Benin">
              </div>
            </div>
            <div class="partner-item">
              <div class="partner-item-image">
                <img src="../../assets/images/la-poste-guinéenne.png" alt="Logo-Poste-Guinéenne">
              </div>
            </div>
            <div class="partner-item">
              <div class="partner-item-image">
                <img src="../../assets/images/CI-poste.jpg" alt="Logo-Poste-CI">
              </div>
            </div>
            <div class="partner-item">
              <div class="partner-item-image">
                <img src="../../assets/images/Logo_STPE.png" alt="Logo-STPE">
              </div>
            </div>
            <div class="partner-item">
              <div class="partner-item-image">
                <img src="../../assets/images/La-sopeco.jpg" alt="Logo-SOPECO">
              </div>
            </div>
            <div class="partner-item">
              <div class="partner-item-image">
                <img src="../../assets/images/trend-finance.gif" alt="Logo-TREND-FINANCE">
              </div>
            </div>
            <div class="partner-item">
              <div class="partner-item-image">
                <img src="../../assets/images/groupe-nabela.png" alt="Logo-NABELA">
              </div>
            </div>
            <div class="partner-item">
              <div class="partner-item-image">
                <img src="../../assets/images/CEF-MF.png" alt="Logo-CEF-MF">
              </div>
            </div>
            <div class="partner-item">
              <div class="partner-item-image">
                <img src="../../assets/images/pades.PNG" alt="Pades">
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

