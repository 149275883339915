<div class="app-pay-content">
  <div class="pay-container">
    <div class="pay-container-mask">
      <div class="pay-container-mask-content">
        <p>Cette partie n'est pas encore disponible</p>
      </div>
    </div>
    <mat-toolbar class="page-title">
      <span><mat-icon class="page-title-icon" role="img" aria-hidden="true">payment</mat-icon> Payement</span>
    </mat-toolbar>
    <mat-tab-group>
      <mat-tab label="Payer Facture">
        <div class="provider-reference">
          <mat-form-field>
            <mat-select placeholder="Vos Références">
              <mat-option *ngFor="let ref of refs" [value]="ref.value">
                {{ ref.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Entrez la référence">
          </mat-form-field>
          <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true" class="my-background-color">valider</button>
        </div>
        <div class="provider-container">
          <div class="provider-container-item">
            <ul>
              <li>Fournisseur :</li>
              <li>..............</li>
            </ul>
          </div>
          <div class="provider-container-item">
            <ul>
              <li>Nom : </li>
              <li>.............</li>
            </ul>
          </div>
          <div class="provider-container-item">
            <ul>
              <li>Prénoms :</li>
              <li>............</li>
            </ul>
          </div>
          <div class="provider-container-item">
            <ul>
              <li>Entreprise : </li>
              <li>............</li>
            </ul>
          </div>
          <div class="provider-container-item">
            <ul>
              <li>Adresse de l'entreprise : </li>
              <li>...........</li>
            </ul>
          </div>
          <div class="provider-container-item">
            <ul>
              <li>RCCM : </li>
              <li>...........</li>
            </ul>
          </div>
        </div>
        <div class="client-container">
          <div class="client-container-item">
            <ul>
              <li>Client : </li>
              <li>............</li>
            </ul>
          </div>
          <div class="client-container-item">
            <ul>
              <li>Adresse Client : </li>
              <li>............</li>
            </ul>
          </div>
          <div class="client-container-item">
            <ul>
              <li>RCCM : </li>
              <li>............</li>
            </ul>
          </div>
        </div>
        <div class="total-price">
          <ul>
            <li class="my-color">Total : 00.00 FCFA</li>
            <li><button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true" class="my-background-color">Payer</button></li>
          </ul>
        </div>
      </mat-tab>
      <mat-tab label="Payer Service">
        <div class="service-pay-container">
          <div class="service-container-choice">
            <mat-button-toggle-group #group="matButtonToggleGroup">
              <mat-button-toggle value="left">
                CEET
              </mat-button-toggle>
              <mat-button-toggle value="center">
                TDE
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="service-pay-container-item">
            <mat-form-field>
              <input matInput placeholder="Numéro de la facture">
            </mat-form-field>
          </div>
          <div class="service-pay-container-item">
            <mat-form-field>
              <input matInput placeholder="Montant à payer" type="number" value="" class="example-right-align">
              <span matPrefix>$&nbsp;</span>
              <span matSuffix>.00</span>
            </mat-form-field>
          </div>
          <div class="service-pay-container-item1">
            <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true" class="my-background-color">Valider</button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
