<div class="inner-disconnect-container-content">
  <div class="app-disconnect-balance-container">
    <div class="balance-operation-title">
      <mat-toolbar class="page-title">
        <span [appAppColor]="{default : ['color']}">Voir Solde</span>
      </mat-toolbar>
    </div>
    <form class="disconnect-balance-form" [formGroup]="form">
      <div class="balance-form-elements">
        <div class="disconnect-balance-input-item">
          <mat-form-field class="tel-input-form">
            <input matInput placeholder="Votre numéro de téléphone" [formControl]="form.controls['phone']"
                   ng2TelInput
                   (countryChange)="onCountryChange($event)"
                   (ng2TelOutput)="getPhone($event)"
                   [ng2TelInputOptions]="utils.selectorOptions"
                   (hasError)="phoneHasError($event)">
            <mat-error *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched">
              Ce champ est requis
            </mat-error>
            <mat-error *ngIf="form.controls['phone'].hasError('badPhone') && form.controls['phone'].touched">
              Numéro de téléphone invalide
            </mat-error>
          </mat-form-field>
        </div>
        <div class="disconnect-balance-input-item">
          <mat-form-field>
            <input matInput type="tel" placeholder="Votre code pin" [formControl]="form.controls['pin']" maxlength="6" style="-webkit-text-security:disc;">
            <mat-error *ngIf="form.controls['pin'].hasError('required') && form.controls['pin'].touched">
              Ce champ est requis
            </mat-error>
            <mat-error *ngIf="form.controls['pin'].hasError('pattern') && form.controls['pin'].touched">
              La valeur entré doit être numérique
            </mat-error>
          </mat-form-field>
        </div>
        <mat-toolbar class="disconnect-balance-footer">
          <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                  matRippleCentered="true" [appAppColor]="{default : ['background-color']}"
                  (click)="onSubmit()" [disabled]="!form.valid || loading" >
            <mat-progress-spinner mode="indeterminate" [diameter]="25" [strokeWidth]="2" *ngIf="loading" >
            </mat-progress-spinner>
            Valider
          </button>
        </mat-toolbar>
      </div>
    </form>
  </div>
</div>
