<div class="ongoing-operations-table mat-elevation-z8">
    <mat-table #table [dataSource]="dataSource">

      <!-- operationDate Column -->
      <ng-container matColumnDef="operationDate">
        <mat-header-cell *matHeaderCellDef class="table-title"> Date </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="mobile-label">Date:</span>
          {{ element.operationDate | date:'yyyy-MM-dd HH:mm':'':'fr' }}
        </mat-cell>
      </ng-container>

      <!-- operationType Column -->
      <ng-container matColumnDef="operationType">
        <mat-header-cell *matHeaderCellDef class="table-title"> Type </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="mobile-label">Type:</span>
          {{element.operationType}}
        </mat-cell>
      </ng-container>

      <!-- operationAmount Column -->
      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef class="table-title"> Montant </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="mobile-label">Montant:</span>
          <app-operation-amount-movement-indicator [operation]="element"></app-operation-amount-movement-indicator>
          {{((element.amount | floorAmount) | currency: 'XOF':'') +" "+element.prefixed}}
        </mat-cell>
      </ng-container>

      <!-- operationDescription Column -->
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef class="table-title"> Description </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="mobile-label">Description:</span>
          {{element.description}}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row (click)="onSelectRow(row)" *matRowDef="let row; columns: displayedColumns;" ></mat-row>
    </mat-table>
</div>
