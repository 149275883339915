import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {PlatformConfig} from './app-loader/platform-config';
import {ConfigLoaderService} from './app-loader/config-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {

  platformConfig: PlatformConfig;

  constructor(private titleService: Title, public configLoaderService: ConfigLoaderService) {
    this.platformConfig = this.configLoaderService.getPlatformConfig();
    this.titleService.setTitle(this.platformConfig.platform.name);
  }

}
