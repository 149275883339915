<div class="my-users-content">
      <mat-table #table [dataSource]="dataSource" class="users-table">

          <!-- userFirstName Column -->
          <ng-container matColumnDef="userFirstName">
              <mat-header-cell *matHeaderCellDef class=""> Prénoms </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.userFirstName}} </mat-cell>
          </ng-container>

          <!-- userLastName Column -->
          <ng-container matColumnDef="userLastName">
            <mat-header-cell *matHeaderCellDef class=""> Nom </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.userLastName}} </mat-cell>
          </ng-container>

          <!-- operationAmount Column -->
          <ng-container matColumnDef="userPhoneNumber">
            <mat-header-cell *matHeaderCellDef class=""> Téléphone </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.userPhoneNumber}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      </mat-table>
</div>

<div class="add-user">
  <ul>
    <li>Ajouter un identifiant</li>
    <li><button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true" class="my-background-color" mat-raised-button (click)="openDialog()">Ajouter</button></li>
  </ul>
</div>
