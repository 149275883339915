import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {GlobalVariable} from '../../../global';

@Component({
  selector: 'app-operation-reason',
  templateUrl: './operation-reason.component.html',
  styleUrls: ['./operation-reason.component.scss']
})
export class OperationReasonComponent implements OnInit {
  @Input() operationReasonFormControl: FormControl;
  @Input() placeholder: string;
  @Output() onOperationReasonSelected: EventEmitter<string> = new EventEmitter<string>();
  public reasons = GlobalVariable.TRANSFER_REASONS;
  constructor() { }

  ngOnInit() {
  }
  onReasonSelection(e) {
    if (e.value) {
      this.onOperationReasonSelected.emit(this.operationReasonFormControl.value);
    }
  }
  onOtherReasonBlur(e) {
    if (e.target.value) {
      this.reasons.push({name: e.target.value, description: e.target.value});
      this.onOperationReasonSelected.emit(e.target.value);
    }
  }
}
