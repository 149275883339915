import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {DashboardComponent} from './user/dashboard/dashboard.component';
import {MyAccountComponent} from './user/my-account/my-account.component';
import {TransfertComponent} from './user/transfert/transfert.component';
import {ToPayComponent} from './user/to-pay/to-pay.component';
import {OperationsComponent} from './user/operations/operations.component';
import {ReportComponent} from './user/report/report.component';
import {ContactComponent} from './contact/contact.component';
import {ParametersComponent} from './user/parameters/parameters.component';
import {UserConnectionComponent} from './user-connection/user-connection.component';
import {UserInscriptionComponent} from './user-inscription/user-inscription.component';
import {InvoiceComponent} from './user/invoice/invoice.component';
import {UserComponent} from './user/user.component';
import {HomeComponent} from './home/home.component';
import {DisconnectOperationsComponent} from './disconnect-operations/disconnect-operations.component';
import {DisconnectTransferComponent} from './disconnect-operations/disconnect-transfer/disconnect-transfer.component';
import {DisconnectPayComponent} from './disconnect-operations/disconnect-pay/disconnect-pay.component';
import {DisconnectChangePinComponent} from './disconnect-operations/disconnect-change-pin/disconnect-change-pin.component';
import {DisconnectBalanceComponent} from './disconnect-operations/disconnect-balance/disconnect-balance.component';
import {UserContactComponent} from './user/user-contact/user-contact.component';
import {AboutComponent} from './about/about.component';
import {HelpComponent} from './help/help.component';
import {AuthGuard} from './shared/auth/auth-guard.service';
import {ProfileResolver} from './shared/profile/profile-resolver.service';
import { TransfertCollectComponent } from './user/transfert-collect/transfert-collect.component';
import { PermanentTransfertComponent } from './user/permanent-transfert/permanent-transfert.component';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { DebitAtmComponent } from './user/debit-atm/debit-atm.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component : HomeComponent
  },
  {path: 'disconnect-operations', component : DisconnectOperationsComponent, children: [
    {path: '', redirectTo: '/disconnect-operations/disconnect-balance', pathMatch: 'full'},
    {path: 'disconnect-transfer', component: DisconnectTransferComponent},
    {path: 'disconnect-pay', component: DisconnectPayComponent},
    {path: 'disconnect-change-pin', component: DisconnectChangePinComponent},
    {path: 'disconnect-balance', component: DisconnectBalanceComponent},
  ], },
  {path: 'about' , component: AboutComponent},
  {path: 'contact' , component: ContactComponent},
  {path: 'help' , component: HelpComponent},
  {path: 'legal-notice', component: LegalNoticeComponent},
  {
    path: 'user',
    component : UserComponent,
    canActivateChild: [AuthGuard],
    resolve: {profile: ProfileResolver},
    children: [
      {
        path: '',
        redirectTo: '/user/dashboard',
        pathMatch: 'full',
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'my-account',
        component: MyAccountComponent,
        canActivate: [AuthGuard],
        resolve: {profile: ProfileResolver}
      },
      {path: 'transfert', component: TransfertComponent, canActivate: [AuthGuard] },
      {path: 'debit-atm', component: DebitAtmComponent, canActivate: [AuthGuard]},
      {path: 'transfert-collect', component: TransfertCollectComponent, canActivate: [AuthGuard] },
      {path: 'permanent-transfert', component: PermanentTransfertComponent, canActivate: [AuthGuard] },
      {path: 'to-pay', component: ToPayComponent, canActivate: [AuthGuard] },
      {path: 'operations' , component: OperationsComponent, canActivate: [AuthGuard] },
      {path: 'report' , component: ReportComponent, canActivate: [AuthGuard] },
      {path: 'parameters' , component: ParametersComponent, canActivate: [AuthGuard] },
      {path: 'invoice' , component: InvoiceComponent, canActivate: [AuthGuard] },
      {path: 'user-contact' , component: UserContactComponent, canActivate: [AuthGuard] },
    ],
  },
  {path: 'user-connection' , component: UserConnectionComponent},
  {path: 'user-inscription' , component: UserInscriptionComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
