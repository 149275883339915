<div class="app-transfert-content">
  <div class="transfert-container">
    <mat-toolbar class="page-title">
      <span [appAppColor]="{default : ['color']}">
        <mat-icon class="page-title-icon" role="img" aria-hidden="true">
          compare_arrows</mat-icon> Transfert</span>
    </mat-toolbar>
    <div class="transfert-container-content">
      <form class="transfert-form">

        <div class="transfert-form-elements">

          <div class="transfert-input-item">
            <mat-form-field class="tel-input-form">
              <input matInput type="text"
                     placeholder="Téléphone du destinataire"
                     (blur)="onReceiverPhoneBlur()"
                     [formControl]="form.controls['receiver']['controls']['phone']"
                     ng2TelInput
                     (countryChange)="onRecieverCountryChange($event)"
                     (ng2TelOutput)="getReceiverNumber($event)"
                     [ng2TelInputOptions]="utils.selectorOptions"
                     (hasError)="receiverPhoneHasError($event)" />
              <mat-error *ngIf="form.controls['receiver']['controls']['phone'].hasError('required') && form.controls['receiver']['controls']['phone'].touched">
                Ce champ est requis
              </mat-error>
              <mat-error *ngIf="form.controls['receiver']['controls']['phone'].hasError('badPhone') && form.controls['receiver']['controls']['phone'].touched">
                Numéro de téléphone invalide
              </mat-error>
              <mat-progress-spinner mode="indeterminate" color="primary" class="loading-circle" [diameter]="25" [strokeWidth]="2" *ngIf="loadingReceiver">
              </mat-progress-spinner>
            </mat-form-field>
          </div>

          <div class="transfert-input-item">
            <mat-form-field>
              <input  matInput placeholder="Montant à envoyer" type="text" value=""
                      [formControl]="form.controls['amount']" class="example-right-align"
                      appCurrencyFormatDirective appRegexpInputCheck [inputRegexp]="'[0-9]'">
                      
              <span matSuffix>.00</span>
              <mat-error *ngIf="form.controls['amount'].hasError('required') && form.controls['amount'].touched">
                Ce champ est requis
              </mat-error>
              <mat-error *ngIf="form.controls['amount'].hasError('invalidAmount') && form.controls['amount'].touched">
                {{ "Montant invalide, le montant minimal pour éffectuer cette opération est de "  }} {{minimumOperationAmount}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="transfert-input-item motif">
            <app-operation-reason
              [operationReasonFormControl]="form.controls['reason']"
              [placeholder]="'Motif'"
              (onOperationReasonSelected)="onReasonSelected($event)"
            ></app-operation-reason>
          </div>

          <div class="transfert-input-item">
            <mat-form-field class="example-full-width">
              <textarea  matInput #transfertDescription maxlength="250"
                         placeholder="Description"
                         [formControl]="form.controls['description']" ></textarea>
              <mat-error *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched">
                Ce champ est requis
              </mat-error>
              <mat-hint align="end">{{transfertDescription.value.length}} / 250</mat-hint>
            </mat-form-field>
          </div>

         

          <div class="transfert-input-item1">
            <ul>
              <li>
                <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                        matRippleCentered="true" [appAppColor]="{default : ['background-color']}"
                        [disabled]="!form.valid || loadingCalculateReceivingAmount"
                        (click)="onCalculateOperationCost('TRANSFERT')" type="button" >
                  <mat-progress-spinner mode="indeterminate" class="primary" [diameter]="25" [strokeWidth]="2"
                                        *ngIf="loadingCalculateReceivingAmount" >
                  </mat-progress-spinner>
                  Coût de transfert</button>
              </li>
              <li [appAppColor]="{default : ['color']}">
                {{ operationCost.cost + '' + operationCost.targetMonetaryArea.currencyShortName }}
              </li>
            </ul>
          </div>
        </div>

        <mat-toolbar class="transfert-footer">
          <button [disabled]="!form.valid" mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
             matRippleCentered="true" [appAppColor]="{default : ['background-color']}" (click)="onSubmit()">
            <mat-progress-spinner mode="indeterminate" class="primary" [diameter]="25" [strokeWidth]="2" *ngIf="loading" >
            </mat-progress-spinner>
            Envoyer
          </button>
        </mat-toolbar>

      </form>
    </div>
  </div>
</div>

