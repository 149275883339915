<div class="app-account-content">
  <div class="my-account-all-content">
    <mat-toolbar class="page-title">
      <span [appAppColor]="{default : ['color']}"><mat-icon class="page-title-icon" role="img" aria-hidden="true">account_circle</mat-icon> Mon Compte</span>
    </mat-toolbar>
    <div class="account-container">
      <div class="account-image">
        <div class="my-account-container">
          <div class="account-image-content">
            <!-- <img mat-card-image [src]="mediaUrl" alt="Photo of user"
                 *ngIf="mediaUrl !== null" > -->
            <img mat-card-image src="../../../assets/images/user.png" alt="Photo of user"
                 *ngIf="mediaUrl === null" >
            <!-- <input type="file" id="profile_pic" name="profile_pic" accept=".jpg, .jpeg, .png"
                   (change)="onChangeMedia($event)" #picture> -->
            <!-- <div *ngIf="editMode" class="profile-hover-text" (click)="picture.click()" >
              <mat-icon class="profile-camera" role="img" aria-hidden="true">camera_alt</mat-icon>
              <p>Changer son photo de profil</p>
            </div> -->
          </div>
          <!-- <div *ngIf="editMode" class="mobile-change-button" (click)="picture.click()">
            <button mat-icon-button><mat-icon class="profile-camera" role="img" aria-hidden="true">camera_alt</mat-icon></button>
          </div> -->
          <div  class="account-ul-container">
            <ul class="account-name">
              <li>{{ profile.lastname }}</li>
              <li>{{ profile.firstname }}</li>
              <li *ngIf="profile.account.validated" style="color: red;" ><b>Compte validé</b></li>
            </ul>
            <ul class="mobile-edit-buttons">
              <li *ngIf="!editMode" >
                <button [appAppColor]="{default : ['background-color']}" mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                        matRippleCentered="true"  (click)="onEditMode()">
                  Modifier
                </button>
              </li>
              <li *ngIf="editMode" >
                <button [appAppColor]="{default : ['background-color']}" mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                        matRippleCentered="true" (click)="onCancelEditMode()">
                  Annuler
                </button>
              </li>
              <li *ngIf="editMode" >
                <button [appAppColor]="{default : ['background-color']}" mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                        matRippleCentered="true" (click)="onSubmit()" >
                  <mat-progress-spinner mode="indeterminate" class="primary" [diameter]="25" [strokeWidth]="2" *ngIf="loading" >
                  </mat-progress-spinner>
                  Enregistrer
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="account-informations">
        <form class="account-form" [formGroup]="form">
          <div class="account-input-content">
            <div class="short-input">
              <mat-form-field>
                <input matInput placeholder="Votre Nom" [formControl]="form.controls['lastname']" >
              </mat-form-field>
            </div>
            <div class="short-input">
              <mat-form-field>
                <input matInput placeholder="Votre Prénom" [formControl]="form.controls['firstname']" >
              </mat-form-field>
            </div>
          </div>
          <div class="account-input-content">
            <div class="short-input">
              <mat-form-field>
                <input matInput placeholder="Votre Email" [formControl]="form.controls['email']" >
                <mat-error *ngIf="form.controls['email'].hasError('emailIsUnique') && form.controls['email'].touched">
                  Cet email est déja utilisé
                </mat-error>
              </mat-form-field>
            </div>
            <div class="short-input">
              <mat-form-field>
                <input matInput placeholder="Votre Téléphone"
                       [formControl]="form.controls['phone']"
                       ng2TelInput
                       (countryChange)="onCountryChange($event)"
                       [ng2TelInputOptions]="utils.selectorOptions"
                       (ng2TelOutput)="getPhone($event)"
                       (hasError)="phoneHasError($event)"
                        readonly="true">
                <mat-error *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched">
                  Ce champ est requis
                </mat-error>
                <mat-error *ngIf="form.controls['phone'].hasError('badPhone') && form.controls['phone'].touched">
                  Numéro de téléphone invalide
                </mat-error>
                <mat-error *ngIf="form.controls['phone'].hasError('phoneIsUnique') && form.controls['phone'].touched">
                  Ce numéro est déja utilisé
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="account-input-content">
            <div class="short-input">
              <mat-form-field>
                <input matInput placeholder="Votre Pays"  disabled [value]="profile.country.name" >
              </mat-form-field>
            </div>
            <div class="short-input">
              <app-cities-autocomplete [cityFormControl]="form.controls['city']"
                                       [placeholder]="'Votre ville'"
                                       (onCitySelected)="onCitySelected($event)"></app-cities-autocomplete>
            </div>
          </div>
          <div class="account-input-content">
            <div class="short-input-address">
              <mat-form-field>
                <input matInput placeholder="Votre Adresse" [formControl]="form.controls['address']" >
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="account-input-content">
            <div class="short-input-address">
              <mat-form-field style="width: 100%">
                <input [formControl]="form.controls['cardExpiration']"
                       [matDatepicker]="FirstDatepicker" [max]="currentDate"
                       matInput placeholder="{{ 'Date d'expiration' }}" 
                       (click)="FirstDatepicker.open()">
                <mat-datepicker-toggle matSuffix [for]="FirstDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #FirstDatepicker></mat-datepicker>
              </mat-form-field>
              <mat-error *ngIf="form.controls['cardExpiration'].hasError('required') 
                && form.controls['cardExpiration'].touched" class="mat-text-warn">
                {{ "REQUIRED" | translate}}
              </mat-error>
            </div>
          </div> -->
        </form>
      </div>
      <div class="account-image-footer">
        <ul>
          <li *ngIf="!editMode" >
            <button [appAppColor]="{default : ['background-color']}" mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                    matRippleCentered="true" (click)="onEditMode()">
              Modifier
            </button>
          </li>
          <li *ngIf="!editMode" >
            <button [appAppColor]="{default : ['background-color']}" mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                    matRippleCentered="true" (click)="changePasswordDialog()">
              Changer le mot de passe
            </button>
          </li>
          <li *ngIf="editMode" >
            <button [appAppColor]="{default : ['background-color']}" mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                    matRippleCentered="true" (click)="onCancelEditMode()">
              Annuler
            </button>
          </li>
          <li *ngIf="editMode" >
            <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                    matRippleCentered="true" [appAppColor]="{default : ['background-color']}" (click)="onSubmit()" >
              <mat-progress-spinner mode="indeterminate" [diameter]="25" [strokeWidth]="2" *ngIf="loading" >
              </mat-progress-spinner>
              Enregistrer
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="account-others-informations">
      <mat-toolbar class="more-infos-header">
        <span [appAppColor]="{default : ['color']}">Autres informations</span>
      </mat-toolbar>
      <mat-tab-group mat-stretch-tabs>
        <mat-tab label="Paramètres de profil">
          <form class="account-others-informations-form">
            <div class="account-input-content">
              <div class="short-input-type">
                <mat-form-field>
                  <input matInput placeholder="Type de compte" disabled value="Classique">
                </mat-form-field>
                <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                        matRippleCentered="true" class="my-background-color" (click)="openBusinessDialog()" disabled>Changer</button>
              </div>
            </div>
            <div class="account-input-content">
              <div class="short-input">
                <mat-form-field>
                  <input [value]="showIdype(profile.idType)" matInput placeholder="Type de pièce" disabled>
                </mat-form-field>
              </div>
              <div class="short-input">
                <mat-form-field>
                  <input [value]="profile.idNumber" matInput placeholder="Numéro de pièce" disabled>
                </mat-form-field>
              </div>
            </div>
            <div class="account-input-content">
              <div class="short-input">
                <mat-form-field>
                  <input [value]="profile.reference" matInput placeholder="Réference" disabled>
                </mat-form-field>
              </div>
              <div class="short-input">
                <mat-form-field>
                  <input [value]="profile.cardExpiration | date:'dd-MM-yyyy'" matInput placeholder="Date d'expiration de la pièce" disabled>
                </mat-form-field>
              </div>
              <div class="short-input">
                <mat-form-field>
                  <mat-select placeholder="Mode de notification" [formControl]="form.controls['notificationType']">
                    <mat-option *ngFor="let nm of notifyMode" [value]="nm"> {{nm}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!--<div class="short-input">
                <mat-form-field>
                  <input matInput placeholder="Entreprise" disabled value=".....">
                </mat-form-field>
              </div>
            </div>
            <div class="account-input-content">
              <div class="short-input">
                <mat-form-field>
                  <input matInput placeholder="Adresse de l'Entreprise"  disabled value="....">
                </mat-form-field>
              </div>
              <div class="short-input">
                <mat-form-field>
                  <input matInput placeholder="rccm" disabled value="....">
                </mat-form-field>
              </div>-->
            </div>
          </form>
          <div class="account-balance">
            <ul>
              <li>Votre Solde</li>
              <li [appAppColor]="{default : ['color']}">{{ 
                ((profile.account.balance | floorAmount) | currency: 'XOF':'') + ' ' + profile.account.monetaryArea.currencyShortName }}</li>
            </ul>
          </div>
        </mat-tab>
        <mat-tab label="Mes Identifiants" *ngIf="1!==1">
          <div class="users-content">
            <app-my-users class="users-page-content">
            </app-my-users>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
