import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { UserCollectProduct } from '../../../shared/models/users-collect/user-collector-product.model';
import { OperationAmountValidators } from '../../../shared/validators/operation-amount.validators';
import { GlobalVariable } from '../../../global';
import { OperationCollectProduct } from './operation-collect-product.model';
import {PlatformConfig} from '../../../app-loader/platform-config';
import {ConfigLoaderService} from '../../../app-loader/config-loader.service';
import { UserCollect } from '../../../shared/models/users-collect/user-collect.model';
import { CustomValidators } from '../../../shared/validators/custom.validators';

@Component({
  selector: 'app-operation-collect-products-dialog',
  templateUrl: './operation-collect-products-dialog.component.html',
  styleUrls: ['./operation-collect-products-dialog.component.scss']
})
export class OperationCollectProductsDialogComponent implements OnInit {

  products: UserCollectProduct[] = [];
  form: FormGroup;
  userCollect: UserCollect;
  minimumOperationAmount = GlobalVariable.MINIMUM_COLLECT_PRODUCT_AMOUNT;
  loading: boolean = false;
  operationProducts: OperationCollectProduct[] = [];
  platformConfig: PlatformConfig;
  constructor(
    public dialogRef: MatDialogRef<OperationCollectProductsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public configLoaderService: ConfigLoaderService, 
              
              private fb: FormBuilder
  ) {
    this.platformConfig = this.configLoaderService.getPlatformConfig();
    this.userCollect = data.userCollect;
    if (data.productDetails) {
      this.operationProducts = data.productDetails;
    }
  }

  ngOnInit() {
    this.form = this.fb.group ( {
      product: [null ,
        Validators.compose ( [  Validators.required, CustomValidators.linkedEntity  ] ),
        Validators.composeAsync([])
      ],
      amount: [null ,
        Validators.compose ( [ Validators.required,
          OperationAmountValidators.amountCheck( this.minimumOperationAmount ) ] ),
        Validators.composeAsync([])
      ],
    }); 
  }

  checkProductExist(row) {
    const products = this.operationProducts.filter(
      (opProduct) => {
        return opProduct.product.reference === row.product.reference;
      }
    );
    return products.length > 0;
  }

  onProductAdd() {
    this.loading = true;
    
    if (this.checkProductExist(this.form.value)) {
      this.onDeleteOperationCollectProduct(this.form.value);
    }
    
    this.form.disable();
    this.operationProducts.push(this.form.value);
    // this.deleteOnProducts(this.form.value);
    this.form.enable();
    this.form.controls['product'].setValue(null);
    this.form.controls['amount'].setValue(null);
    // this.form.reset();
    this.loading = false;
  }

  deleteOnProducts(row: OperationCollectProduct) {
    this.loading = true;
    this.products = this.products.filter(
      (opProduct) => {
        return opProduct.reference !== row.product.reference;
      }
    );
    this.loading = false;
  }

  onDeleteOperationCollectProduct(row: OperationCollectProduct) {
    this.loading = true;
    this.operationProducts = this.operationProducts.filter(
      (opProduct) => {
        return opProduct.product.reference !== row.product.reference;
      }
    );
    this.products.push(row.product);
    this.loading = false;
  }

  onSubmit() {
    this.dialogRef.close(this.operationProducts);
  }

  getTotal() {
    let total = 0;
    this.operationProducts.forEach(
      (opProduct) => {
        total = total + opProduct.amount;
      }
    );
    return total;
  }

  onCollectProductSelected(e) {  
    this.form.controls['product'].setValue(e);
  }

}
