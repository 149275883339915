import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {GlobalVariable} from '../../../global';

@Injectable({
  providedIn: 'root'
})
export class CustomerDetailsDialogService {

  private endpointUrl: string = GlobalVariable.BASE_API_URL + '/logged/';
  private collectCustomerEndpoint: string = GlobalVariable.BASE_API_URL + '/user-collect/';

  constructor(private http: HttpClient) { }

  getCustomerInfos(reference: String): Observable<any> {
    return this.http.get(this.endpointUrl + reference)
  }

  getCollectCustomerInfos(reference: String): Observable<any> {
    return this.http.get(this.collectCustomerEndpoint + reference)
  }
}
