<mat-form-field class="tel-input-form">
  <input matInput placeholder="{{ placeholder }}"
         [formControl]="collectProductFormControl"  autocomplete="off"
         [matAutocomplete]="autoCollectProducts"
         (keyup)="filterProducts(collectProductInput.value)"
         #collectProductInput>
  <mat-error
    *ngIf="collectProductFormControl.hasError('required') && 
    collectProductFormControl.touched"
    class="mat-text-warn">
    {{ "Ce champs est requis" }}
  </mat-error>
  <mat-progress-spinner *ngIf="productLoading" mode="indeterminate"
   class="primary ng-pendig-spinner-spin" 
   [diameter]="25" [strokeWidth]="2"  ></mat-progress-spinner>
</mat-form-field>
<mat-autocomplete #autoCollectProducts="matAutocomplete" [displayWith]="displayProduct"
 (optionSelected)="onSelectProduct()">
  <mat-option *ngFor="let product of collectProducts" [value]="product">
    {{ product.name }}
  </mat-option>
</mat-autocomplete>
