import { MatDialog } from '@angular/material';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {PinForgotComponent} from './pin-forgot/pin-forgot.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';

import {AuthService} from '../shared/auth/auth.service';
import {Credentials} from '../shared/auth/model/credentials.model';
import {User} from '../shared/user/model/user.model';
import {Utils} from '../shared/utils/Utilis';
import {GlobalVariable} from '../global';
import { ConfigLoaderService } from '../app-loader/config-loader.service';
import { PlatformConfig } from '../app-loader/platform-config';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-user-connection',
  templateUrl: './user-connection.component.html',
  styleUrls: ['./user-connection.component.css']
})

export class UserConnectionComponent implements OnInit,OnDestroy {

  hide = true;

  public form: FormGroup;
  public confirm_form: FormGroup;
  public formErrors: string[] = [];
  private correctPlatform: string;
  public loading: boolean = false;
  mustResendCode = false;
  resendCounter = 0;
  TEST_MODE = GlobalVariable.TEST_MODE;
  telCountry: any;
  remainingLogins = null;
  isLeftVisible = true;
  showCounter = new BehaviorSubject<boolean>(false);
  platformConfig: PlatformConfig ;
  clearCounter;
  numberRegEx = /\-?\d*\.?\d{1,2}/;
  constructor(public dialog: MatDialog,
              private fb: FormBuilder,
              private router: Router,
              private http: HttpClient,
              public utils: Utils,
              public configLoaderService: ConfigLoaderService,
              private authService: AuthService) {
      this.platformConfig = this.configLoaderService.getPlatformConfig();
  }
  ngOnDestroy(): void {
   clearInterval(this.clearCounter);
  }

  ngOnInit() {
    this.form = this.fb.group ( {
      login: [null , Validators.compose ( [ Validators.required ] )] ,
      password: [null , Validators.compose ( [ Validators.required ] )],
      rememberMe: [null , Validators.compose ( [] )],
    });

    this.confirm_form = this.fb.group ( {
      token: [null , Validators.compose ( [ Validators.required ] )],
      validationCode: [null , Validators.compose ( [ Validators.required ] )],
      login: [null , Validators.compose ( [ Validators.required ] )],
    });
   
   this.showCounter.subscribe( res => {
    if(res){
      this.startCounter();
    }
   })
  }

  blockTabAction(e) {
    if (e.keyCode == 9) e.preventDefault(); 
  }

  phoneHasError(e) {
    if (!e) {
      if (this.telCountry) {
        if (this.telCountry.dialCode !== '237') {
          this.form.controls['login'].setErrors({'badPhone': true});
        } else {
          const phone = this.form.controls['login'].value;
          if (phone !== null && phone !== undefined) {
            if (phone.indexOf('+') === -1) {
              this.form.controls['login'].setValue('+' + this.telCountry.dialCode + phone);
            }
          }
        }
      } else {
        this.form.controls['login'].setErrors({'badPhone': true});
      }
    }
  }

  onCountryChange(e) {
    this.telCountry = e;
  }

  getPhone(e) {
    this.form.controls['login'].setValue(e);
  }


  onConfirmCodeSubmit() {
    this.loading = true;
    this.confirm_form.disable();

    this.authService.confirmAuthCode(this.confirm_form.value).subscribe(
      (user: User) => {
        this.router.navigate ( [ '/user' ] );
      },
      (error) => {
        this.confirm_form.enable();
        this.formErrors.pop();
        this.correctPlatform = null;
        if (error.message === 'INVALID CODE') {
          this.formErrors.push('Code incorrect');
        }
        this.confirm_form.controls['validationCode'].reset();
        this.loading = false;
      }
    );
  }



  simpleAuthentification(credentials) {
    this.authService.simpleAuthSigningUser(credentials).subscribe(
      (user: User) => {
        this.router.navigate ( [ '/user' ] );
      },
      (error) => {
        this.formErrors.pop();
        this.correctPlatform = null;
        if (error.message === 'INVALID CREDENTIALS') {
          this.formErrors.push('Téléphone ou PIN incorrect');
        } else if (error.message  === 'THIS OPERATION IS DONE ON A PLATFORM WHICH IS NOT YOUR\'S') {
          this.formErrors.push('Vous tentez d\'effectuer une opération sur une plateforme qui n\'est pas la votre');
          if (error.platform) {
            this.correctPlatform  = error.platform;
          }
        } else if (error.message === 'USER IS NOT ACTIVE') {
          this.formErrors.push('Utilisateur inactif, rendez-vous en agence pour l\'activer');
          
        } else {
          this.formErrors.push(error.message);
        }
        this.remainingLogins = error.remainingLogins;
        this.form.controls['password'].setValue(null);
        this.loading = false;
        this.form.enable();
      }
    );
  }

  doubleAuthentification(credentials) {
    this.authService.doubleAuthSigningUser(credentials).subscribe(
      (authToken: any) => {
        // this.router.navigate ( [ '/user' ] );
        this.formErrors = [];
        this.remainingLogins = null;
        this.correctPlatform = null;
        this.confirm_form.controls['login'].setValue(credentials.login);
        this.confirm_form.controls['token'].setValue(authToken.value);
        this.loading = false;
        this.isLeftVisible = !this.isLeftVisible;
        this.showCounter.next(true);
      },
      (error) => {
        // console.log(error);
        this.formErrors.pop();
        this.correctPlatform = null;
        if (error.message === 'INVALID CREDENTIALS') {
          this.formErrors.push('Téléphone ou PIN incorrect');
        } else if (error.message  === 'THIS OPERATION IS DONE ON A PLATFORM WHICH IS NOT YOUR\'S') {
          this.formErrors.push('Vous tentez d\'effectuer une opération sur une plateforme qui n\'est pas la votre');
          if (error.platform) {
            this.correctPlatform  = error.platform;
          }
        } else if (error.message === 'USER IS NOT ACTIVE') {
          this.formErrors.push('Utilisateur inactif, rendez-vous en agence pour l\'activer');
        } else {
          this.formErrors.push(error.message);
        }
        this.remainingLogins = error.remainingLogins;
        this.form.controls['password'].setValue(null);
        this.loading = false;
        this.form.enable();
      }
    );
  }

  onSubmit() {
    this.formErrors = [];
    this.remainingLogins = null;
    this.correctPlatform = null;
    const credentials: Credentials = this.form.value;
    this.loading = true;
    this.form.disable();
    
    this.authService.signinUser(credentials).subscribe(
      (response: any) => {
        if ('authToken' in response) {
          this.router.navigate ( [ '/user' ] );
        } else {
          this.formErrors = [];
          this.remainingLogins = null;
          this.correctPlatform = null;
          this.confirm_form.controls['login'].setValue(credentials.login);
          this.confirm_form.controls['token'].setValue(response.value);
          this.loading = false;
          this.isLeftVisible = !this.isLeftVisible;
          this.showCounter.next(true);
        }
      },
      (error) => {
        // console.log(error);
        this.formErrors.pop();
        this.correctPlatform = null;
        if (error.message === 'INVALID CREDENTIALS') {
          this.formErrors.push('Téléphone ou PIN incorrect');
        } else if (error.message  === 'THIS OPERATION IS DONE ON A PLATFORM WHICH IS NOT YOUR\'S') {
          this.formErrors.push('Vous tentez d\'effectuer une opération sur une plateforme qui n\'est pas la votre');
          if (error.platform) {
            this.correctPlatform  = error.platform;
          }
        } else if (error.message === 'USER IS NOT ACTIVE') {
          this.formErrors.push('Utilisateur inactif, rendez-vous en agence pour l\'activer');
        } else {
          this.formErrors.push(error.message);
        }
        this.remainingLogins = error.remainingLogins;
        this.form.controls['password'].setValue(null);
        this.loading = false;
        this.form.enable();
      }
    );  
    // if (this.platformConfig.platform.activateDoubleAuthenticator) {
    //   this.doubleAuthentification(credentials);
    // } else  {
    //   this.simpleAuthentification(credentials);
    // }
  }

  openPinDialog(): void {
    const dialogRef = this.dialog.open(PinForgotComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {

    });
  }
  resendCode(){
    this.mustResendCode = false;
    ++this.resendCounter;
   
   
      
      this.authService.resendCode(this.confirm_form.value.token).subscribe(() => {
        this.startCounter(5);
      },err => {
        this.isLeftVisible = !this.isLeftVisible;
        this.form.enable();
        this.showCounter.next(false);
      })
   
  }
  startCounter(minuiteToadd = 2){
    var countDownDate = new Date(new Date().getTime() + minuiteToadd*60000).getTime();
    var time = this.clearCounter= setInterval(()=>{
      var now = new Date().getTime();
       var timeleft = countDownDate - now;
       var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
        var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
        // document.getElementById("days").innerHTML = days + "d "
        // document.getElementById("hours").innerHTML = hours + "h " 
        document.getElementById("minutes").innerHTML = minutes + "m " 
        document.getElementById("seconds").innerHTML = seconds + "s "
        if(timeleft < 0){
          clearInterval(time);
          this.mustResendCode = true;
          document.getElementById("minutes").innerHTML = ""
          document.getElementById("seconds").innerHTML = ""
        }
    }, 1000);
  }
}
