import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-transfert-dialog',
  templateUrl: './confirm-transfert-dialog.component.html',
  styleUrls: ['./confirm-transfert-dialog.component.css']
})
export class ConfirmTransfertDialogComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<ConfirmTransfertDialogComponent>) { }

  ngOnInit() {
  }
  onNoClick(){
    this.dialogRef.close('NON');
  }
  onOuiClick(){
    this.dialogRef.close('OUI');
  }
}
