<div>
  <button mat-icon-button
          mat-button-sm color="danger"
          class="mr-1"
          matTooltip="Fermer"
          (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
  {{ snackMessage.message }}
</div>
