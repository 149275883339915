
<div class="chronocash-descritpion">
    <h1 [appAppColor]="{default : ['color']}">{{ platformConfig.platform.name }}</h1>
    <div class="home-description"
     [innerHtml]="platformConfig.platform.legalNotice">
    </div>
</div>








<app-footer></app-footer>