export class UserSubscription {
  reference: string;
  account: any;
  address: string;
  city: any;
  country: any;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  picture: any;
  role: any;
  status: string;
  username: string;
  idType: any;
  idNumber: any;
  lastConnexion: any;
  cardExpiration: any;
  lastChangePassword: any;
  notificationType: string;
}
