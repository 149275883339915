import { Component, OnInit } from '@angular/core';
import { DialogContactBoxComponent } from '../../shared/components/dialog-contact-box/dialog-contact-box.component';
import { ContactService } from '../../contact/contact.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-user-contact',
  templateUrl: './user-contact.component.html',
  styleUrls: ['./user-contact.component.css']
})
export class UserContactComponent implements OnInit {

  profile: any;
  loggedContactUrl = '/logged/contact-clk';
  loading = false;
  form: FormGroup;

  constructor(private fb: FormBuilder,
              private contactService: ContactService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.form = this.fb.group ( {
      message: [null , Validators.compose ( [ Validators.required ] )]
    });
  }

  onContactSubmit() {
    this.form.disable();
    this.loading = true;
    this.contactService.postContact(this.form.value, this.loggedContactUrl).subscribe(
      (response) => {
        const dialogRef = this.dialog.open(DialogContactBoxComponent, {
          width: '250px',
          disableClose: false,
          data: { message: 'Votre message a été envoyé'}
        });
        dialogRef.afterClosed().subscribe(result => {
          this.form.enable();
          this.form.reset();
        });
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.form.enable();
      }
    );
  }
}
