<div class="term-container">
  <div class="home-description"
                 [innerHtml]="platformConfig.platform.termCondition"></div>
  <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                        *ngIf ="data.for === 'inscription'"
                        matRippleCentered="true"
                        style="float: right; color: white;"
                        [appAppColor]="{default : ['background-color']}"
                        (click)="close()">
                    Ok
  </button>
  <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                        *ngIf ="data.for === 'first-connexion'"
                        matRippleCentered="true"
                        style="float: right; color: white;"
                        [appAppColor]="{default : ['background-color']}"
                        (click)="close()">
         J'ai lu et j'accepte les termes et conditions d'utilisation
  </button>
</div>