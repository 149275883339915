import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import {AuthService} from '../shared/auth/auth.service';
import {UserSubscription} from '../shared/user/model/user-subscription.model';
import { UserInscriptionValidators } from './user-inscription.validators';
import { UserInscriptionService } from './user-inscription.service';
import { Utils } from '../shared/utils/Utilis';
import {GlobalVariable} from '../global';
import { MatDialog } from '@angular/material';
import { TermsComponent } from '../shared/components/terms/terms.component';
import { PrivacyPolicyComponent } from '../shared/components/privacy-policy/privacy-policy.component';

@Component({
  selector: 'app-user-inscription',
  templateUrl: './user-inscription.component.html',
  styleUrls: ['./user-inscription.component.css']
})
export class UserInscriptionComponent implements OnInit {

  public form: FormGroup;
  public formErrors: string[] = [];
  countries: any = [];
  public loading: boolean = false;
  public cities: any;
  public citiesLoading: boolean = false;
  public countryCode = null;
  TEST_MODE = GlobalVariable.TEST_MODE;
  telCountry: any;
  phoneNumberUtil: any;
  constructor(private fb: FormBuilder,
              private router: Router,
              private http: HttpClient,
              private authService: AuthService,
              private dialog: MatDialog,
              private userInscriptionValidators: UserInscriptionValidators,
              private userInscriptionService: UserInscriptionService,
              public utils: Utils) {
          this.userInscriptionService.getCountries().subscribe(
            (response: any) => {
             this.countries = response.result;
             const countryChoose = this.countries.filter((country) => country.countryPhoneCode === '228');
             this.onCountryChange(null, countryChoose);
            }, (error) => {
              console.log(error);
            }
          );
  }

  ngOnInit() {

    this.form = this.fb.group ( {
      firstname: [null , Validators.compose ( [ Validators.required ] )] ,
      lastname: [null , Validators.compose ( [ Validators.required ] )],
      phone: [null , Validators.compose ( [ Validators.required ]),
        Validators.composeAsync([this.userInscriptionValidators.uniquePhoneValidator.bind(this.userInscriptionValidators)])],
      address: [null , Validators.compose ( [ Validators.required ] )],
      city: [null , Validators.compose ( [ ] )],
      email: [null , Validators.compose ( [Validators.email ]),
      Validators.composeAsync([this.userInscriptionValidators.uniqueEmailValidator.bind(this.userInscriptionValidators)])],
      term: [false , Validators.compose ( [Validators.required] )],
    });
    /*
    this.form.controls['email'].valueChanges.subscribe(
      (res) => {
        console.log(res);
        if (res) {
          this.form.controls['email'].setValidators([ Validators.email ]);
          this.form.controls['email'].setAsyncValidators([
            this.userInscriptionValidators.uniqueEmailValidator.bind(this.userInscriptionValidators)
          ]);
        } else {
          this.form.controls['email'].setValidators([]);
          this.form.controls['email'].setAsyncValidators([]);
        }
      }
    );

    this.form.controls['email'].statusChanges.subscribe(
      (res) => {
        console.log('status');
      }
    );*/

  }

  onEmailKeyUpBlur(e) {
    e.preventDefault();
    const value = this.form.controls['email'].value;
    if (value === null || value === '') {
      console.log('no error');
      this.form.controls['email'].setErrors(null);
    }
  }

  phoneHasError(e) {
    if (!e) {
      if (this.telCountry) {
        if (this.telCountry.dialCode !== '237') {
          this.form.controls['phone'].setErrors({'badPhone': true});
        } else {
          const phone = this.form.controls['phone'].value;
          if (phone !== null && phone !== undefined) {
            if (phone.indexOf('+') === -1) {
              this.form.controls['phone'].setValue('+' + this.telCountry.dialCode + phone);
            }
          }
        }
      } else {
        this.form.controls['phone'].setErrors({'badPhone': true});
      }
    }
  }

  getPhone(e) {
    this.form.controls['phone'].setValue(e);
  }
  onCountryChange(e, defaultCountry: any = null) {
    this.telCountry = e;
    let countryChoose = null;
    if (defaultCountry === null)  {
      this.form.controls['city'].setValue(null);
      this.form.controls['city'].disable();
      this.citiesLoading = true;
      countryChoose = this.countries.filter((country) => country.countryPhoneCode === e.dialCode);
    } else {
      countryChoose = defaultCountry;
    }
    if (countryChoose.length) {
      this.userInscriptionService.getCities(countryChoose[0].reference).subscribe(
        (response: any) => {
          if (response.result.length) {
            this.cities = response.result;
          } else {
            this.cities = [{reference: null, name: 'Pas de villes disponible pour le pays choisi'}];
          }
          this.form.controls['city'].enable();
          this.citiesLoading = false;
        }, (error) => {
          this.cities = [{reference: null, name: 'Pas de villes disponible pour le pays choisi'}];
          this.citiesLoading = false;
          this.form.controls['city'].enable();
          console.log(error);
        }
      );
    } else  {
      this.cities = [{reference: null, name: 'Pas de villes disponible pour le pays choisi'}];
      this.citiesLoading = false;
      this.form.controls['city'].enable();
    }
  }
  onSubmit() {
    const body = Object.assign({}, this.form.value);
    delete body.term;
    const userSubscription: UserSubscription = body;
    this.loading = true;

    this.form.disable();

    this.authService.signupUser(userSubscription).subscribe(
      (user: UserSubscription ) => {
        this.router.navigate(['/user-connection']);
      },
      (error) => {
        console.log(error);
        this.formErrors.pop();
        if (error.content.message === 'INVALID CREDENTIALS') {
          this.formErrors.push('Téléphone ou PIN incorrect');
        } else if (error.content.message === 'THIS CUSTOMER DOESN\'T HAVE A CALLCENTER IN HIS COUNTRY') {
          this.formErrors.push('Vous pouvez pas poursuivre l\'inscription car nous n\'avons pas de Callcenter dans votre pays');
        } else {
          this.formErrors.push(error.content.message);
        }
        this.form.enable();
        this.loading = false;
      }
    );
  }

  showUseConditions() {
    const dialogRef = this.dialog.open( TermsComponent, {
      width: '750px',
      height: '80%',
      disableClose: false,
      data: {for: "inscription"}
    });
    dialogRef.afterClosed().subscribe(result => {
      //  this.form.enable();
      //  this.form.reset();
    });
  }

  showPrivacyPolicy() {
    const dialogRef = this.dialog.open( PrivacyPolicyComponent, {
      width: '750px',
      height: '80%',
      disableClose: false,
      data: {for: "inscription"}
    });
    dialogRef.afterClosed().subscribe(result => {
      //  this.form.enable();
      //  this.form.reset();
    });
  }

}
