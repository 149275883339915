
export class Operation {
  reference: string;
  operationCode: string;
  operationDate: string;
  account: any;
  operationIndication: string;
  amount: number;
  prefixedNewBalance: string;
  prefixedLastBalance: string;
  receivingAmount: number;
  prefixedAmount: string;
  operationCost: number;
  prefixedOperationCost: string;
  operationAmountOnAccount: number;
  prefixedOperationAmountOnAccount: string;
  status: string;
  operationType: string;
  agency: any;
  organization: any;
  customer:	any;
  receiver: any;
  receivingOperation: Operation;
  sender: any;
  sendingOperation: any;
  receiverAccount: any;
  description: any;
}
