<div class="disconnect-container-content">
  <div class="app-contact-container">
    <div [ngClass]="{'app-contact-container-content': TEST_MODE, 'app-contact-container-content1': !TEST_MODE}">
      <div class="app-contact-all-content">
        <div class="contact-container-content">
          <div [appAppColor]="{default : ['background-color']}" class="contact-text-part">
            <h1>Contactez-Nous</h1>
            <p>
              Remplissez ces champs pour nous laisser un message
            </p>
          </div>
          <form [formGroup]="form" (submit)="onContactSubmit()">
            <div class="contact-input-container">
              <div class="contact-input-item">
                <mat-form-field>
                  <input [formControl]="form.controls['lastname']" matInput placeholder="Votre Nom">
                  <mat-error *ngIf="form.controls['lastname'].hasError('required') && form.controls['lastname'].touched">
                    Ce champ est requis
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="contact-input-item">
                <mat-form-field>
                  <input [formControl]="form.controls['firstname']" matInput placeholder="Votre Prénoms">
                  <mat-error *ngIf="form.controls['firstname'].hasError('required') && form.controls['firstname'].touched">
                    Ce champ est requis
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="contact-input-item">
                <mat-form-field class="tel-input-form">
                  <input matInput placeholder="Numéro de téléphone"
                         [formControl]="form.controls['phone']"
                         ng2TelInput
                         (countryChange)="onCountryChange($event)"
                         (ng2TelOutput)="getPhone($event)"
                         [ng2TelInputOptions]="utils.selectorOptions"
                         (hasError)="phoneHasError($event)">
                  <mat-error *ngIf="form.controls['phone'].hasError('badPhone') && form.controls['phone'].touched">
                    Numéro de téléphone invalide
                  </mat-error>
                  <mat-error *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched">
                    Ce champ est requis
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="contact-input-item">
                <mat-form-field>
                  <input [formControl]="form.controls['email']" matInput placeholder="Votre Email">
                  <mat-error *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched">
                    Email non valide
                  </mat-error>
                  <mat-error *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched">
                    Ce champ est requis
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="contact-input-item1">
                <mat-form-field class="example-full-width" >
                  <textarea [formControl]="form.controls['message']" matInput #transfertDescription maxlength="1000" placeholder="Message"></textarea>
                  <mat-hint align="end">{{transfertDescription.value.length}} / 1000</mat-hint>
                  <mat-error *ngIf="form.controls['message'].hasError('required') && form.controls['message'].touched">
                    Ce champ est requis
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="contact-input-item1">
                <button [disabled]="!form.valid || loading" type="submit" mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true" [appAppColor]="{default : ['background-color']}">
                  Envoyer
                  <mat-spinner *ngIf="loading" class="contact-spinner" [strokeWidth]="2" [diameter]="25"></mat-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
