<div>
  <form [formGroup]="form" (submit)="onSubmit()">
    <p>Le numéro {{customer.phone}} n'a pas été trouvé, Veuillez confirmer le nom et de prénom du destinataire</p>
    <div>

      <div>
        <mat-form-field>
          <input matInput type="text"
                 placeholder="Nom"
                 [formControl]="form.controls['lastname']" />
          <mat-error *ngIf="form.controls['lastname'].hasError('required') && form.controls['lastname'].touched">
            Ce champ est requis
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field>
          <input matInput type="text"
                 placeholder="Prénom"
                 [formControl]="form.controls['firstname']" />
          <mat-error *ngIf="form.controls['firstname'].hasError('required') && form.controls['firstname'].touched">
            Ce champ est requis
          </mat-error>
        </mat-form-field>
      </div>

    </div>
    <a mat-raised-button mat-ripple (click)="onClose()"
     color="primary" >Annuler</a>
     <a mat-raised-button mat-ripple (click)="onSubmit()"
     [disabled]="!form.valid" 
     [style.background-color]="platformConfig.platform.clientAppColor">Valider</a>
  </form>

</div>
