import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';


export class OperationAmountValidators {

  public static amountCheck(operationMinimumAmount): Promise<any> | Observable<any> | any {
    return (control: FormControl) => {
      const value = control.value;
      if (control.value === '') {
        return false;
      }
      if (value < operationMinimumAmount) {
        return { invalidAmount: true };
      }
    };
  }
}
