import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { UserSubscription } from '../../shared/user/model/user-subscription.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalVariable } from '../../global';
import { MediaService } from '../../shared/media/media.service';
import { ProfileService } from '../../shared/profile/profile.service';
import { BusinessInfosComponent } from './business-infos/business-infos.component';
import { MatDialog } from '@angular/material';
import { UserInscriptionValidators } from '../../user-inscription/user-inscription.validators';
import { Utils } from '../../shared/utils/Utilis';
import { PinChangeComponent } from '../../shared/components/pin-change/pin-change.component';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {

  profile: UserSubscription;
  form: FormGroup;

  editMode: boolean = false;

  loading: boolean = false;

  mediaBaseUrl = GlobalVariable.BASE_MEDIA_URL;
  mediaUrl: any = null;
  mediaLoading: boolean = false;
  telCountry: any;
  notifyMode: string[] = ['SMS', 'WHATSAPP'];
  constructor(public dialog: MatDialog, private route: ActivatedRoute,
    private fb: FormBuilder,
    private http: HttpClient,
    private mediaService: MediaService,
    private userInscriptionValidators: UserInscriptionValidators,
    public utils: Utils,
    private profileService: ProfileService) {
    this.route.data.subscribe(
      (data: Data) => {
        this.profile = data['profile'];
      }
    );
  }

  ngOnInit() {
    this.form = this.fb.group({
      reference: [null, Validators.compose([])],
      picture: [null, Validators.compose([])],
      firstname: [null, Validators.compose([Validators.required])],
      lastname: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required]),
        Validators.composeAsync([this.userInscriptionValidators.uniqueEmailValidator.bind(this.userInscriptionValidators)])],
      phone: [null, Validators.compose([Validators.required]),
        Validators.composeAsync([this.userInscriptionValidators.uniquePhoneValidator.bind(this.userInscriptionValidators)])],
      address: [null, Validators.compose([Validators.required])],
      city: [null, Validators.compose([Validators.required])],
      country: [null, Validators.compose([Validators.required])],
      notificationType: [null, Validators.compose([Validators.required])]
      // cardExpiration: [null ,
      //   Validators.compose ( [ Validators.required  ] ),
      //   Validators.composeAsync([])
      // ]
    });

    this.form.patchValue(this.profile);

    this.form.disable();

    if (this.form.controls['picture'].value !== null) {
      this.mediaUrl = this.mediaBaseUrl + this.form.controls['picture'].value.path;
    }
  }

  showIdype(idType) {
    let idTypeInLetter = null;
    switch (idType) {
      case 'CI':
        idTypeInLetter = 'Carte nationale d\'identité';
        break;
      case 'CE':
        idTypeInLetter = 'Carte d\'électeur';
        break;
      case 'PA':
        idTypeInLetter = 'Passport';
        break;
      case 'PC':
        idTypeInLetter = 'Permis de conduire';
        break;
      case 'CP':
        idTypeInLetter = 'Carte professionnelle';
        break;
      case 'CIP':
        idTypeInLetter = 'Certificat d\'identification personnelle';
        break;
      case 'TS':
        idTypeInLetter = 'Carte de séjour';
        break;
      case 'CC':
        idTypeInLetter = 'Carte CEDEAO';
        break;
      case 'CR':
        idTypeInLetter = 'Carte de résident';
        break;
    }
    return idTypeInLetter;
  }

  phoneHasError(e) {
    if (!e) {
      if (this.telCountry) {
        if (this.telCountry.dialCode !== '237') {
          this.form.controls['phone'].setErrors({ 'badPhone': true });
        } else {
          const phone = this.form.controls['phone'].value;
          if (phone !== null && phone !== undefined) {
            if (phone.indexOf('+') === -1) {
              this.form.controls['phone'].setValue('+' + this.telCountry.dialCode + phone);
            }
          }
        }
      } else {
        this.form.controls['phone'].setErrors({ 'badPhone': true });
      }
    }
  }

  onCountryChange(e) {
    this.telCountry = e;
  }

  getPhone(e) {
    this.form.controls['phone'].setValue(e);
  }

  onEditMode() {
    this.form.enable();
    this.form.controls['phone'].disable();
    if (this.profile.account.validated) {
      this.form.controls['firstname'].disable();
      this.form.controls['lastname'].disable();
    }
    this.editMode = true;
  }

  onCancelEditMode() {
    this.form.patchValue(this.profile);
    this.form.disable();
    this.editMode = false;
  }

  onSubmit() {
    this.loading = true;
    this.profileService.patchProfile(this.form.value).subscribe(
      (user: UserSubscription) => {
        this.profile = user;
        this.form.patchValue(this.profile);
        this.form.disable();
        this.editMode = false;
        this.loading = false;
      },
      eror => {
        this.loading = false;
      });
  }

  onCitySelected(e) {
    this.form.controls['city'].setValue(e);
  }

  onChangeMedia(e) {
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file === 'undefined' || file === null) {
      this.mediaUrl = null;
    } else {
      this.mediaLoading = true;
      reader.onloadend = () => {
        this.mediaUrl = reader.result;
      };
      reader.readAsDataURL(file);

      this.mediaService.postMedia(file).subscribe(
        (response) => {
          this.form.controls['picture'].setValue(response);
          this.mediaLoading = false;
          e.target.value = null;
        }
      );

    }

  }

  onDeleteMedia() {
    this.mediaUrl = null;
    this.form.controls['picture'].setValue(null);
    this.mediaLoading = false;
  }

  openBusinessDialog(): void {
    const dialogRef = this.dialog.open(BusinessInfosComponent, {
      width: '400px',
      data: { name: 'walai', animal: 'talai' }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }
  changePasswordDialog(){
    const dialogRef = this.dialog.open(PinChangeComponent, {
      width: '450px',
      disableClose: true,
      data: { for: "change-password" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //  this.form.enable();
      //  this.form.reset();
    });
  }

}
