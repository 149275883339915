import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {UserOperationsService} from '../../user-operations.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PlatformConfig} from '../../../app-loader/platform-config';
import {ConfigLoaderService} from '../../../app-loader/config-loader.service';

@Component({
  selector: 'app-confirm-operation',
  templateUrl: './confirm-operation.component.html',
  styleUrls: ['./confirm-operation.component.css']
})
export class ConfirmOperationComponent implements OnInit {

  operation: any;

  form: FormGroup;

  loading: boolean = false;

  success: boolean = false;

  errorMessage: string;

  platformConfig: PlatformConfig;

  constructor(public dialogRef: MatDialogRef<ConfirmOperationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private userOperationsService: UserOperationsService,
              private fb: FormBuilder,
              public configLoaderService: ConfigLoaderService) {
    this.operation = data.operation;
    this.platformConfig = this.configLoaderService.getPlatformConfig();
  }

  ngOnInit() {
    this.form = this.fb.group ( {
      confirmationCode: [null ,
        Validators.compose ( [Validators.required]),
        Validators.composeAsync([])
      ]
    });
  }

  onSubmit() {
    this.form.disable();
    this.loading = true;
    this.userOperationsService.confirmOperation(this.operation, this.form.value.confirmationCode).subscribe(
      (response) => {
        this.success = true;
      },
      (error) => {
        this.errorMessage = error.error.message;
        if (error.error.message === 'INVALID CONFIRMATION CODE') {
          this.errorMessage = 'Code de confirmation incorrect';
        } else {
          this.errorMessage = error.error.message;
        }
        this.form.enable();
        this.loading = false;
      }
    );
  }

  onCancel() {
    this.dialogRef.close(this.operation);
  }

}
