<mat-form-field>
  <input matInput placeholder="{{ placeholder }}"
         [formControl]="cityFormControl"  autocomplete="off"
         [matAutocomplete]="autoCities"
         (keyup)="filterCities(cityInput.value)"
         #cityInput
  >
  <mat-progress-spinner *ngIf="cityLoading" mode="indeterminate" class="primary ng-pendig-spinner-spin" [diameter]="25" [strokeWidth]="2"  ></mat-progress-spinner>
</mat-form-field>
<mat-autocomplete #autoCities="matAutocomplete" [displayWith]="displayCities" (optionSelected)="onSelectCity()">
  <mat-option *ngFor="let city of cities | async" [value]="city">
    {{ city.name  }}
  </mat-option>
</mat-autocomplete>
<mat-error
  *ngIf="cityFormControl.hasError('required') && cityFormControl.touched">
  ce champ est requis
</mat-error>
