<div class="disconnect-container-content">
  <div class="app-about-container">
    <div [ngClass]="{'app-about-container-content': TEST_MODE, 'app-about-container-content1': !TEST_MODE}">
      <div class="app-about-all-content">
          <div class="app-disconnect-container-left">
            <div class="app-disconnect-left-content">

                <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true"
                        routerLink="/disconnect-operations/disconnect-transfer" routerLinkActive="active" [appAppColor]="appColorDisconnected" class="exception-button">
                  <mat-icon [appAppColor]="{default : ['color']}" class="sidebar-icon" role="img" aria-hidden="true">compare_arrows</mat-icon>
                  <br class="line-breaker">
                  <span [appAppColor]="{default : ['color']}">Transferer</span>
                </button>

                <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true"
                        routerLink="/disconnect-operations/disconnect-pay" routerLinkActive="active" [appAppColor]="appColorDisconnected">
                  <mat-icon [appAppColor]="{default : ['color']}" class="sidebar-icon" role="img" aria-hidden="true">payment</mat-icon>
                  <br class="line-breaker">
                  <span [appAppColor]="{default : ['color']}">payer</span>
                </button>

                <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true" class="exception-button"
                        routerLink="/disconnect-operations/disconnect-balance" routerLinkActive="active" [appAppColor]="appColorDisconnected">
                  <mat-icon [appAppColor]="{default : ['color']}" class="sidebar-icon" role="img" aria-hidden="true">check_circle</mat-icon>
                  <br class="line-breaker">
                  <span [appAppColor]="{default : ['color']}">Voir Solde</span>
                </button>

                <!-- <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)" matRippleCentered="true" class="last-button"
                        routerLink="/disconnect-operations/disconnect-change-pin" routerLinkActive="active" [appAppColor]="appColorDisconnected">
                  <mat-icon [appAppColor]="{default : ['color']}" class="sidebar-icon" role="img" aria-hidden="true">fiber_pin</mat-icon>
                  <br class="line-breaker">
                  <span [appAppColor]="{default : ['color']}">Changer mon PIN</span>
                </button> -->

            </div>
          </div>
          <div class="app-disconnect-container-right">
            <router-outlet>

            </router-outlet>
          </div>
      </div>
    </div>
  </div>
</div>
