import {HttpParams} from '@angular/common/http';

export class RequestParameters {

  constructor(public searchString: string = '',
              public limit: number = 10,
              public offset: number = 0,
              public orderBy: OrderByRequestParameters[] = [],
              public fields: FieldRequestParameters[] = [],
              public resultsCount: number = 0) {

  }

  setData(parameters: RequestParameters) {
    this.searchString = parameters.searchString.slice(0);
    this.limit = +parameters.limit;
    this.offset = +parameters.offset;
    this.orderBy = parameters.orderBy.slice(0);
    this.fields = parameters.fields.slice(0);
    this.resultsCount = +parameters.resultsCount;
  }

  getHttpParams(): HttpParams  {
    let  params = new HttpParams()
      .set('limit', this.limit.toString())
      .set('offset', this.offset.toString())
      .set('searchString', this.searchString);

    for (const key in this.orderBy) {
      if (key) {
        params = params.set('orderBy[' + key + '][field]', this.orderBy[key].field)
          .set('orderBy[' + key + '][value]', this.orderBy[key].value);
      }
    }

    for (const key in this.fields) {
      if (this.fields.hasOwnProperty(key)) {
        if (this.fields[key].value !== null && this.fields[key].value !== undefined) {
          params = params.set('fields[' + key + '][field]', this.fields[key].field)
            .set('fields[' + key + '][value]', this.fields[key].value);
        }

        if (this.fields[key].upperValue !== null && this.fields[key].upperValue !== undefined) {
          params = params.set('fields[' + key + '][field]', this.fields[key].field)
            .set('fields[' + key + '][upperValue]', this.fields[key].upperValue);
        }

        if (this.fields[key].lowerValue !== null && this.fields[key].lowerValue !== undefined) {
          params = params.set('fields[' + key + '][field]', this.fields[key].field)
            .set('fields[' + key + '][lowerValue]', this.fields[key].lowerValue);
        }

      }
    }

    return params;
  }



}

export class OrderByRequestParameters {
  constructor(public field: string,
              public value: string) {

  }
}

export class FieldRequestParameters {
  constructor(public field: string,
              public value: string = null,
              public lowerValue: string = null,
              public upperValue: string = null) {

  }
}
