<div class="disconnect-container-content">
  <div class="app-help-container">
    <div [ngClass]="{'app-help-container-content': TEST_MODE, 'app-help-container-content1': !TEST_MODE}">
      <div class="app-help-all-content">
        <h1 [appAppColor]="{default : ['color']}"><mat-icon role="img" aria-hidden="true">question_answer</mat-icon> Mode Opératoire {{ platformConfig.platform.name }}</h1>
        <div class="faq-content">
          <mat-accordion class="example-headers-align">

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Comment créer un Compte {{ platformConfig.platform.name }} ?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expansion-container">
                <p>
                  Télécharger et Installer l’application {{ platformConfig.platform.name }} sur Play Store
                </p>
                <ul>
                  <li>
                    - Cliquer « connexion » puis sur « Vous n’avez pas de compte ? Cliquez –ici »
                    S’affiche la page Inscription.
                  </li>
                  <li>
                    Remplir les champs :
                  </li>
                  <li>
                    - numéro de téléphone précédé de l’indicatif pays sans 00 ni +
                  </li>
                  <li>
                    - Nom
                  </li>
                  <li>
                    - Prénoms
                  </li>
                </ul>
                <p>
                  Puis cliquer sur Inscription
                  Un message s’affiche, vous notifiant « Inscription réussie ».
                </p>
                <p>
                  Vous recevez également un SMS, de confirmation pour la création de compte suivie de votre code Pin
                  confidentiel à garder.
                </p>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Comment envoyer de l’argent en web ?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expansion-container">
                <p>
                  D’UN COMPTE À UN AUTRE :(L’expéditeur et le Bénéficiaire disposent de compte {{ platformConfig.platform.name }})
                </p>
                <p>
                  Cliquez sur « Mon Compte » puis Allez sur « Connexion »
                </p>
                <p>
                  Remplir les champs : Numéro de téléphone et Code Pin
                  Puis cliquez sur « Se connecter ». Puis allez sur « Transfert » (Votre Solde s’affiche)
                </p>
                <p>
                  Remplir les champs : Téléphone du destinataire, Montant à envoyer et Motif
                </p>
                <p>
                  Cliquez sur « Montant à percevoir » pour s’assurer de combien sera perçu par le destinataire puis cliquez sur
                  Envoyer
                </p>
                <p>
                  Le code de confirmation à envoyer au bénéficiaire s’affiche et l’SMS est envoyé au bénéficiaire.
                  Cliquez sur « OK » et vous recevrez un message de confirmation.
                </p>
                <p>
                  D’UN COMPTE À UN AUTRE : (L’expéditeur seul dispose de compte {{ platformConfig.platform.name }})
                </p>
                <p>
                  Cliquez sur « Mon Compte » puis Allez sur « Connexion »
                </p>
                <p>
                  Remplir les champs : Numéro de téléphone et Code Pin
                  Puis cliquez sur « Se connecter ».
                </p>
                <p>
                  Puis allez sur « Transfert » (Votre Solde s’affiche)
                </p>
                <p>
                  Remplir les champs : Téléphone du destinataire, Montant à envoyer et Motif
                </p>
                <p>
                  Montant à percevoir pour s’assurer de combien sera perçu par le destinataire puis cliquez sur Envoyer
                </p>
                <p>
                  Remplir les champs : Nom et prénom. Puis validez pour créer le compte du destinataire
                </p>
                <p>
                  Cliquez sur « Montant à percevoir » pour s’assurer de combien sera perçu par le destinataire puis cliquez sur
                  Envoyer
                </p>
                <p>
                  Le code de confirmation à envoyer au bénéficiaire s’affiche et l’SMS est envoyé au bénéficiaire.
                  Cliquez sur « OK » et vous recevrez un message de confirmation.
                </p>
                <p>
                  VOUS RENDRE DANS UNE AGENCE OBLIGATOIREMENT (Expéditeur Sans compte)
                </p>
                <ul>
                  <li>- Se munir du numéro de téléphone du destinataire, nom et prénoms (Destinataire sans compte)</li>
                  <li>- Se munir du numéro de téléphone du destinataire (Destinataire dispose de compte)</li>
                </ul>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Comment envoyer de l’argent sur Androïd ?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expansion-container">
                <p>
                  Pour activer votre compte, vous devez vous rendre dans une agence Bestcash muni de votre pièce d’identité pour les formalités.
                </p>
                <p>
                  Cliquer sur le menu « Transférer »
                </p>
                <p>
                  Remplir les champs :
                </p>
                <ul>
                  <li>- Numéro de téléphone précédé de l’indicatif pays sans 00, ni +</li>
                  <li>- Code Pin</li>
                  <li>- Numéro du bénéficiaire précédé de l’indicatif pays sans 00, ni +</li>
                  <li>- Le montant à envoyer</li>
                  <li>- Le motif du transfert</li>
                </ul>
                <p>
                  Cliquez sur « Montant à percevoir » pour s’assurer de combien sera perçu par le bénéficiaire puis cliquez sur
                  Envoyer
                </p>
                <p>
                  Le code de confirmation à envoyer au bénéficiaire s’affiche dans la fenêtre « Opération effectuée » et un SMS
                  est envoyé au bénéficiaire pour notifier le transfert à valider avec le code de confirmation.
                </p>
                <p>
                  Vous recevrez également un message de confirmation, vous notifiant le montant transféré, le nom et
                  prénom du bénéficiaire, le coût et le code de validation.
                </p>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Comment recevoir de l’argent ?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expansion-container">
                <p>
                  Se rendre obligatoirement dans une Agence {{ platformConfig.platform.name }} et se munir de :
                </p>
                <p>
                  Téléphone, Nom et prénom du bénéficiaire. Le Code de confirmation en cas de transfert non validé.
                </p>
                <p>
                  CONFIRMER LE TRANSFERT :
                </p>
                <ul>
                  <li>
                    - Donnez votre numéro de téléphone, confirmez votre identité et communiquer le code de confirmation reçu
                    de l’expéditeur.
                  </li>
                </ul>
                <p>
                  POUR LE RETRAIT :
                </p>
                <ul>
                  <li>- Donnez votre numéro de téléphone et confirmez votre identité</li>
                  <li>- Donnez ensuite le montant à percevoir</li>
                </ul>
                <p>
                  Vous recevriez le code de validation du retrait par SMS
                </p>
                <ul>
                  <li>- Transmettez ce code à l’agent au guichet</li>
                </ul>
                <p>
                  Un deuxième SMS pour la confirmation du retrait viendra sur votre téléphone.
                </p>
                <ul>
                  <li>- L’on vous remettra alors le montant en espèces</li>
                </ul>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Comment confirmer un Transfert sur son portable ?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expansion-container">
                <p>
                  Cliquer sur « connexion » et remplir les champs :
                </p>
                <ul>
                  <li>- numéro de téléphone, précédé de l’indicatif pays sans 00 ni +</li>
                  <li>- Code Pin, puis Cliquer sur Connexion</li>
                </ul>
                <p>
                  Si vous avez des opérations en attente s’affiche donc « mes opérations » et le nombre d’opération.
                </p>
                <ul>
                  <li>
                    - Cliquer sur « mes Opérations en cours » et choisir « Vers Moi »
                    Choisir l’Opération à confirmer puis Cliquer sur « confirmer l’opération »
                  </li>
                  <li>
                    - Saisir le Code de confirmation reçu de l’Expéditeur puis valider.
                    OK pour terminer.
                  </li>
                </ul>
                <p>
                  Vous recevez par SMS un message de validation et votre nouveau solde.
                </p>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Comment se connecter sur son portable ?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expansion-container">
                <p>
                  Cliquer sur « connexion » et remplir les champs :
                </p>
                <ul>
                  <li>
                    - numéro de téléphone, précédé de l’indicatif pays sans 00 ni +
                  </li>
                  <li>
                    - Code Pin, puis Cliquer sur Connexion Vous avez accès à votre compte {{ platformConfig.platform.name }}.
                  </li>
                </ul>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
