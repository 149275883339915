import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.css']
})
export class ParametersComponent implements OnInit {

  languages = [
    {value: 'french-0', viewValue: 'Français'},
    {value: 'english-1', viewValue: 'Anglais'}
  ];

  selectedValue: string = this.languages[0].value;

  constructor() { }

  ngOnInit() {
  }

}
