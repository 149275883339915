import { CustomerDetailsDialogService } from './customer-details-dialog.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CustomerDialogComponent } from '../customer-dialog/customer-dialog.component';
import {PlatformConfig} from '../../../app-loader/platform-config';
import {ConfigLoaderService} from '../../../app-loader/config-loader.service';

@Component({
  selector: 'app-customer-details-dialog',
  templateUrl: './customer-details-dialog.component.html',
  styleUrls: ['./customer-details-dialog.component.css']
})
export class CustomerDetailsDialogComponent implements OnInit {

  customer: {
    country: '',
    phone: '',
    firstname: '',
    lastname: ''
  };
  form: FormGroup;

  platformConfig: PlatformConfig;

  constructor(public dialogRef: MatDialogRef<CustomerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              public configLoaderService: ConfigLoaderService,
              private customerDetailsService: CustomerDetailsDialogService) {
    this.platformConfig = this.configLoaderService.getPlatformConfig();
    this.customer = data;
  }

  ngOnInit() {
    this.loadCustomerInfos(this.data.phone);
  }

  loadCustomerInfos(reference: String) {
    this.customerDetailsService.getCustomerInfos(reference)
    .subscribe(
      userInfo => {
        this.customer.country = userInfo.country.name;
        this.customer.firstname = userInfo.firstname;
        this.customer.lastname = userInfo.lastname;
        this.customer.phone = userInfo.username;
      },
      error => {throw error}
    )
  }

  onClose() {
    this.dialogRef.close(null);
  }

}
