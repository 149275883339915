<div class="app-operations-content">
  <div class="operations-container">
    <mat-toolbar class="page-title">
      <span [appAppColor]="{default : ['color']}"><mat-icon class="page-title-icon" role="img" aria-hidden="true">history</mat-icon> Opérations en cours</span>
    </mat-toolbar>
    <div class="operations-left-content">
      <mat-toolbar class="operations-header">
        <span [appAppColor]="{default : ['color']}">Mes opérations en cours</span>
      </mat-toolbar>
      <div class="my-ongoing-operations">
        <app-my-ongoing-operations [operationSelected]="operationSelectedFromDashboard" [selectedOperation]="selectedOperation">
        </app-my-ongoing-operations>
      </div>
    </div>
    <div class="operations-right-content">
      <mat-toolbar class="operations-header">
        <span [appAppColor]="{default : ['color']}">Détails de l'opération</span>
      </mat-toolbar>
      <div class="operations-right-details" *ngIf="operationSelected !== null" >
        <div class="operations-right-item" >
          <ul>
            <li>Date :</li>
            <li>{{ operationSelected.operationDate | date:'yyyy-MM-dd HH:mm':'':'fr' }}</li>
          </ul>
        </div>
        <div class="operations-right-item">
          <ul>
            <li>Code :</li>
            <li>{{ operationSelected.operationCode }}</li>
          </ul>
        </div>
        <div class="operations-right-item" *ngIf="operationSelected.agency && !operationSelected.debitType">
          <ul>
            <li>Agence :</li>
            <li>{{ operationSelected.agency.name }}</li>
          </ul>
        </div>
        <div class="operations-right-item" *ngIf="operationSelected.operationType !== 'DEBIT'">
          <ul>
            <li>Expéditeur :</li>
            <li>{{ operationSelected.account.accountNumber }}</li>
          </ul>
        </div>
        <div class="operations-right-item" *ngIf="operationSelected.receiverAccount !== null">
          <ul>
            <li>Receveur:</li>
            <li>{{ operationSelected.receiverAccount.accountNumber }}</li>
          </ul>
        </div>
        <div class="operations-right-item">
          <ul>
            <li>Montant :</li>
            <li>
              <app-operation-amount-movement-indicator [operation]="operationSelected"></app-operation-amount-movement-indicator>
             
              {{((operationSelected.amount | floorAmount) | currency: 'XOF':'') +" "+operationSelected.prefixed}}
            </li>
          </ul>
        </div>
        <div class="operations-right-item">
          <ul>
            <li>Coût :</li>
            <li>{{ operationSelected.prefixedOperationCost }}</li>
          </ul>
        </div>
        <div class="operations-right-item"
             *ngIf="operationSelected.confirmationCode && !operationSelected.debitType">
          <ul>
            <li>Code de confirmation :</li>
            <li>{{ operationSelected.confirmationCode }}</li>
          </ul>
        </div>
        <div class="operations-right-item">
          <h3 class="description">Description :</h3>
          <p>
            {{ operationSelected.description }}
          </p>
        </div>
      </div>
      <div class="operations-right-header" *ngIf="operationSelected !== null" >
        <div *ngIf="operationSelected.operationType === 'TRANSFERT'">
          <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                  matRippleCentered="true" [appAppColor]="{default : ['background-color']}"
                  [disabled]="loadingCancel" (click)="onCancel()"
                  *ngIf="canCancelOperation()" >
            <mat-progress-spinner mode="indeterminate" class="primary" [diameter]="25" [strokeWidth]="2" *ngIf="loadingCancel" >
            </mat-progress-spinner>
            Annuler l'opération
          </button>
          <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                  matRippleCentered="true" [appAppColor]="{default : ['background-color']}" (click)="onConfirm()"
                  *ngIf="canConfirmOperation()">
            Confirmer l'opération
          </button>
        </div>
        <div *ngIf="operationSelected.operationType === 'DEBIT' && operationSelected.debitType !== 'DEBIT ATM'">
          <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                  matRippleCentered="true" [appAppColor]="{default : ['background-color']}"
                  [disabled]="loadingCancel" (click)="onCancelDebit()"
                  *ngIf="canCancelOperation() || operationSelected.status !=='CONFIRMED'" >
            <mat-progress-spinner mode="indeterminate" class="primary" [diameter]="25" [strokeWidth]="2" *ngIf="loadingCancel" >
            </mat-progress-spinner>
            Annuler l'opération
          </button>
        </div>
        <div *ngIf="operationSelected.operationType === 'DEBIT' && operationSelected.debitType === 'DEBIT ATM'">
          <button mat-raised-button mat-ripple matRippleColor="rgba(255,255,255,.5)"
                  matRippleCentered="true" [appAppColor]="{default : ['background-color']}"
                  [disabled]="loadingCancel" (click)="onCancelDebitATM()"
                  *ngIf="canCancelOperation() || operationSelected.status !=='CONFIRMED'" >
            <mat-progress-spinner mode="indeterminate" class="primary" [diameter]="25" [strokeWidth]="2" *ngIf="loadingCancel" >
            </mat-progress-spinner>
            Annuler l'opération
          </button>
        </div>
      </div>
    
    </div>
    </div>
  </div>

