import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';

import {UserOperationsService} from '../user-operations.service';
import {
  FieldRequestParameters, OrderByRequestParameters,
  RequestParameters
} from '../../shared/models/request-parameters/parameter.model';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  form: FormGroup;

  requestParameters: RequestParameters = new RequestParameters();
  today = new Date();
  maxDate = new Date().toISOString();
  minDate;
  periods = [
    {value: 'DAY', viewValue: 'Dernier Jour'},
    {value: 'WEEK', viewValue: 'Dernière Semaine'},
    {value: 'MONTH', viewValue: 'Dernier Mois'},
    {value: 'CUSTOM', viewValue: 'Personnalisée'},
  ];

  loading: boolean = false;

  reportsLoaded: boolean = false;
  reportsData = {
    operations: null,
    parameters: null,
    dates: null,
  };

  constructor(private fb: FormBuilder,
              private userOperationsService: UserOperationsService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    var d = new Date();
    d.setMonth(d.getMonth() - 1);
    this.minDate = d.toISOString();

    this.form = this.fb.group ( {
      date: this.fb.group({
        value: [null,
          Validators.compose ( [Validators.required]),
          Validators.composeAsync([])
        ],
        value2: [null,
          Validators.compose ( [Validators.required]),
          Validators.composeAsync([ ])
        ],
      })
    });
    this.form.disable();
  }

  onSelectPeriod(e) {
    let dateValue: any = new Date();
    let dateValue2: any = new Date();

    switch (e) {
      case 'WEEK': {
        const today = new Date();
        dateValue = (new Date(today.setDate(today.getDate() - 7))).toISOString();

        break;
      }
      case 'MONTH': {
        const today = new Date();
        dateValue = (new Date(today.setDate(today.getDate() - 30))).toISOString();

        break;
      }
      case 'CUSTOM': {
        dateValue = null;
        dateValue2 = null;
        break;
      }
    }

    (<FormGroup>this.form.controls['date']).controls['value'].setValue(dateValue);
    (<FormGroup>this.form.controls['date']).controls['value2'].setValue(dateValue2);

    this.form.enable();
  }

  onSubmit() {
    this.reportsLoaded = false;

    const datePipe = new DatePipe('en');
    const dates = this.form.value.date;
    const date1 = datePipe.transform(dates.value, 'yyyy-MM-ddT00:00:00');
    const date2 = datePipe.transform(dates.value2, 'yyyy-MM-ddT23:59:59');
    const reqParameter = new FieldRequestParameters('operationDate', null, date1, date2);

    this.requestParameters = new RequestParameters();
    this.requestParameters.fields.push(reqParameter);
    this.requestParameters.orderBy.push(new OrderByRequestParameters('operationDate', 'ASC'));
    this.requestParameters.limit = -1;

    this.userOperationsService.getReport(this.requestParameters).subscribe(
      (response) => {
        this.reportsLoaded = true;
        this.reportsData.dates = dates;
        this.reportsData.operations = response.result;
        this.reportsData.parameters = response.parameters;
      },
      (error) => {
      }
    );

  }

}
