import { Component, OnInit } from '@angular/core';
import { ConfigLoaderService } from '../app-loader/config-loader.service';
import { PlatformConfig } from '../app-loader/platform-config';

@Component({
  selector: 'app-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.css']
})
export class LegalNoticeComponent implements OnInit {
  platformConfig: PlatformConfig;
  constructor(public configLoaderService: ConfigLoaderService) {
    this.platformConfig = this.configLoaderService.getPlatformConfig();
  }
  ngOnInit(): void {
    if (this.platformConfig.platform.homeHeadBackgroundPicture) {
      let homeBaner: any = document.getElementById('app-red-back');
      homeBaner.style.backgroundImage = "url("+this.platformConfig.platform.homeHeadBackgroundPicture+")";
      homeBaner.style.backgroundPosition = "center center";
      homeBaner.style.backgroundRepeat = "no-repeat";
      homeBaner.style.backgroundAttachment = "fixed";
      homeBaner.style.backgroundSize = "cover";
    }
  }

}
