import { CollectCustomerDetailsDialogComponent } from './../../shared/components/collect-customer-details-dialog/collect-customer-details-dialog.component';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { CustomerDialogComponent } from '../../shared/components/customer-dialog/customer-dialog.component';
import { OperationResultDialogComponent } from '../../shared/components/operation-result-dialog/operation-result-dialog.component';
import { UserOperationsService } from '../user-operations.service';
import { DisconnectOperationsService } from '../../disconnect-operations/disconnect-operations.service';

import { ProfileService } from '../../shared/profile/profile.service';
import { UserSubscription } from '../../shared/user/model/user-subscription.model';
import { GlobalVariable } from '../../global';
import { OperationAmountValidators } from '../../shared/validators/operation-amount.validators';
import { Utils } from '../../shared/utils/Utilis';
import { CustomValidators } from '../../shared/validators/custom.validators';
import { UsersCollectService } from '../../shared/models/users-collect/users-collect.service';
import { UserCollect } from '../../shared/models/users-collect/user-collect.model';
import { FieldRequestParameters, RequestParameters } from '../../shared/models/request-parameters/parameter.model';
import { OperationCollectProductsDialogComponent } from './operation-collect-products-dialog/operation-collect-products-dialog.component';
import { OperationCollectProduct } from './operation-collect-products-dialog/operation-collect-product.model';
import { UserCollectProduct } from '../../shared/models/users-collect/user-collector-product.model';


@Component({
  selector: 'app-transfert-collect',
  templateUrl: './transfert-collect.component.html',
  styleUrls: ['./transfert-collect.component.css']
})
export class TransfertCollectComponent implements OnInit {

  loadingCalculateReceivingAmount: boolean = false;
  customer: UserSubscription = null;
  form: FormGroup;
  userCollect: UserCollect;
  operationCost: any = {
    cost: 0,
    targetMonetaryArea: {
      currencyShortName: ''
    }
  };
  public reasons = [
    {
      name: 'professionnel',
      description: 'D\'ordre professionel'
    },
    {
      name: 'familial',
      description: 'D\'ordre familial'
    },
    {
      name: 'maladie',
      description: 'A cause d\'une maladie'
    },
    {
      name: 'mariage',
      description: 'Pour un mariage'
    },
    {
      name: 'commercial',
      description: 'D\'odre commercial'
    },
    {
      name: 'autres',
      description: 'Autres raison'
    }
  ];

  loading: boolean = false;
  loadingReceiver: boolean = false;
  dialogRef: MatDialogRef<OperationResultDialogComponent> | null;
  config = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '440px',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      message: ''
    }
  };

  customerDialogRef: MatDialogRef<CustomerDialogComponent> | null;
  customerDialogConfig = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '50%',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      phone: '',
      firstname: '',
      lastname: '',
    }
  };

  customerDetailsDialogRef: MatDialogRef<CollectCustomerDetailsDialogComponent> | null;
  customerDetailsDialogConfig:MatDialogConfig = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '50%',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      phone: '',
      firstname: '',
      lastname: '',
    }
  };

  minimumOperationAmount = GlobalVariable.MINIMUM_TRANSFER_OPERATION_AMOUNT;
  recieverTelCountry: any;

  productsDialogRef: MatDialogRef<OperationCollectProductsDialogComponent> | null;
  productsDialogconfig = {
    disableClose: true,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '90%',
    height: '90%',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      products: null,
      productDetails: null,
      userCollect: null
    }
  };
  showBtnLoading: boolean = false;
  // minimumOperationAmount = GlobalVariable.MINIMUM_CREDIT_OPERATION_AMOUNT;
  telCountry: any;
  addProductsLoading: boolean  = false;

  parameters = new RequestParameters();

  constructor( private fb: FormBuilder,
    private usersCollectService: UsersCollectService,
    public dialog: MatDialog,
    public utils: Utils,
    private disconnectOperationService: DisconnectOperationsService,
    private profileService: ProfileService ) {  }

  ngOnInit() {

    this.form = this.fb.group ({
      amount: [null,
        Validators.compose([Validators.required]),
        Validators.composeAsync([])
      ],
      description: [null,
        Validators.compose([]),
        Validators.composeAsync([])
      ],
      collectAgency: [null ,
        Validators.compose ( [Validators.required, CustomValidators.linkedEntity ] ),
        Validators.composeAsync([])
      ],
      reason: [null ,
        Validators.compose ( [] ),
        Validators.composeAsync([])
      ],
      receiver: this.fb.group({
        phone: [null,
          Validators.compose([Validators.required]),
          Validators.composeAsync([])
        ],
        firstname: [null,
          Validators.compose([Validators.required]),
          Validators.composeAsync([])
        ],
        lastname: [null,
          Validators.compose([Validators.required]),
          Validators.composeAsync([])
        ]
      }),
      productDetails: [null ,
        Validators.compose ( [ Validators.required ] ),
        Validators.composeAsync([])
      ],
     });

     this.customer = this.profileService.getProfileData();

    this.profileService.profile.subscribe(
      (response) => {
        this.customer = response;
      }
    );

    this.form.controls['amount'].disable();

  }

  receiverIsBlocked() {
    (<FormGroup>this.form.controls['receiver']).controls['phone'].setErrors({'userIsBlocked': true});
  }
  receiverNotFound() {
    console.log('Not found call');
    (<FormGroup>this.form.controls['receiver']).controls['phone'].setErrors({'userNotFound': true});
  }

  onReceiverPhoneBlur() {
    if ((<FormGroup>this.form.controls['receiver']).controls['phone'].valid) {
      this.loadingReceiver = true;
      this.form.disable();
      const customerPhone = (<FormGroup>this.form.controls['receiver']).controls['phone'].value;
      this.usersCollectService.getUserCollect(customerPhone).subscribe(
        (response: any) => {
          if (!response.account.blocked) {
            this.userCollect = response;
            (<FormGroup>this.form.controls['receiver']).controls['firstname'].setValidators([]);
            (<FormGroup>this.form.controls['receiver']).controls['lastname'].setValidators([]);
            this.loadingReceiver = false;
            this.form.enable();
            this.form.controls['amount'].disable();
          } else {
            this.loadingReceiver = false;
            this.form.enable();
            this.receiverIsBlocked();
            this.form.controls['amount'].disable();
          }
          this.customerDetailsDialogConfig.data.phone = this.form.value.receiver.phone;
          this.customerDetailsDialogRef = this.dialog.open(CollectCustomerDetailsDialogComponent, this.customerDetailsDialogConfig);
        },
        (error) => {
          if (error.status === 404) {
            this.loadingReceiver = false;
            this.form.enable();
            this.receiverNotFound();
            this.form.controls['amount'].disable();
          }
        }
      );
    }
  }

  getReceiverNumber(e) {
    (<FormGroup>this.form.controls['receiver']).controls['phone'].setValue(e);
  }
  receiverPhoneHasError(e) {
    if (!e) {
      if (this.recieverTelCountry) {
        if (this.recieverTelCountry.dialCode !== '237') {
          (<FormGroup>this.form.controls['receiver']).controls['phone'].setErrors({ 'badPhone': true });
        } else {
          const phone = (<FormGroup>this.form.controls['receiver']).controls['phone'].value;
          if (phone !== null && phone !== undefined) {
            if (phone.indexOf('+') === -1) {
              (<FormGroup>this.form.controls['receiver']).controls['phone'].setValue('+' + this.recieverTelCountry.dialCode + phone);
            }
          }
        }
      } else {
        (<FormGroup>this.form.controls['receiver']).controls['phone'].setErrors({ 'badPhone': true });
      }
    }
  }
  onRecieverCountryChange(e) {
    this.recieverTelCountry = e;
  }

  onCollectorAgencySelected(e) {
    this.form.controls['collectAgency'].setValue(e);
  }

  onAddProducts() {
    this.addProductsLoading = true;
    this.openProductsDialog();
    // const userCollectField = new FieldRequestParameters('userCollect',
    // this.userCollect.reference);
    // this.parameters.fields = [];
    // this.parameters.fields.push(userCollectField);
    // this.usersCollectService.getUserCollectProducts(this.userCollect).subscribe(
    //   (response: any) => {

    //   }, (error) => {

    //   });
  }

  getOperationAmount(products: OperationCollectProduct[]) {
    let amount = 0;
    products.forEach(
      (prd) => {
        amount = amount + Number(prd.amount);
      }
    );
    if (amount > 0) {
      this.form.controls['amount'].enable();
      this.form.controls['amount'].setValue(amount);
      this.form.controls['amount'].disable();
      this.onCalculateOperationCost();
    }
  }

  openProductsDialog() {
    // this.productsDialogconfig.data.products = products;
    this.productsDialogconfig.data.userCollect = this.userCollect;
    this.productsDialogconfig.data.productDetails = this.form.value.productDetails;
    this.productsDialogRef = this.dialog.open(OperationCollectProductsDialogComponent, this.productsDialogconfig);
    this.productsDialogRef.afterClosed().subscribe((result: OperationCollectProduct[]) => {
      if (result !== null) {
        this.getOperationAmount(result);
        this.form.controls['productDetails'].setValue(result);
      }
      this.addProductsLoading = false;
    });
  }

  onReasonSelected(e) {
    this.form.controls['reason'].setValue(e);
  }

  onSubmit() {

    this.loading = true;
    this.form.disable();

    this.usersCollectService.postTransfertCollect(this.form.value).subscribe(
      (response: any) => {
        this.config.data.message = 'Opération effectuée';
        this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
        this.dialogRef.afterClosed().subscribe((result) => {
          this.form.reset();
          this.userCollect = null;
          this.operationCost.cost = 0;
          this.operationCost.targetMonetaryArea.currencyShortName = '';
          this.loading = false;
          this.form.enable();
          this.form.controls['amount'].disable();
        });
      },
      (error) => {
        let message = null;
        const errorMessage = error.error.message;
        if (errorMessage === 'INVALID PIN') {
          message = 'Pin invalide';
        } else if (errorMessage === 'INSUFFICIENT BALANCE') {
          message = 'Solde insuffisant pour éffectuer cette opération';
        } else if (errorMessage === 'SAME PHONE NUMBER') {
          message = 'Le transfert vers votre propre numéro n\'est pas possible';
        } else if (errorMessage === 'Country is not supported') {
          message = 'Le pays du destinataire n\'est pas pris en charge.';
        } else if (errorMessage === 'RESSOURCE_NOT_FOUND') {
          message = 'L\'expéditeur du tranfert n\'a pas été trouvé';
        } else if (errorMessage === 'ACCOUNT BLOCKED') {
          message = 'Votre compte a été bloqué';
        } else {
          message = errorMessage;
        }
        this.config.data.message = message;
        this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
        this.dialogRef.afterClosed().subscribe((result) => {
          this.loading = false;
          this.form.enable();
          this.form.reset();
          this.operationCost = {
            cost: 0,
            targetMonetaryArea: {
              currencyShortName: ''
            }
          };
          this.form.controls['amount'].disable();
        });
      }
    );
  }

  private GetCountryCodeFromPhone(phone) {

    const phoneUtil = PhoneNumberUtil.getInstance();
    const countryPhoneCode = phoneUtil.parse(phone).getCountryCode();
    return phoneUtil.getRegionCodeForCountryCode(countryPhoneCode);

  }

  onCalculateOperationCost() {
    this.loadingCalculateReceivingAmount = true;
    const transfert = this.form.getRawValue();
    // console.log(transfert);
    const body = {
      amount: transfert.amount,
      operationType: 'TRANSFERT_COLLECT',
      sourceAccountType: 'CLASSIC',
      targetAccountType: 'COLLECT',
      userCollect: this.userCollect !== null ? this.userCollect.reference : null,
      sourceCountry: this.GetCountryCodeFromPhone(this.customer.phone),
      targetCountry: this.GetCountryCodeFromPhone(transfert.receiver.phone)
    };

    this.disconnectOperationService.calculateOperationCost(body).subscribe(
      (response: any) => {
        // console.log(response);
        this.operationCost = response;
        this.loadingCalculateReceivingAmount = false;
      },
      (error) => {
        // console.log(error);
        this.loadingCalculateReceivingAmount = false;
        this.config.data.message = 'Erreur lors du calcul des frais.';
        if (error.error.children.targetCountry) {
          this.config.data.message = 'Le pays du destinataire n\'est pas pris en charge.';
        }
        this.dialogRef = this.dialog.open(OperationResultDialogComponent, this.config);
        this.dialogRef.afterClosed().subscribe((result) => {
          this.loading = false;
          this.form.enable();
        });
      }
    );
  }

}
